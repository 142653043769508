import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useUserRole = (allowedRoles) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("LOAu"));
  useEffect(() => {
    if (user && !allowedRoles.includes(user.role.identifier)) {
      return navigate("/admin/dashboard", { replace: true })
    }
  }, [allowedRoles]);
  return true
}