export const detectTypeFile = (file) => {
  const type = file.type
  switch (type) {
    case "image/jpeg":
      return "/images/file-types/jpg.png"
    case "image/png":
      return "/images/file-types/png.png"
    case "application/pdf":
      return "/images/file-types/pdf.png"
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "/images/file-types/doc.png"
    case "text/csv":
      return "/images/file-types/csv.png"
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "/images/file-types/ppt.png"
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "/images/file-types/xls.png"
    default:
      return "/images/file-types/txt.png"
  }
}

export const MBOfFile = (file) => {
  const size = file.size
  const sizeMB = size / 1024 / 1024
  return sizeMB.toFixed(2) + " MB"
}