import { MapContainer, TileLayer } from "react-leaflet"
import 'leaflet/dist/leaflet.css'
import SetBoundsRectangles from "./modules/SetBoundsRectangles"
import { useState } from "react"
import { getDemographicCategories, getDistrict, getSectionsByDistrict } from "./services/api"
import { useMutation, useQuery } from "@tanstack/react-query"
import { InfoWindow } from "./modules/InfoWindow"
import { Loader } from "./modules/Loader"
import { Filters } from "./modules/Filters"
import { ColorIndicatorBar } from "./modules/ColorIndicatorBar"

export const DemographicResultsMap = ({ localDistrictId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [coordinates, setCoordinates] = useState([])
  const [switchGeoData, setSwitchGeoData] = useState(1) // 1 = district, 2 = sections
  const [year, setYear] = useState("2020")
  const [infoStickyBottomRightWindow, setInfoStickyBottomRightWindow] = useState(null)
  const [colorIndicator, setColorIndicator] = useState({ initial: "", end: "" })
  // consts for demographic categories
  const [demographicCategorySelected, setDemographicCategorySelected] = useState(null)
  const [demographicSubcategories, setDemographicSubcategories] = useState(null)
  const [demographicSubcategorySelected, setDemographicSubcategorySelected] = useState(null)

  const { data: demographicCategories } = useQuery(
    ["GET_DEMOGRAPHIC_CATEGORIES"],
    () => getDemographicCategories(),
    { refetchOnWindowFocus: false }
  )

  const handleBuildInfosWindow = ({ name, demographicresults }) => {
    if (demographicresults !== undefined && demographicresults.length > 0) {
      console.log("handleBuildInfosWindow", demographicresults)
      let info = ""
      demographicresults.forEach(element => {
        info += `<div style="width: 100%%; display: flex; align-items: center; padding: 5px 0px; border-bottom: 1px solid #cecece;">`
        info += `<div style="padding: 0px 5px">`
        info += `<span style="font-weight: bold">${demographicCategorySelected.name}</span> <br/>`
        info += `<span style="font-weight: bold">${demographicSubcategorySelected.name}</span> <br/>`
        info += `<div><span>${(element.value).toFixed()}<span/></div>`
        info += `</div>`
        info += `</div>`
      });
      setInfoStickyBottomRightWindow(info)
    } else {
      setInfoStickyBottomRightWindow("Proximamente")
    }
  }

  const handleVisibilityTypeSelect = (event) => {
    setInfoStickyBottomRightWindow(null)
    setSwitchGeoData(event.target.value)
  }
  const handleYearSelect = (event) => {
    setInfoStickyBottomRightWindow(null)
    setYear(event.target.value)
  }

  // Functions for Demographic categories
  const handleDemographicCategorySelect = (value) => {
    setDemographicCategorySelected(value)
    setDemographicSubcategorySelected(null)
    setDemographicSubcategories(null)
    setInfoStickyBottomRightWindow(null)
    if (value !== null) {
      const subcategories = value.subcategories.map((subcategory) => {
        return {
          identifier: subcategory.identifier,
          name: subcategory.name
        }
      })
      setDemographicSubcategories(subcategories)
    } else {
      setDemographicSubcategories(null)
    }
  }

  const handleDemographicSubcategorySelect = (value) => {
    setDemographicSubcategorySelected(value)
    setInfoStickyBottomRightWindow(null)
  }

  // Functions for search button
  const mutationSearchSectionsByDistrict = useMutation(getSectionsByDistrict, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "not-found") { setCoordinates([]) }
      setColorIndicator({ initial: data.color_indicator.initial, end: data.color_indicator.end })
      const sections = data.sections.map((section) => {
        return {
          coordinates: section.coordinates,
          demographicresults: section.demographicresults.length > 0 ? section.demographicresults : [],
          name: section.identifier,
          color: section.color
        }
      })
      setCoordinates(sections)
    },
    onError: (error) => {
      console.log(error)
    }
  })

  const mutationSearchLocalDistrict = useMutation(getDistrict, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "not-found") { setCoordinates([]) }
      setColorIndicator({ initial: data.data.color, end: data.data.color })
      setCoordinates([data.data])
    },
    onError: (error) => {
      console.log(error)
    }
  })

  const handleSearch = () => {
    setIsLoading(true)
    const params = {
      id: localDistrictId,
      year: year,
      category: demographicCategorySelected._id,
      subcategory: demographicSubcategorySelected.identifier
    }

    if (switchGeoData === 1) {
      mutationSearchLocalDistrict.mutate(params)
    } else if (switchGeoData === 2) {
      mutationSearchSectionsByDistrict.mutate(params)
    }
  }

  return (<>
    <Filters
      switchGeoData={switchGeoData}
      handleVisibilityTypeSelect={handleVisibilityTypeSelect}
      year={year}
      handleYearSelect={handleYearSelect}
      demographicCategories={demographicCategories}
      handleDemographicCategorySelect={handleDemographicCategorySelect}
      demographicSubcategories={demographicSubcategories}
      handleDemographicSubcategorySelect={handleDemographicSubcategorySelect}
      demographicCategorySelected={demographicCategorySelected}
      demographicSubcategorySelected={demographicSubcategorySelected}
      handleSearch={handleSearch}
      isLoading={isLoading} />
    {coordinates.length > 0 ? <div style={{ position: "relative" }}>
      <ColorIndicatorBar 
        colorInitial={colorIndicator.initial}
        colorEnd={colorIndicator.end}
        textInitial="Mayor población/promedio"
        textEnd="Menor población/promedio"
      />
      <MapContainer
        center={[19.3264047, -99.6049788]}
        zoom={15}
        scrollWheelZoom={true}
        style={{ height: "500px", width: "100%", borderRadius: "14px", boxShadow: "0 3px 4px 0px rgba(0,0,0,0.2)" }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" />
        <SetBoundsRectangles coordinates={coordinates} infoSticky={handleBuildInfosWindow} />
        <Loader show={isLoading} />
      </MapContainer>
      <InfoWindow
        show={infoStickyBottomRightWindow !== null}
        close={() => setInfoStickyBottomRightWindow(null)}
        title="Resultados demograficos"
        placeholder="Da click sobre una localidad"
        position="top-right"
        info={infoStickyBottomRightWindow} />
    </div> : null}
  </>)
}

//https://github.com/Inbound-Marketing-Mexico/cuadros-backoffice/blob/main/src/components/page/home/Map.jsx#L127