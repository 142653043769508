import { useEffect, useState } from "react"
import { Autocomplete, Grid, TextField } from "@mui/material"

export const Filters = ({ folders, setCategoryFolderPolitician, politicians, setPoliticianFilter }) => {
  const [categoriesFolderPolitician, setCategoriesFolderPolitician] = useState([])

  useEffect(() => {
    if (folders !== undefined && folders !== null && folders !== "") {
      let categories = []
      folders.forEach(folder => {
        for (let index = 0; index < folder.categories.length; index++) {
          categories.push({ value: folder.categories[index]._id, label: `${folder.name} - ${folder.categories[index].name}` })
        }
      });
      setCategoriesFolderPolitician(categories)
    }
  }, [folders])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disablePortal
          size="small"
          id="politiciansFilter"
          options={politicians}
          getOptionLabel={(option) => option.label}
          onChange={(event, newValue) => setPoliticianFilter([newValue !== null ? newValue.value : ""])}
          renderInput={(params) => <TextField {...params} label="Elegir diputado" />}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disablePortal
          size="small"
          id="categoriesFolderPoliticianFilter"
          options={categoriesFolderPolitician}
          getOptionLabel={(option) => option.label}
          onChange={(event, newValue) => setCategoryFolderPolitician([newValue !== null ? newValue.value : ""])}
          renderInput={(params) => <TextField {...params} label="Elegir diputado" />}
        />
      </Grid>
    </Grid>
  )
}