import { Link } from 'react-router-dom';
import '../../styles/404.css'

function Error() {
  return (<div className="error-container">
    <div className="number">404</div>
    <div className="text"><span>Ooops...</span><br/>Página no encontrada</div>
    <Link to="/admin/dashboard" className="return">Regresar a la pagina Home</Link>
  </div>)
}

export default Error;
