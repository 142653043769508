import { InputAutocompleteBuilder, InputSwitchBuilder, InputTextBuilder } from "components/InputBuilder";

export default function SharedForm(
  { dataInstance, districts, handleOnChangeData, handleOnChangeAutocomplete, handleChangeBoolean, register, errors }
) {
  return (<>
    <InputTextBuilder
      label="ID de municipio" id="municipality_id" name="municipality_id" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputTextBuilder
      label="Nombre" id="name" name="name" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputAutocompleteBuilder
      label="Distritos" id="districts" name="districts" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeAutocomplete}
      register={register} errors={errors}
      options={districts} />
    <InputSwitchBuilder
      label="Municipio Activo" id="active" name="active" required={true}
      instance={dataInstance} handleOnChangeData={handleChangeBoolean}
      register={register} errors={errors} />
  </>)
}