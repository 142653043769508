import { useEffect, useState } from "react";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from '@tanstack/react-query'
import { SchemaValidation } from "../services/schemaValidation";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { edit } from "../services/api";
import { useTranslation } from "react-i18next";
import { isEmptyObject } from "helpers";
import SharedForm from "../modules/SharedForm";
import { Sections } from "../modules/Sections";

export default function Edit({ open, close, instance, reportAction }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  const [sections, setSections] = useState([])
  let { reset, handleSubmit, register, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(SchemaValidation(t))
  })

  useEffect(() => {
    delete instance.password
    if (open) { setDataInstance(instance); reset(instance); setSections(instance.sections) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const mutation = useMutation(edit, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al guardar los datos', 'error')
      } else {
        reset(); setDataInstance({});
        reportAction(true, 'Acción realizada correctamente', 'success')
      }
    }
  })

  const handleEdit = (params) => {
    setIsLoading(true)
    let id = params._id
    delete params._id
    delete params.createdAt
    delete params.updatedAt
    delete params.deleted
    mutation.mutate({ id, params })
  }

  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => {
    if (event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeBoolean = (name) => {
    setDataInstance({ ...dataInstance, [name]: !dataInstance[name] })
  }

  const handleUpdateSections = (sections) => {
    setValue("sections", sections)
    setSections(sections)
  }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>{t("button_text_cancel")}</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)} disabled={isEmptyObject(dataInstance)} >{t("button_text_edit")}</Button>
    </> : <CircularProgress />}
  </>)

  return (<>
    <FlexibleWidthDialog open={open} maxWidth="md" title={t('districts_dialog_title_edit')} actions={<Actions />}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SharedForm
            dataInstance={dataInstance}
            handleOnChangeData={handleOnChangeData}
            handleChangeBoolean={handleChangeBoolean}
            register={register}
            errors={errors} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Sections
            sections={sections}
            updateSections={handleUpdateSections}
            rhf={{ errors }}
          />
        </Grid>
      </Grid>
    </FlexibleWidthDialog>
  </>)
}