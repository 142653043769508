import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, IconButton, MenuItem, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Delete as DeleteIcon, Edit as EditIcon, ExpandMore as ExpandMoreIcon } from "@mui/icons-material"
import { useMutation, useQuery } from "@tanstack/react-query";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAll as getCommissions } from "../../Comissions/services/api";
import { getAll as getCommissionCharge } from "../../ComissionsCharge/services/api";
import { updateCommissions } from "../services/api";
import { ValidateErrorMessage } from "components/ValidateErrorMessage";

export const Commissions = ({ open, close, instance, reportAction }) => {
  const { t } = useTranslation();
  const [commissions, setCommissions] = useState([])
  const [newCommission, setNewCommission] = useState({ commission: '', commission_charge: '', isDeleted: false })
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [indexToEdit, setIndexToEdit] = useState(-1)
  const [expandedAccordion, setExpandedAccordion] = useState(false);
  const { data: commissionsList } = useQuery(['commisionsList'], getCommissions, { refetchOnWindowFocus: false, enabled: open })
  const { data: commissionChargesList } = useQuery(['commisionChargesList'], getCommissionCharge, { refetchOnWindowFocus: false, enabled: open })
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
  let timeOut;

  useEffect(() => {
    if (open) {
      let commissionsTmp = []
      instance.commissions.forEach(item => {
        commissionsTmp.push({ commission: item.commission, commission_charge: item.commission_charge, isDeleted: false })
      })
      setCommissions([...commissionsTmp])
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    clearMessageOnInterval() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  const clearMessageOnInterval = () => {
    if (message !== '') { timeOut = setTimeout(() => { setMessage('') }, 5000) }
    else { clearTimeout(timeOut) }
  }

  const handleClose = () => { setNewCommission({ commission: '', commission_charge: '', isDeleted: false }); setIsLoading(false); close() }

  const mutation = useMutation(updateCommissions, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al guardar los datos', 'error')
      } else {
        setCommissions([]);
        reportAction(true, 'Acción realizada correctamente', 'success')
      }
    }
  })

  const handleEdit = () => {
    let commisionsFinal = []
    const commissionsOnlyNoDeleted = commissions.filter(item => !item.isDeleted)
    commissionsOnlyNoDeleted.forEach(item => {
      commisionsFinal.push({ commission: item.commission, commission_charge: item.commission_charge })
    })
    setIsLoading(true)
    mutation.mutate({ id: instance._id, params: { commissions: commisionsFinal } })
  }

  const handleDeleteCommission = (index) => {
    let commissionsTemp = commissions
    /* commissionsTemp.splice(index, 1) */
    commissionsTemp[index].isDeleted = true
    setCommissions([...commissionsTemp])
  }

  const handleRestoreCommission = (index) => {
    let commissionsTemp = commissions
    commissionsTemp[index].isDeleted = false
    setCommissions([...commissionsTemp])
  }

  const handleAddEditCommision = () => {
    if (indexToEdit === -1) {
      let commissionsTemp = commissions
      commissionsTemp.push(newCommission)
      setCommissions([...commissionsTemp])
      setNewCommission({ commission: '', commission_charge: '', isDeleted: false })
    } else {
      let commissionsTemp = commissions
      commissionsTemp[indexToEdit] = newCommission
      setCommissions([...commissionsTemp])
      setNewCommission({ commission: '', commission_charge: '', isDeleted: false })
      setIndexToEdit(-1)
    }
  }

  const handleSelectCommissionToEdit = (index) => {
    setIndexToEdit(index);
    setNewCommission(commissions[index])
    setExpandedAccordion("panel-add-commision");
    const element = document.getElementById('container-panel-add-commision');
    if (element) { element.scrollIntoView({ behavior: 'smooth' }); }
  }

  const handleCleanCancelCommission = () => {
    setIndexToEdit(-1);
    setNewCommission({ commission: '', commission_charge: '', isDeleted: false })
    setExpandedAccordion(false);
  }

  const handleSelectNewCommission = (event) => {
    setMessage('')
    if (validateIfCommissionExist(event.target.value)) {
      setNewCommission({ ...newCommission, commission: '', commission_charge: '', isDeleted: false })
      setMessage('La comisión seleccionada ya existe en la lista')
    } else {
      setNewCommission({ ...newCommission, commission: event.target.value })
    }
  }

  const validateIfCommissionExist = (commission) => {
    let exist = false
    commissions.forEach(item => { if (item.commission === commission) { exist = true } })
    return exist
  }

  const handleChangeExpandedAccordion = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
    setNewCommission({ commission: '', commission_charge: '', isDeleted: false })
    setIndexToEdit(-1)
  };

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>{t("button_text_cancel")}</Button>
      <Button variant="contained" color="info" onClick={() => handleEdit()}>{t("button_text_edit")}</Button>
    </> : <CircularProgress />}
  </>)

  return (
    <FlexibleWidthDialog open={open} title={t('politicians_dialog_title_commissions')} actions={<Actions />}>
      {commissionsList !== undefined && commissionChargesList !== undefined ? <>
        <div style={{ marginBottom: 24 }} id="container-panel-add-commision">
          <Accordion sx={{ backgroundColor: "rgba(33, 33, 69, .15)" }} expanded={expandedAccordion === 'panel-add-commision'} onChange={handleChangeExpandedAccordion('panel-add-commision')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-add-commision-content" id="panel-add-commision-header">
              <Typography variant="body2" gutterBottom component="div" sx={{ fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>{indexToEdit === -1 ? "Agregar" : "Editar"} comisión</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} sx={{ fontSize: 12 }}>
                <Grid item xs={12} sm={6}>
                  <TextField id="select-commission" select label="Selecciona una comisión" fullWidth
                    value={newCommission.commission}
                    onChange={(e) => handleSelectNewCommission(e)} >
                    {commissionsList.data.map((option) => (
                      <MenuItem key={option._id} value={option._id}> {option.name} </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField id="select-commission-charge" select label="Selecciona un cargo" fullWidth
                    value={newCommission.commission_charge}
                    onChange={(e) => setNewCommission({ ...newCommission, commission_charge: e.target.value })}
                    disabled={newCommission.commission === ''} >
                    {commissionChargesList.data.map((option) => (
                      <MenuItem key={option._id} value={option._id}> {option.name} </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="info" onClick={() => handleAddEditCommision()} fullWidth
                    disabled={newCommission.commission === '' || newCommission.commission_charge === ''}
                  >{indexToEdit === -1 ? "Agregar" : "Editar"}</Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="warning" onClick={() => handleCleanCancelCommission()} fullWidth
                    disabled={newCommission.commission === '' || newCommission.commission_charge === ''}
                  >{indexToEdit === -1 ? "Limpiar" : "Cancelar"}</Button>
                </Grid>
                <Grid item xs={12}> <ValidateErrorMessage message={message} /> </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        {!matchesSM ?
        <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight: "bold", marginBottom: 2, paddingBottom: 2, paddingTop: 2, position: "sticky", top: "-16px", zIndex: 9, backgroundColor: "#ffffff", borderBottom: "1px solid #cecece" }}>Comisiones registradas</Typography>
        : null}
        <Grid container spacing={2}>
          {matchesSM ?
            <Grid item xs={12} sx={{ fontSize: 14, paddingBottom: 2, position: "sticky", top: "-16px", zIndex: 9, backgroundColor: "#ffffff", borderBottom: "1px solid #cecece" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}><b>Comisión</b></Grid> <Grid item xs={12} sm={4}><b>Cargo</b></Grid>
              </Grid>
            </Grid>
            : null}
          {commissions.map((commission, index) => (
            <Grid item xs={12} key={index} sx={{ backgroundColor: commission.isDeleted ? "#fe9797" : "#ffffff", paddingBottom: 2, borderBottom: "1px solid #cecece", fontSize: matchesSM ? 12 : 14 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  { !matchesSM ? <b>Comisión: </b> : null }
                  {commissionsList.data.find(item => item._id === commission.commission).name}
                </Grid>
                <Grid item xs={12} sm={4}>
                  { !matchesSM ? <b>Cargo: </b> : null }
                  {commissionChargesList.data.find(item => item._id === commission.commission_charge).name}
                </Grid>
                <Grid item xs={12} sm={3} sx={{ paddingRight: 2 }}>
                  {!commission.isDeleted ? <>
                    <IconButton size="small" onClick={() => handleSelectCommissionToEdit(index)}><EditIcon /></IconButton>
                    <IconButton size="small" onClick={() => handleDeleteCommission(index)}><DeleteIcon /></IconButton>
                  </> :
                    <Button variant="contained" color="warning" size="small" onClick={() => handleRestoreCommission(index)} fullWidth>Restaurar</Button>
                  }
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </> : null}
    </FlexibleWidthDialog>
  )
}