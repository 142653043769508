import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon, DragIndicator as DragIndicatorIcon, MoreHoriz as MoreHorizIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'

/**
 * Component that renders a icon indicator to drag and drop
 */
export const DraggableRowDragIndicatorIcon = ({ disabled }) => {
  return (<>
    <DragIndicatorIcon
      fontSize="small"
      sx={{
        color: !disabled ? "#212145" : "#9e9e9e",
        cursor: !disabled ? "grab" : "not-allowed"
      }} />
  </>)
}

/**
 * Component that renders a image
 */
export const DraggableRowImage = ({ image }) => {
  return (<>
    {image ?
      <img
        src={image}
        style={{ width: 50 }}
        alt={image} />
      : <div style={{ width: 50 }}></div>
    }
  </>)
}

/**
 * Component that renders a text
 */
export const DraggableRowText = ({ text }) => {
  return <div> {text} </div>
}

/**
 * Component that renders a icon indicator to know if the accordion of the row is active
 */
export const DraggableRowButtonMenu = ({ open, disabled, action }) => {
  return (
  <>
    <IconButton
      id={`demo-customized-button`}
      aria-controls={open ? `demo-customized-menu` : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      variant="contained"
      onClick={action}
      style={{ marginRight: 8 }}
      disabled={disabled}
    >
      <MoreHorizIcon />
    </IconButton>
  </>)
}

/**
 * Component that renders a icon indicator to know if the accordion of the row is active
 */
export const DraggableRowActivateAccordionIcon = ({ active, action }) => {
  return (<>
    <IconButton onClick={action} >
      {active ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </IconButton>
  </>)
}

/**
 * Component that renders the accordion content of the row if is active
 */
export const DraggableRowAccordionContent = ({ show, children }) => {
  return (<>
    {show ?
      <div style={{ width: "100%" }}>
        <div style={{ width: "92%", float: 'right' }}>
          {children}
        </div>
      </div>
      : null}
  </>)
}