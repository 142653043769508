import { InputPasswordBuilder, InputSelectBuilder, InputSwitchBuilder, InputTextBuilder } from "components/InputBuilder";

export default function SharedForm(
  { dataInstance, roles, handleOnChangeData, handleChangeBoolean, register, errors }
) {
  return (<>
    <InputTextBuilder
      label="Nombre" id="name" name="name" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputTextBuilder
      label="Correo electrónico" id="email" name="email" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputPasswordBuilder
      label="Contraseña" id="password" name="password" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputSelectBuilder
      label="Rol" id="role" name="role" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors}
      options={roles} />
    <InputSwitchBuilder
      label="Usuario activo?" id="active" name="active" required={true}
      instance={dataInstance} handleOnChangeData={handleChangeBoolean}
      register={register} errors={errors} />
  </>)
}