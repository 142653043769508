import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FlexibleWidthDialog({ open, title, maxWidth="sm", children, actions }) {
  return (
    <Dialog 
      fullWidth
      maxWidth={maxWidth}
      open={open}
      TransitionComponent={Transition}
      scroll={`paper`} 
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title"> {title} </DialogTitle>
      <DialogContent dividers={true} id='mui-dialog-content'> {children} </DialogContent>
      <DialogActions> {actions} </DialogActions>
    </Dialog>
  )
}