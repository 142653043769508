//import firebase from 'firebase/app'
//import 'firebase/storage'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage } from 'firebase/storage'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBazCtkXmW1GLUTpqa57VoW5zIa8fmxz8A",
  authDomain: "observatoriolegislativo-test.firebaseapp.com",
  projectId: "observatoriolegislativo-test",
  storageBucket: "observatoriolegislativo-test.appspot.com",
  messagingSenderId: "166832949006",
  appId: "1:166832949006:web:f7b7d6aed08a7f6b06795f"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig)

export const storage = getStorage(app)
