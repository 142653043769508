/**
* Function to order data using React Beautiful DND lib
*/
export const ReactBeautifulDNDUpdateOrder = (data, droppedItem) => {
  if (!droppedItem.destination) return false;
  var orderDND = data
  const [reorderedItem] = orderDND.splice(droppedItem.source.index, 1);
  orderDND.splice(droppedItem.destination.index, 0, reorderedItem);
  let order = []
  orderDND.forEach((element, index) => order.push({ id: element._id, order: index }));
  return order
}