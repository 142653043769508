

export const listMapNumbers = [
  /* {
    id: 1,
    title: 'Mapa 1',
    description: 'Estatal del Partido que más votos tuvo por distrito elecciones 2021 mayoría relativa',
  },
  {
    id: 2,
    title: 'Mapa 2',
    description: 'Estatal de los partidos que más votos tuvieron por sección elecciones 2021 mayoría relativa',
  },
  {
    id: 3,
    title: 'Mapa 3',
    description: 'Estatal del margen de victoria en rangos por distrito',
  }, */
  {
    id: 4,
    title: 'Secciones ganadas por partido',
    description: 'Por distrito, mapa de secciones ganadas por partido, indicando si son rurales o urbanas',
  },
  {
    id: 5,
    title: 'Secciones ganadas segunda fuerza',
    description: 'Por distrito, mapa de secciones ganadas segunda fuerza indicando si son rurales o urbanas',
  },
  {
    id: 6,
    title: 'Mapa seccional de margen de victoria',
    description: 'Por distrito, mapa seccional de margen de victoria, desde la perspectiva del partido del diputado, indicando si son rurales o urbanas. Para el caso donde el partido del diputado coincida con el pártido ganador, el calculo se hace con respecto a la segunda fuerza de cada sección. Para el caso donde el partido del diputado no gano la elección, el calculo se hace desde la perspectiva del partido ganador hacia el partido del diputado (como si este partido fuera la segunda fuerza)',
  },
  {
    id: 7,
    title: 'Secciones con % de participación ciudadana',
    description: 'Por distrito, mapa de secciones con % de participación ciudadana indicando si son rurales o urbanas',
  },
  {
    id: 8,
    title: 'Secciones con votos nulos',
    description: 'Por distrito, mapa de secciones con votos nulos, indicando si son rurales o urbanas',
  },
  {
    id: 9,
    title: 'Secciones ganadas, por el partido del diputado, mapa de calor de mayor a menor cantidad de mujeres, en dichas secciones ganadas',
    description: 'Por distrito secciones ganadas, por el partido del diputado, mapa de calor de mayor a menor cantidad de mujeres, en dichas secciones ganadas.',
  },
  {
    id: 10,
    title: 'Secciones de mayor a menor votación a favor del partido del diputado',
    description: 'Por distrito secciones de mayor a menor votación a favor del partido del diputado',
  },
  {
    id: 11,
    title: 'Categorías de incidencia por sección',
    description: 'Distrital con categorías de incidencia por sección (categorías nuevas)',
  }
]

