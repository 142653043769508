import { Box, Button, Grid, TextField, useMediaQuery } from "@mui/material";
import { DragIndicator as DragIndicatorIcon, Search as SearchIcon } from "@mui/icons-material";

export const TableSearch = ({ placeholder, searchAction }) => {
  return (<>
    <Grid container spacing={0} sx={{ marginBottom: 2 }}>
      <Grid item xs={12} md={12}>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
          <SearchIcon />
          <TextField fullWidth autoFocus 
            id="search"
            variant="standard"
            placeholder={placeholder}
            onChange={searchAction} />
        </Box>
      </Grid>
    </Grid>
  </>)
}

export const TableHeaderC = ({ columns }) => {
  const mdMQ = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (<>
    {mdMQ ?
      <Grid container spacing={0}
        sx={{ fontSize: 14, padding: 1, borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
        {columns.map((column, index) => (
          <Grid key={index} item xs={column.size} sx={{ paddingRight: 1, fontWeight: "bold" }}>
            <Button sx={{ textTransform: "none", fontWeight: "bold" }}>{column.name}</Button>
          </Grid>
        ))}
      </Grid>
      : 
      null
    }
  </>)
}

export const TableRowDragableC = ({ totalData, isDragable = false, dragableHandle, items, actions }) => {
  const mdMQ = useMediaQuery(theme => theme.breakpoints.up('md'));

  const tableItem = { paddingRight: mdMQ ? 2 : 0, display: mdMQ ? 'flex' : "grid", gridTemplateColumns: "1fr 1fr", alignItems: "center", gap: 2, margin: mdMQ ? 0 : "4px 0 4px 0" }

  return (<>
    <Grid container spacing={0} sx={{ fontSize: 14, paddingTop: 2, paddingBottom: 2, borderBottom: "1px solid rgba(224, 224, 224, 1)", '&:hover': { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}>
      {items.map((item, index) => (
        <Grid key={`trc-${index}`} item xs={12} md={item.size} sx={tableItem}>
          {index === 0 && isDragable ?
            null
            :
            <span style={{ display: mdMQ ? 'none' : 'block', fontWeight: "bold" }}>{item.column}</span>
          }
          <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            {index === 0 && isDragable && totalData > 1 ?
              <span {...dragableHandle}><DragIndicatorIcon fontSize="small" /></span>
              :
              null
            }
            {item.value === "actions" ? actions : item.value}
          </span>
        </Grid>
      ))}
    </Grid>
  </>)
}

export const TableRowC = ({ totalData, leftAction = false, items, actions }) => {
  const mdMQ = useMediaQuery(theme => theme.breakpoints.up('md'));

  const tableItem = { paddingRight: mdMQ ? 2 : 0, display: mdMQ ? 'flex' : "grid", gridTemplateColumns: "1fr 1fr", alignItems: "center", gap: 2, margin: mdMQ ? 0 : "4px 0 4px 0" }

  return (<>
    <Grid container spacing={0} sx={{ fontSize: 14, padding: 1, borderBottom: "1px solid rgba(224, 224, 224, 1)", '&:hover': { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}>
      {items.map((item, index) => (
        <Grid key={`trc-${index}`} item xs={12} md={item.size} sx={tableItem}>
          {index === 0 && leftAction ?
            null
            :
            <span style={{ display: mdMQ ? 'none' : 'block', fontWeight: "bold" }}>{item.column}</span>
          }
          <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            {index === 0 && leftAction && totalData > 1 ?
              <span><DragIndicatorIcon fontSize="small" /></span>
              :
              null
            }
            {item.value === "actions" ? actions : item.value}
          </span>
        </Grid>
      ))}
    </Grid>
  </>)
}

export const TableNotFoundC = () => {
  return (
    <Grid container spacing={0} sx={{ fontSize: 14, paddingTop: 2, paddingBottom: 2, borderBottom: "1px solid rgba(224, 224, 224, 1)", '&:hover': { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}>
      <Grid item xs={12} sx={{ textAlign: "center" }}> No se encontró ningún resultado </Grid>
    </Grid>
  )
}