import { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from '@tanstack/react-query'
import { SchemaValidationAdd } from "../services/schemaValidation";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { save } from "../services/api";
import { useTranslation } from "react-i18next";
import { isEmptyObject } from "helpers";
import SharedForm from "../modules/SharedForm";
import { getAll as getAllPoliticalParties } from "routes/PoliticalParty/services/api";
import { getAll as getAllDistricts } from "routes/Distric/services/api";

const PoliticianSchema = {
  academic_degree: { degree: "", institution: "" },
  annual_activities_report: { isSubmitted: false, date: "" },
  three_of_three_report: { isSubmitted: false, date: "" },
  facebook_account: { page: "", followers: "" },
  twitter_account: { page: "", followers: "" },
  instagram_account: { page: "", followers: "" },
  website: "",
  active: true
}

export default function Add({ open, close, reportAction }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState(PoliticianSchema)
  let { reset, handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(SchemaValidationAdd(t)),
    defaultValues: PoliticianSchema
  })
  const { data: politicianPartiesList } = useQuery(['politicianPartiesList'], getAllPoliticalParties, { refetchOnWindowFocus: false, enabled: open })
  const [politicianParties, setPoliticianParties] = useState([])
  const { data: districtsList } = useQuery(['districtsList'], getAllDistricts, { refetchOnWindowFocus: false, enabled: open })
  const [districts, setDistricts] = useState([])

  useEffect(() => {
    if (politicianPartiesList) {
      const politicianParties = politicianPartiesList.data.map((item) => {
        return { value: item._id, label: item.name }
      })
      setPoliticianParties(politicianParties)
    }
  }, [politicianPartiesList])

  useEffect(() => {
    if (districtsList) {
      const districts = districtsList.data.map((item) => {
        return { value: item._id, label: item.name }
      })
      setDistricts(districts)
    }
  }, [districtsList])

  const mutation = useMutation(save, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al guardar los datos', 'error')
      } else {
        reset(); setDataInstance(PoliticianSchema);
        reportAction(true, 'Acción realizada correctamente', 'success')
      }
    }
  })

  const handleAdd = (params) => {
    const formData = new FormData()
    for(let dataKey in params) {
      if([ "academic_degree", "facebook_account", "twitter_account", "instagram_account", "annual_activities_report", "three_of_three_report", ].includes(dataKey)) {
        for (let previewKey in params[dataKey]) {
          formData.append(`${dataKey}[${previewKey}]`, params[dataKey][previewKey]);
        }
      } else if(dataKey === "photo") {
        formData.append(dataKey, params[dataKey][0]);
      } else if(dataKey === "radiography") {
        formData.append(dataKey, params[dataKey][0]);
      } else {
        formData.append(dataKey, params[dataKey]);
      }
    }
    for (let val of formData.entries()) {
      console.log(val[0]+ ', ' + val[1]); 
    }

    setIsLoading(true)
    mutation.mutate(formData)
  }

  const handleClose = () => { reset(); setDataInstance(PoliticianSchema); close() }

  const handleOnChangeData = (event) => {
    if (event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeBoolean = (name) => {
    if (name.includes(".")) {
      const separate = name.split(".")
      const originalValue = dataInstance[separate[0]][separate[1]]
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: !originalValue } })
      return;
    }
    setDataInstance({ ...dataInstance, [name]: !dataInstance[name] })
  }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>{t("button_text_cancel")}</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)} disabled={isEmptyObject(dataInstance)} >{t("button_text_add")}</Button>
    </> : <CircularProgress />}
  </>)

  return (<>
    <FlexibleWidthDialog open={open} maxWidth="lg" title={t('commissions_dialog_title_add')} actions={<Actions />}>
      {politicianParties.length > 0 && districts.length > 0 ?
        <SharedForm
          typeAction="add"
          dataInstance={dataInstance}
          setDataInstance={setDataInstance}
          politicalParties={politicianParties}
          districts={districts}
          handleOnChangeData={handleOnChangeData}
          handleChangeBoolean={handleChangeBoolean}
          register={register}
          errors={errors} />
        : null}
    </FlexibleWidthDialog>
  </>)
}