import { MenuItem } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import Edit from "./Edit";

export function ActionsDialogPage({ open, close, instance, reportAction }) {
  return (<>
    <Edit
      open={open === 2}
      close={close}
      instance={instance}
      reportAction={reportAction} />
  </>)
}

export function ActionsMenuPage({ t, action }) {
  // 2: edit
  return (<>
    <MenuItem onClick={() => { action(2)}} disableRipple>
      <EditIcon /> Editar
    </MenuItem>
  </>)
}