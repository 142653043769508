import { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from '@tanstack/react-query'
import { SchemaValidationEdit } from "../services/schemaValidation";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { edit } from "../services/api";
import { useTranslation } from "react-i18next";
import { isEmptyObject } from "helpers";
import SharedForm from "../modules/SharedForm";

export default function Edit({ open, close, instance, reportAction }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  let { reset, handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(SchemaValidationEdit(t))
  })

  useEffect(() => {
    delete instance.password
    if (open) { setDataInstance(instance); reset(instance); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const mutation = useMutation(edit, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al guardar los datos', 'error')
      } else {
        reset(); setDataInstance({});
        reportAction(true, 'Acción realizada correctamente', 'success')
      }
    }
  })

  const handleEdit = (params) => {
    console.log(params.logo[0])
    const formData = new FormData()
    formData.append('logo', params.logo)
    formData.append('logo_file', params.logo_file[0])
    formData.append('name', params.name)
    formData.append('abbreviation', params.abbreviation)
    formData.append('foundation_year', params.foundation_year)
    formData.append('ideology', params.ideology)
    formData.append('facebook_account', params.facebook_account)
    formData.append('twitter_account', params.twitter_account)
    formData.append('instagram_account', params.instagram_account)
    formData.append('website', params.website)
    formData.append('active', params.active)
    setIsLoading(true)
    let id = params._id
    mutation.mutate({ id, params: formData })
  }

  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => {
    console.log(event.target.name, event.target.value)
    if (event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeBoolean = (name) => {
    setDataInstance({ ...dataInstance, [name]: !dataInstance[name] })
  }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>{t("button_text_cancel")}</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)} disabled={isEmptyObject(dataInstance)} >{t("button_text_edit")}</Button>
    </> : <CircularProgress />}
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={t('politicalparties_dialog_title_edit')} actions={<Actions />}>
      <SharedForm
        type="edit"
        dataInstance={dataInstance}
        handleOnChangeData={handleOnChangeData}
        handleChangeBoolean={handleChangeBoolean}
        register={register}
        errors={errors} />
    </FlexibleWidthDialog>
  </>)
}