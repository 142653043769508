export const categoriasPartidistas = [
  {
    name: "Switch PRI",
    color: "#C6E0B4"
  },
  {
    name: "Hegemónico PRI",
    color: "#00B050"
  },
  {
    name: "Tripartidista PRI",
    color: "#00F26D"
  },
  {
    name: "Dominante MORENA",
    color: "#FF1D1D"
  },
  {
    name: "Neo MORENA",
    color: "#FF6565"
  },
  {
    name: "Bipartidista MORENA",
    color: "#FCE4D6"
  },
  {
    name: "Neo PVEM",
    color: "#85BD5F"
  },
  {
    name: "NA",
    color: "#BFBFBF"
  },
  {
    name: "Dominante PRI",
    color: "#8BC167"
  },
  {
    name: "Tripartidista PVEM",
    color: "#AFDC7E"
  },
  {
    name: "Tripartidista PMC",
    color: "#F0904E"
  },
  {
    name: "Tripartidista MORENA",
    color: "#FF5B5B"
  },
  {
    name: "Neo PRI",
    color: "#A9D08E"
  },
  {
    name: "Hegemónico MORENA",
    color: "#C00000"
  },
  {
    name: "Switch PAN",
    color: "#DDEBF7"
  },
  {
    name: "Tripartidista PAN",
    color: "#8EA9DB"
  },
  {
    name: "Hegemónico PAN",
    color: "#2F75B5"
  },
  {
    name: "Dominante PAN",
    color: "#9BC2E6"
  },
  {
    name: "Tripartidista PES",
    color: "#FF00FF"
  },
  {
    name: "Neo PMC",
    color: "#F4B084"
  },
  {
    name: "Neo PAN",
    color: "#BDD7EE"
  },
  {
    name: "Dominante PVEM",
    color: "#A9D08E"
  },
  {
    name: "Switch PMC",
    color: "#F8CBAD"
  },
  {
    name: "Hegemónico PRD",
    color: "#FFC000"
  },
  {
    name: "Dominante PRD",
    color: "#F8F200"
  },
  {
    name: "Neo PT",
    color: "#F63B00"
  },
  {
    name: "Switch MORENA",
    color: "#FFB3B3"
  },
  {
    name: "Tripartidista PNA",
    color: "#8FE2FF"
  },
  {
    name: "Tripartidista PRD",
    color: "#FFD966"
  },
  {
    name: "Dominante PNA",
    color: "#7DDDFF"
  }
]