import { Autocomplete, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Switch, TextField } from "@mui/material"
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material"
import { ValidateErrorMessage } from "components/ValidateErrorMessage"
import { useState } from "react"
import { TextMaskCustom } from "./custom"

export const LabelBuilder = ({ label, htmlFor, required }) => {
  return (
    <InputLabel
      htmlFor={htmlFor}
      required={required}
      style={{ fontSize: 14, fontWeight: "bold" }}
    >
      {label}
    </InputLabel>
  )
}

const InputContainerBuilder = ({ children }) => <div style={{ marginBottom: 8 }}> {children} </div>

export const InputTextBuilder = ({
  label, id, name, required, instance, handleOnChangeData, errors, register, type = "text"
}) => {
  const defaultValueFromInstace = id.includes(".") ? instance[id.split(".")[0]][id.split(".")[1]] : instance[id]

  return (<InputContainerBuilder>
    <LabelBuilder label={label} htmlFor={id} required={required} />
    <TextField
      fullWidth margin="dense" size="small" type={type}
      id={id} name={name} value={defaultValueFromInstace || ''}
      {...register(id)}
      error={errors[id] ? true : false}
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors[id]?.message} />
  </InputContainerBuilder>)
}

export const InputTextMaskBuilder = ({
  label, id, name, required, mask, instance, handleOnChangeData, errors, register, type = "text"
}) => {
  const defaultValueFromInstace = id.includes(".") ? instance[id.split(".")[0]][id.split(".")[1]] : instance[id]
  const error = id.includes(".") ?
    errors[id.split(".")[0]] !== undefined ? errors[id.split(".")[0]][id.split(".")[1]] : false
    : errors[id]

  return (<InputContainerBuilder>
    <LabelBuilder label={label} htmlFor={id} required={required} />
    <TextField
      fullWidth margin="dense" size="small" type={type}
      id={id} name={name} value={defaultValueFromInstace || ''}
      {...register(id)}
      error={error ? true : false}
      onChange={handleOnChangeData}
      InputProps={{ inputComponent: TextMaskCustom }}
      inputProps={{ mask: mask }}
    />
    <ValidateErrorMessage message={error?.message} />
  </InputContainerBuilder>)
}

export const InputPasswordBuilder = ({
  label, id, name, required, instance, handleOnChangeData, errors, register
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => { setShowPassword(!showPassword) }
  const handleMouseDownPassword = (event) => { event.preventDefault() }

  const defaultValueFromInstace = id.includes(".") ? instance[id.split(".")[0]][id.split(".")[1]] : instance[id]

  return (<InputContainerBuilder>
    <LabelBuilder label={label} htmlFor={id} required={required} />
    <FormControl variant="outlined" fullWidth margin="normal" required={required} style={{ marginTop: 8, marginBottom: 16 }}>
      <OutlinedInput required fullWidth size="small"
        type={showPassword ? 'text' : 'password'}
        id={id} name={name} defaultValue={defaultValueFromInstace || ''}
        onChange={handleOnChangeData}
        {...register(id)}
        error={errors[id] ? true : false}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" edge="end"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            > {showPassword ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />} </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
    <ValidateErrorMessage message={errors[id]?.message} />
  </InputContainerBuilder>)
}

export const InputTextAreaBuilder = ({
  label, id, name, required, instance, handleOnChangeData, errors, register
}) => {
  return (<InputContainerBuilder>
    <LabelBuilder label={label} htmlFor={id} required={required} />
    <TextField
      fullWidth margin="dense" size="small" type="text" multiline rows={4}
      id={id} name={name} value={instance[name] || ''}
      {...register(id)}
      error={errors[id] ? true : false}
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors[id]?.message} />
  </InputContainerBuilder>)
}

export const InputNumberBuilder = ({
  label, id, name, required, instance, handleOnChangeData, errors, register
}) => {
  return (<InputContainerBuilder>
    <LabelBuilder label={label} htmlFor={id} required={required} />
    <TextField
      fullWidth margin="dense" size="small" type="number"
      id={id} name={name} value={instance[name] || ''}
      {...register(id)}
      error={errors[id] ? true : false}
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors[id]?.message} />
  </InputContainerBuilder>)
}

export const InputSwitchBuilder = ({
  label, id, required, instance, handleOnChangeData, register
}) => {

  const defaultValueFromInstace = id.includes(".") ? instance[id.split(".")[0]][id.split(".")[1]] : instance[id]

  return (<InputContainerBuilder>
    <LabelBuilder label={label} htmlFor={id} required={required} />
    <Switch edge="end" required={required} inputProps={{ 'aria-labelledby': `switch-list-label-${id}` }}
      onClick={() => handleOnChangeData(id)}
      checked={defaultValueFromInstace || false}
      {...register(id)}
    />
  </InputContainerBuilder>)
}

export const InputFileImageBuilder = ({
  label, id, required, errors, register, handleOnChangeData, setValue
}) => {
  const [previewImage, setPreviewImage] = useState('')

  const handlePreviewImage = (event) => {
    let file = event.target.files[0]
    if (file) {
      let reader = new FileReader()
      reader.onloadend = () => { setPreviewImage(reader.result) }
      reader.readAsDataURL(file)
    }
  }

  return (<InputContainerBuilder>
    <LabelBuilder label={label} htmlFor={id} required={required} />

    <img src={previewImage} alt="" style={{ height: 100 }} /><br />

    <Button variant="contained" component="label">
      Subir archivo
      <input
        type="file"
        hidden
        id={id}
        name={id}
        {...register(id, {
          onChange: handlePreviewImage
        })}
      />
    </Button>
    <ValidateErrorMessage message={errors[id]?.message} />
  </InputContainerBuilder>)
}

export const InputSelectBuilder = ({
  label, id, name, required, instance, handleOnChangeData, errors, register, options
}) => {
  const defaultValueFromInstace = id.includes(".") ? instance[id.split(".")[0]][id.split(".")[1]] : instance[id]
  const error = id.includes(".") ?
    errors[id.split(".")[0]] !== undefined ? errors[id.split(".")[0]][id.split(".")[1]] : false
    : errors[id]

  return (<InputContainerBuilder>
    <LabelBuilder label={label} htmlFor={id} required={required} />
    <TextField
      fullWidth margin="dense" size="small" type="text"
      id={id} name={name} value={defaultValueFromInstace || ''}
      {...register(id)}
      error={error ? true : false}
      onChange={handleOnChangeData}
      select>
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
    <ValidateErrorMessage message={error?.message} />
  </InputContainerBuilder>)
}

export const InputAutocompleteBuilder = ({
  label, id, name, required, instance, handleOnChangeData, errors, register, options
}) => {
  const defaultValueFromInstace = id.includes(".") ? instance[id.split(".")[0]][id.split(".")[1]] : instance[id]
  const error = id.includes(".") ?
    errors[id.split(".")[0]] !== undefined ? errors[id.split(".")[0]][id.split(".")[1]] : false
    : errors[id]

  return (<InputContainerBuilder>
    <LabelBuilder label={label} htmlFor={id} required={required} />
    <Autocomplete
      multiple
      id={id}
      options={options}
      getOptionLabel={(option) => option.label}
      value={defaultValueFromInstace || []}
      onChange={(event, newValue) => handleOnChangeData(name, newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          placeholder="Selecciona los distritos"
          error={error ? true : false}
        />
      )}
    />
    <ValidateErrorMessage message={error?.message} />
  </InputContainerBuilder>)
}