import { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from '@tanstack/react-query'
import { SchemaValidation } from "../services/schemaValidation";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { edit } from "../services/api";
import { useTranslation } from "react-i18next";
import { isEmptyObject } from "helpers";
import SharedForm from "../modules/SharedForm";

export default function Edit({ open, close, instance, districts, reportAction }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  let { reset, handleSubmit, register, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(SchemaValidation(t))
  })

  useEffect(() => {
    delete instance.password
    if (open) { 
      let instanceTemp = {...instance}
      instanceTemp.districts = instanceTemp.districts.map((item) => item._id)
      
      if (districts.length > 0) {
        let districtsTemp = []
        instanceTemp.districts.forEach((item) => {
          districtsTemp.push(districts.find((district) => district.value === item))
        })
        instanceTemp.districts = districtsTemp
      }
      setDataInstance(instanceTemp); 
      reset(instance); 
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const mutation = useMutation(edit, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al guardar los datos', 'error')
      } else {
        reset(); setDataInstance({});
        reportAction(true, 'Acción realizada correctamente', 'success')
      }
    }
  })

  const handleEdit = (params) => {
    setIsLoading(true)
    let id = params._id
    delete params._id
    delete params.createdAt
    delete params.updatedAt
    delete params.deleted
    mutation.mutate({ id, params })
  }

  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => {
    if (event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleOnChangeAutocomplete = (name, value) => {
    setDataInstance({ ...dataInstance, [name]: value })
    const values = value.map((item) => item.value)
    setValue(name, values.length > 0 ? values : null)
  }

  const handleChangeBoolean = (name) => {
    setDataInstance({ ...dataInstance, [name]: !dataInstance[name] })
  }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>{t("button_text_cancel")}</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)} disabled={isEmptyObject(dataInstance)} >{t("button_text_edit")}</Button>
    </> : <CircularProgress />}
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={t('municipalities_dialog_title_edit')} actions={<Actions />}>
      <SharedForm
        dataInstance={dataInstance}
        districts={districts}
        handleOnChangeData={handleOnChangeData}
        handleOnChangeAutocomplete={handleOnChangeAutocomplete}
        handleChangeBoolean={handleChangeBoolean}
        register={register}
        errors={errors} />
    </FlexibleWidthDialog>
  </>)
}