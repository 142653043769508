import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"

export const Filters = ({
  switchGeoData,
  handleVisibilityTypeSelect,
  year,
  handleYearSelect
}) => {
  return (
    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Tipo de visibilidad</InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={switchGeoData}
            label="Tipo de visibilidad"
            onChange={handleVisibilityTypeSelect} >
            <MenuItem value={1}>Distrito Electoral</MenuItem>
            <MenuItem value={2}>Secciones</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Año</InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={year}
            label="Año"
            onChange={handleYearSelect} >
            <MenuItem value="2015">2015</MenuItem>
            <MenuItem value="2018">2018</MenuItem>
            <MenuItem value="2021">2021</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}