import * as Yup from "yup"

export const SchemaValidationAdd = (t) => Yup.object().shape({
  full_name: Yup.string().required("Campo requerido"),
  photo: Yup.mixed()
    .test("type", "Solo imagenes", function (value) {
      console.log("photo", value)
      if (value && !value.length) {
        return true
      }
      return value && value[0] && value[0].type.includes("image");
    }),
  birthday: Yup.string().required("Campo requerido"),
  gender: Yup.string().required("Campo requerido"),
  academic_degree: Yup.object().shape({
    degree: Yup.string().required("Campo requerido"),
  }),
  last_job: Yup.string(),
  hobbies: Yup.string(),
  facebook_account: Yup.object().shape({
    page: Yup.string(),
    followers: Yup.string()
  }),
  twitter_account: Yup.object().shape({
    page: Yup.string(),
    followers: Yup.string()
  }),
  instagram_account: Yup.object().shape({
    page: Yup.string(),
    followers: Yup.string()
  }),
  website: Yup.string(),
  political_party: Yup.string().required("Campo requerido"),
  chosen_by: Yup.string().required("Campo requerido"),
  district: Yup.string()
    .when('chosen_by', {
      is: "MR",
      then: schema => schema.required("Campo requerido")
    }),
  legislative_experience: Yup.boolean().required("Campo requerido"),
  annual_activities_report: Yup.object().shape({
    isSubmitted: Yup.boolean().required("Campo requerido"),
  }),
  three_of_three_report: Yup.object().shape({
    isSubmitted: Yup.boolean().required("Campo requerido"),
    date: Yup.string().when('$isSubmitted', {
      is: true,
      then: Yup.string().required("Campo requerido")
    })
  }),
  active: Yup.boolean()
})

export const SchemaValidationEdit = (t) => Yup.object().shape({
  full_name: Yup.string().required("Campo requerido"),
  photo: Yup.mixed(),
  photo_file: Yup.mixed()
    .test("type", "Solo imagenes", function (value) {
      console.log("photo", value)
      if (value && !value.length) {
        return true
      }
      return value && value[0] && value[0].type.includes("image");
    }),
  birthday: Yup.string().required("Campo requerido"),
  gender: Yup.string().required("Campo requerido"),
  academic_degree: Yup.object().shape({
    degree: Yup.string().required("Campo requerido"),
  }),
  last_job: Yup.string(),
  hobbies: Yup.string(),
  radiography: Yup.mixed(),
  radiography_file: Yup.mixed()
    .test("type", "Solo imagenes", function (value) {
      console.log("radiography", value)
      if (value && !value.length) {
        return true
      }
      return value && value[0] && value[0].type.includes("image");
    }),
  facebook_account: Yup.object().shape({
    page: Yup.string(),
    followers: Yup.string()
  }),
  twitter_account: Yup.object().shape({
    page: Yup.string(),
    followers: Yup.string()
  }),
  instagram_account: Yup.object().shape({
    page: Yup.string(),
    followers: Yup.string()
  }),
  website: Yup.string(),
  political_party: Yup.string().required("Campo requerido"),
  district: Yup.string().required("Campo requerido"),
  chosen_by: Yup.string().required("Campo requerido"),
  legislative_experience: Yup.boolean().required("Campo requerido"),
  annual_activities_report: Yup.object().shape({
    isSubmitted: Yup.boolean().required("Campo requerido"),
  }),
  three_of_three_report: Yup.object().shape({
    isSubmitted: Yup.boolean().required("Campo requerido"),
    date: Yup.string().when('$isSubmitted', {
      is: true,
      then: Yup.string().required("Campo requerido")
    })
  }),
  active: Yup.boolean()
})