import { useState } from "react";
import { FormControlLabel, Grid } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAll, updateActive, updateOrder } from "./services/api";
import { SnackbarGlobal } from "components/Snackbar";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Android12Switch, StyledMenu } from "components/Styled";
import { useTranslation } from "react-i18next";
//import { Subcategories } from "../Subcategories";
import { ActionFabButtonAdd, ActionsDialogPage, ActionsMenuPage } from "./ActionsPage";
import { ReactBeautifulDNDUpdateOrder } from "./routeHelpers";
import { DraggableRowAccordionContent, DraggableRowActivateAccordionIcon, DraggableRowButtonMenu, DraggableRowDragIndicatorIcon, DraggableRowImage, DraggableRowText } from "components/Builder/DraggableRow";
import { Categories } from "./Categories";

export default function Folders() {
  const { t } = useTranslation();
  const [notification, setNotification] = useState({ open: false, message: "", severity: "" })
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: delete
  const [instanceSelected, setInstanceSelected] = useState({})
  const [showCategories, setShowCategories] = useState(null)
  const queryClient = useQueryClient()
  const { data } = useQuery(['foldersList'], getAll, { refetchOnWindowFocus: false })

  const handleResetActions = () => { setOpenAction(0) }
  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity })
    if (success) { handleResetActions(); queryClient.invalidateQueries('foldersList') }
  }

  const [anchorElMenuActions, setAnchorElMenuActions] = useState(null)
  const open = Boolean(anchorElMenuActions)
  const handleClickMenuActions = (event, item) => {
    setAnchorElMenuActions(event.currentTarget); setInstanceSelected(item);
  }
  const handleCloseMenuActions = () => setAnchorElMenuActions(null)
  const handleMenuItemClick = (option) => { setOpenAction(option); handleCloseMenuActions(); }

  /**
   * Mutation to execute function to order a folder
   */
  const mutation = useMutation(updateOrder, {
    onSuccess: (data) => { if (data.status === "success") { handleWatchAction(true, 'Acción realizada correctamente', 'success') } }
  })
  const handlerOrder = (params) => mutation.mutate(params)
  const handleDrop = (droppedItem) => {
    const order = ReactBeautifulDNDUpdateOrder(data.data, droppedItem)
    if (order === false) return
    handlerOrder({ value: order })
  }

  const mutationUpdateActive = useMutation(updateActive, {
    onSuccess: (data) => {
      if (data.status === "error") { handleWatchAction(false, 'Error al guardar los datos', 'error') }
      else { handleWatchAction(true, 'Acción realizada correctamente', 'success') }
    }
  })
  const handleUpdateActive = (item) => mutationUpdateActive.mutate({ id: item._id, params: { active: !item.active } })

  return (<>
    <Grid container spacing={0}>
      {data !== undefined ?
        <Grid item xs={12}>
          {data.data.length > 0 ?
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <Grid container spacing={0} className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
                    {data.data.map((folder, index) => (
                      <Draggable key={`${index}-${folder._id}`} draggableId={folder._id} index={index} isDragDisabled={showCategories !== null}>
                        {(provided) => (
                          <Grid ref={provided.innerRef} {...provided.draggableProps} container spacing={0} sx={{ padding: "10px 0px", alignItems: "center", borderTop: "1px solid rgba(224, 224, 224, 1)", backgroundColor: showCategories === folder._id ? "rgba(33, 33, 69, 0.2)" : "#ffffff" }}>
                            <Grid item xs={1} {...provided.dragHandleProps}> <DraggableRowDragIndicatorIcon disabled={showCategories !== null} /> </Grid>
                            <Grid item xs={7} sx={{ fontWeight: showCategories === folder._id ? "bold" : "normal" }}> <DraggableRowText text={folder.name} /> </Grid>
                            <Grid item xs={3}>
                              <DraggableRowButtonMenu
                                open={open}
                                disabled={showCategories !== null}
                                action={(event) => handleClickMenuActions(event, folder)} />
                              <FormControlLabel sx={{ marginRight: 0 }} control={<Android12Switch checked={folder.active} disabled={showCategories !== null} onClick={() => handleUpdateActive(folder)} />} />
                            </Grid>
                            <Grid item xs={1}>
                              <DraggableRowActivateAccordionIcon
                                active={showCategories === folder._id}
                                action={() => setShowCategories(showCategories === null || showCategories !== folder._id ? folder._id : null)} />
                            </Grid>
                            <DraggableRowAccordionContent show={showCategories === folder._id}>
                              <Categories folder={folder._id} active={showCategories} />
                            </DraggableRowAccordionContent>
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
            : <p>NO hay registros</p>
          }
        </Grid>
        : null}
    </Grid>
    {showCategories === null ? <ActionFabButtonAdd t={t} action={() => setOpenAction(1)} /> : null }
    <StyledMenu
      id={`demo-customized-menu`}
      MenuListProps={{ 'aria-labelledby': `demo-customized-button` }}
      anchorEl={anchorElMenuActions}
      open={open}
      onClose={handleCloseMenuActions} >
      <ActionsMenuPage t={t} action={handleMenuItemClick} />
    </StyledMenu>
    <ActionsDialogPage
      open={openAction}
      close={() => handleResetActions()}
      instance={instanceSelected}
      reportAction={handleWatchAction} />
    <SnackbarGlobal
      open={notification.open}
      close={() => setNotification({ open: false, message: "", severity: "" })}
      message={notification.message}
      severity={notification.severity} />
  </>)
}