import { API_BASE_URL, HEADERS_WITH_TOKEN, HEADERS_WITH_TOKEN_WITHOUT_CONTENTTYPE } from "services/api/BaseUrl"

export const getAll = async () => {
  const res = await fetch(
    `${API_BASE_URL}/politicians-assistance`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt'))
    }
  )
  return await res.json()
}

export const downloadTemplate = async () => {
  const res = await fetch(
    `${API_BASE_URL}/politicians-assistance/data-generate-csv`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt'))
    }
  )
  return await res.json()
}

export const load = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/politicians-assistance`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN_WITHOUT_CONTENTTYPE(localStorage.getItem('LOAt')),
      body: params
    }
  )
  return await res.json()
}