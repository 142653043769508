import { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useMutation } from '@tanstack/react-query'
import { getAll, updateActive } from "./services/api";
import { SnackbarGlobal } from "components/Snackbar";
import { StyledMenu } from "components/Styled";
import { FMPageOpacityTransition } from "components/FMTransitions/PageTransitions";
import { ActionFabButtonAdd, ActionsDialogPage, ActionsMenuPage } from "./ActionsPage";
import { MUIDatatableCustomBodyRenderActions, MUIDatatableOptions, getActive, handleSetInstance } from "./routeHelpers";
import MUIDataTable from "mui-datatables";
import { MUIDataTableGlobalOptions } from "helpers";
import LoaderTable from "components/Loaders/LoaderTable";
import { useUserRole } from "hooks/useUserRole";

export default function Politician() {
  useUserRole(["SUPERADMIN", "ADMIN", "EDITOR"])
  const { t } = useTranslation();
  const globalUser = JSON.parse(localStorage.getItem('mqadusr'))
  const [loadingUpdateActive, setLoadingUpdateActive] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [dataFilter, setDataFilter] = useState(null)
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: delete
  const [notification, setNotification] = useState({ open: false, message: "", severity: "" })
  const [instanceSelected, setInstanceSelected] = useState({})
  const queryClient = useQueryClient()
  const { data } = useQuery(['politiciansList'], getAll, { refetchOnWindowFocus: false })

  useEffect(() => {
    if (data !== undefined && data !== null && data !== "") {
      setDataFilter(data.data)
      setItemId(null)
    }
  }, [data])

  const mutation = useMutation(updateActive, {
    onSuccess: (data) => {
      if (data.status === "error") { handleWatchAction(false, 'Error al guardar los datos', 'error') }
      else { handleWatchAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleUpdateActive = (id) => {
    setLoadingUpdateActive(true)
    setItemId(id)
    const active = getActive(id, data)
    mutation.mutate({ 
      id: id, 
      params: { active: !active } 
    })
  }

  const handleResetActions = () => setOpenAction(0)
  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity })
    if (success) { handleResetActions(); queryClient.invalidateQueries(['politiciansList']) }
  }

  const [anchorElMenuActions, setAnchorElMenuActions] = useState(null)
  const open = Boolean(anchorElMenuActions)
  const handleClickMenuActions = (event, value) => {
    setAnchorElMenuActions(event.currentTarget); handleSetInstance(value, data, setInstanceSelected);
  }
  const handleCloseMenuActions = () => setAnchorElMenuActions(null)
  const handleMenuItemClick = (option) => { setOpenAction(option); handleCloseMenuActions(); }

  const columns = MUIDatatableOptions(t, MUIDatatableCustomBodyRenderActions, open, handleClickMenuActions, loadingUpdateActive, itemId, getActive, data, handleUpdateActive)

  return (<>
    <FMPageOpacityTransition>
      <Box component="main" style={{ marginBottom: 100, }}>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {(dataFilter !== null) ?
            <MUIDataTable
              data={dataFilter}
              columns={columns}
              options={MUIDataTableGlobalOptions(t)} />
            : <Paper sx={{ padding: 3 }}> <LoaderTable /> </Paper>
          }
        </Container>
      </Box>
      <ActionFabButtonAdd t={t} action={() => setOpenAction(1)} />
      <ActionsDialogPage
        open={openAction}
        close={() => handleResetActions()}
        instance={instanceSelected}
        reportAction={handleWatchAction} />
      <StyledMenu
        id="demo-customized-menu"
        anchorEl={anchorElMenuActions}
        open={open}
        onClose={handleCloseMenuActions}
        MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}>
        <ActionsMenuPage t={t} action={handleMenuItemClick} />
      </StyledMenu>
    </FMPageOpacityTransition>
    <SnackbarGlobal
      open={notification.open}
      close={() => setNotification({ open: false, message: "", severity: "" })}
      message={notification.message}
      severity={notification.severity} />
  </>)
}