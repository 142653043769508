import { InputTextBuilder } from "components/InputBuilder";

export default function SharedForm(
  { dataInstance, handleOnChangeData, register, errors }
) {
  return (<>
    <InputTextBuilder
      label="Nombre" id="name" name="name" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
  </>)
}