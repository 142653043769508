import { useEffect, useState } from "react";
import { Polygon, Tooltip, useMap } from "react-leaflet";


export default function SetBoundsRectangles({ coordinates, infoSticky }) {
  const [color, setColor] = useState("purple");
  const [opacitySelected, setOpacitySelected] = useState(null);
  const map = useMap();

  useEffect(() => {
    if (coordinates.length > 0) {
      console.log("coordinates", coordinates);
      const onlyCoordinates = coordinates.map((item) => item.coordinates);
      map.fitBounds(onlyCoordinates);
    }
  }, [coordinates]);

  const clickCustom = () => {
    if (color === "purple") {
      setColor("red");
    } else {
      setColor("purple");
    }
  }

  return (
    <div>
      {coordinates.map((item, index) => (
        <Polygon
          key={index}
          eventHandlers={{
            click: () => {
              infoSticky(item);
              setOpacitySelected(index);
              map.fitBounds(item.coordinates);
            }
          }}
          pathOptions={{
            color: item.color,
            fillColor: item.color,
            opacity: opacitySelected === index ? 1 : 0.9,
            fillOpacity: opacitySelected === index ? 1 : 0.9
          }}
          positions={item.coordinates}
        >
          <Tooltip sticky>
            <div>
              <div>
                <b>Identificador: </b> {item.name}
              </div>
            </div>
          </Tooltip>
        </Polygon>
      ))}
    </div>
  );
}

//https://github.com/pedroez-isc16/geo-data-backend/blob/diego-dev/src/controllers/MunicipalityController/cuadros.js

/* function SetBoundsRectangles({ cordenadas, cordenadasMunicipio }) {
  const [bounds, setBounds] = useState(cordenadas);
  const map = useMap();

  const clickCustom = ({ coordinates, cordenadasMunicipio,}) => {
    setBounds(coordinates);
    map.fitBounds(coordinates);
  };

  useEffect(() => {
    if (cordenadasMunicipio.length > 0) {
      setBounds(cordenadasMunicipio[0].coordinates);
      map.fitBounds(cordenadasMunicipio[0].coordinates);
    } else if (cordenadas.length > 0) {
      const cor = cordenadas.map((item) => item.coordinates);
      setBounds(cor);
      map.fitBounds(cor);
    }
  }, [cordenadasMunicipio]);

  const purpleOptions = { color: "purple" };
  const RedOption = { color: "red" };

  return (
    <div>
      {cordenadasMunicipio.length > 0
        ? cordenadasMunicipio.map((item, index) => {
          let municipality = {
            nominal: 0,
            percent: 0,
          };
          if (municipios.length > 0) {
            municipality = municipios.find(
              (m) =>
                String(m.name)
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "") ===
                String(item.name)
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
            );
          }
          return (
            municipios.length > 0 && (
              <Polygon
                bounds={cordenadasMunicipio}
                eventHandlers={{
                  click: () =>
                    clickCustom({ coordinates: item.coordinates }),
                }}
                key={index}
                pathOptions={
                  municipality !== undefined
                    ? {
                      color: "purple",
                    }
                    : { color: "purple" }
                }
                positions={item.coordinates}
              >
                <Tooltip sticky>
                  {item.name}
                  {municipality !== undefined && (
                    <>
                      <p>Lista Nominal: {municipality.nominal}</p>
                      <p>
                        Porcentaje de avance:
                        {`${municipality.percent.split("%")[0] * 100} %`}
                      </p>
                    </>
                  )}
                </Tooltip>
              </Polygon>
            )
          );
        })
        : cordenadas.length > 0 &&
        cordenadas.map((item, index) => {
          let municipality = {
            nominal: 0,
            percent: 0,
          };
          console.log(municipality);
          if (municipios.length > 0) {
            municipality = municipios.find(
              (m) =>
                String(m.name)
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "") ===
                String(item.name)
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
            );
          }

          return (
            municipios.length > 0 && (
              <Polygon
                bounds={cordenadas}
                eventHandlers={{
                  click: () =>
                    clickCustom({ coordinates: item.coordinates }),
                }}
                key={index}
                // pathOptions={purpleOptions}
                pathOptions={
                  municipality !== undefined
                    ? {
                      color: "purple",
                    }
                    : { color: "purple" }
                }
                positions={item.coordinates}
              >
                <Tooltip sticky>
                  {item.name}
                  {municipality !== undefined && (
                    <>
                      <p>Lista Nominal: {municipality.nominal}</p>
                      <p>
                        Porcentaje de avance:
                        {`${municipality.percent.split("%")[0] * 100} %`}
                      </p>
                    </>
                  )}
                </Tooltip>
              </Polygon>
            )
          );
        })}
    </div>
  );
} */