import { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from '@tanstack/react-query'
import { SchemaValidation } from "../services/schemaValidation";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { save } from "../services/api";
import { useTranslation } from "react-i18next";
import { isEmptyObject } from "helpers";
import SharedForm from "../modules/SharedForm";

export default function Add({ open, close, folder, reportAction }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  let { reset, handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(SchemaValidation(t))
  })

  const mutation = useMutation(save, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al guardar los datos', 'error')
      } else {
        reset(); setDataInstance({});
        reportAction(true, 'Acción realizada correctamente', 'success')
      }
    }
  })

  const handleAdd = (params) => {
    setIsLoading(true)
    params.folder = folder
    mutation.mutate(params)
  }

  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => {
    if (event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeBoolean = (name) => {
    setDataInstance({ ...dataInstance, [name]: !dataInstance[name] })
  }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>{t("button_text_cancel")}</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)} disabled={isEmptyObject(dataInstance)} >{t("button_text_add")}</Button>
    </> : <CircularProgress />}
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title="Agregar" actions={<Actions />}>
      <SharedForm
        dataInstance={dataInstance} 
        handleOnChangeData={handleOnChangeData}
        handleChangeBoolean={handleChangeBoolean} 
        register={register} 
        errors={errors} />
    </FlexibleWidthDialog>
  </>)
}