import { Card, CardContent, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { RemoveRedEye as RemoveRedEyeIcon } from "@mui/icons-material";

const detectTypeFile = (type) => {
  switch (type) {
    case "image/jpeg":
      return "/images/file-types/jpg.png"
    case "image/png":
      return "/images/file-types/png.png"
    case "application/pdf":
      return "/images/file-types/pdf.png"
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "/images/file-types/doc.png"
    case "text/csv":
      return "/images/file-types/csv.png"
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "/images/file-types/ppt.png"
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "/images/file-types/xls.png"
    default:
      return "/images/file-types/txt.png"
  }
}

const MBOfFile = (size) => {
  const sizeMB = size / 1024 / 1024
  return sizeMB.toFixed(2) + " MB"
}

export const CardDocumentPolitician = ({ file, typeShow = "grid", onDownload }) => {
  return (
    <Card sx={{ maxWidth: "100%", borderRadius: typeShow === "grid" ? "15px" : "8px" }}>
      <CardContent sx={{ paddingBottom: typeShow === "grid" ? "24px" : "16px !important" }}>
        {typeShow === "grid" ? <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img src={detectTypeFile(file.type)} alt="csv" style={{ maxWidth: 40 }} />
            <div>
              <Tooltip title="Ver"> <IconButton onClick={() => onDownload({name: file.name, path: file.path})}><RemoveRedEyeIcon /></IconButton> </Tooltip>
            </div>
          </div>
          <div style={{ margin: "15px 0px" }}>
            <Typography variant="body2" color="text.secondary" noWrap sx={{ fontWeight: "bold" }}>{file.name}</Typography>
          </div>
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: 13 }}>{MBOfFile(file.size)}</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: 13 }}>{new Date(file.createdAt).toLocaleDateString('es-MX', { year: "numeric", month: "long", day: "2-digit" })}</Typography>
        </> : <>
          <Grid container spacing={1} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Grid item xs={2}>
              <img src={detectTypeFile(file.type)} alt="csv" style={{ maxWidth: 40 }} />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color="text.secondary" noWrap sx={{ fontWeight: "bold" }}>{file.name}</Typography>
              <Typography variant="body2" color="text.secondary" noWrap sx={{ fontSize: 13 }}>{new Date(file.createdAt).toLocaleDateString('es-MX', { year: "numeric", month: "long", day: "2-digit" })}</Typography>
              <Typography variant="body2" color="text.secondary" noWrap sx={{ fontSize: 13 }}>{MBOfFile(file.size)}</Typography>
            </Grid>
            <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title="Ver"> <IconButton onClick={() => onDownload({name: file.name, path: file.path})}><RemoveRedEyeIcon /></IconButton> </Tooltip>
            </Grid>
          </Grid>
        </>}
      </CardContent>
    </Card>
  )
}