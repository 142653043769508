//export const API_GEOPOLITICAL_URL = 'http://localhost:8080/api/v1'; // Local
export const API_GEOPOLITICAL_URL = 'https://geopolitical-microservice-hltza4osjq-uc.a.run.app/api/v1'; // Produccion

export const HEADERS_API = () => {
  return {
    'Content-Type': 'application/json',
  }
}

export const getDemographicCategories = async () => {
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/demographic-categories`,
    {
      method: 'GET',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}


export const getDistrict = async (params) => {
  const { id, year, category, subcategory } = params
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/observatorio-legislativo/demographic-results/localdistrict/${id}?year=${year}&category=${category}&subcategory=${subcategory}`,
    {
      method: 'POST',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}

export const getSectionsByDistrict = async (params) => {
  const { id, year, category, subcategory } = params
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/observatorio-legislativo/demographic-results/sections-by-localdistrict/${id}?year=${year}&category=${category}&subcategory=${subcategory}`,
    {
      method: 'POST',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}

