export const politicianParties = [
  {
    name: "Partido del Trabajo",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-pt.png?alt=media&token=16dbdf93-bf10-4202-a2f4-a1bba9b172b5",
    color: "#DFC417",
    identifier: "PT"
  },
  {
    name: "Partido Encuentro Solidario",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-pes.png?alt=media&token=54cd429f-869f-42b5-ac1b-3b4eed45d122",
    color: "#4C1D91",
    identifier: "PES"
  },
  {
    name: "Nueva Alianza",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-nva.png?alt=media&token=14e8a52b-6f47-483e-9770-f37748e16dc2",
    color: "#1F8FA5",
    identifier: "NA"
  },
  {
    name: "Nueva Alianza",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-nva.png?alt=media&token=14e8a52b-6f47-483e-9770-f37748e16dc2",
    color: "#1F8FA5",
    identifier: "NAEM"
  },
  {
    name: "Partido Verde Ecologista de México",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-pv.png?alt=media&token=25619305-a24b-47ba-80cc-f080a6511c8a",
    color: "#0FAC0C",
    identifier: "PVEM"
  },
  {
    name: "Fuerza Mexicana",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-fmx.png?alt=media&token=9d8703af-fecb-44f7-87dc-ef7ccf960ef9",
    color: "#EA13C3",
    identifier: "FXM"
  },
  {
    name: "Movimiento Regeneración Nacional",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-morena.png?alt=media&token=30c121b6-c53b-457e-af1c-7869f63e1b1c",
    color: "#C34813",
    identifier: "MORENA"
  },
  {
    name: "Partido Revolucionario Institucional",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-pri.png?alt=media&token=62645473-af47-4418-84e1-56973e355145",
    color: "#E40A11",
    identifier: "PRI"
  },
  {
    name: "Partido Acción Nacional",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-pan.png?alt=media&token=45f28314-f98b-4d92-aa2a-27a58a94aa75",
    color: "#175081",
    identifier: "PAN"
  },
  {
    name: "Movimiento Ciudadano",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-mvc.png?alt=media&token=2acce434-63aa-4fe8-9d4e-8f1ce93ff7fd",
    color: "#F2B424",
    identifier: "MC"
  },
  {
    name: "Partido de la Revolución Democrática",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-prd.png?alt=media&token=d7315ff6-4b84-497f-854b-daabfea8c7cc",
    color: "#F5E307",
    identifier: "PRD"
  },
  {
    name: "Redes Sociales Progresistas",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-rsp.png?alt=media&token=7742ea9d-dfd5-40ec-8e4b-685476a83dfd",
    color: "#7E817E",
    identifier: "RSP"
  },
  {
    name: "Candidatura Independiente",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-rsp.png?alt=media&token=7742ea9d-dfd5-40ec-8e4b-685476a83dfd",
    color: "#7E817E",
    identifier: "Cand_Ind"
  },
  {
    name: "Partido Humanista",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-rsp.png?alt=media&token=7742ea9d-dfd5-40ec-8e4b-685476a83dfd",
    color: "#7E817E",
    identifier: "PH"
  },
  {
    name: "Vía Radical",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-rsp.png?alt=media&token=7742ea9d-dfd5-40ec-8e4b-685476a83dfd",
    color: "#7E817E",
    identifier: "VR"
  },
  {
    name: "Partido Futuro Democrático",
    logo: "https://firebasestorage.googleapis.com/v0/b/geo-data-votos.appspot.com/o/logo-rsp.png?alt=media&token=7742ea9d-dfd5-40ec-8e4b-685476a83dfd",
    color: "#7E817E",
    identifier: "PFD"
  }
]