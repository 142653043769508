import { InputSwitchBuilder, InputTextBuilder } from "components/InputBuilder";

export default function SharedForm(
  { dataInstance, handleOnChangeData, handleChangeBoolean, register, errors }
) {
  return (<>
    <InputTextBuilder
      label="Nombre" id="name" name="name" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputSwitchBuilder
      label="Legislatura activa" id="active" name="active" required={true}
      instance={dataInstance} handleOnChangeData={handleChangeBoolean}
      register={register} errors={errors} />
  </>)
}