import { CloudUpload as CloudUploadIcon } from "@mui/icons-material"

export const DragAndDropZone = ({ getRootProps, getInputProps }) => {
  return (
    <div>
      <div {...getRootProps({ className: 'dropzone' })} style={{ border: "1px dashed #ababab", textAlign: "center", padding: 15, backgroundColor: "#ffffff" }}>
        <input {...getInputProps()} />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CloudUploadIcon />
          Arrastra los archivos aquí o da clic para seleccionarlos
        </div>
      </div>
      <h4>Archivos seleccionados</h4>
    </div>
  )
}