import * as Yup from "yup"

export const SchemaValidationAdd = (t) => Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  logo: Yup.mixed()
    .test('required', "Campo requerido", (value) => {
      return value && value.length
    })
    .test("type", "Solo imagenes", function (value) {
      return value && value[0] && value[0].type.includes("image");
    }),
  abbreviation: Yup.string().required("Campo requerido"),
  foundation_year: Yup.string().required("Campo requerido"),
  ideology: Yup.string().required("Campo requerido"),
  facebook_account: Yup.string(),
  twitter_account: Yup.string(),
  instagram_account: Yup.string(),
  website: Yup.string(),
  active: Yup.boolean().required("Campo requerido"),
})

export const SchemaValidationEdit = (t) => Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  logo: Yup.mixed().required("Campo requerido"),
  logo_file: Yup.mixed().nullable()
    /* .test('required', "Campo requerido", (value) => {
      console.log(value)
      return value && value.length
    }) */
    .test("type", "Solo imagenes", function (value) {
      console.log("logo_file", value)
      if(value && !value.length) {
        return true
      }

      return value && value[0] && value[0].type.includes("image");
    }),
  abbreviation: Yup.string().required("Campo requerido"),
  foundation_year: Yup.string().required("Campo requerido"),
  ideology: Yup.string().required("Campo requerido"),
  facebook_account: Yup.string(),
  twitter_account: Yup.string(),
  instagram_account: Yup.string(),
  website: Yup.string(),
  active: Yup.boolean().required("Campo requerido"),
})