import { FormControlLabel, IconButton } from "@mui/material"
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material"
import LoaderSwitch from "components/Loaders/LoaderSwitch"
import { Android12Switch } from "components/Styled"

export const handleSetInstance = (id, data, setData) => {
  const instance = data.data.filter(item => (item._id === id))
  setData(instance[0])
}

export const getActive = (id, data) => {
  const instance = data.data.filter(item => (item._id === id))
  if (instance.length === 0)
    return false
  return instance[0].active
}

export const MUIDatatableCustomBodyRenderActions = (
  open,
  clickMenuActions,
  loadingUpdateActive,
  itemId,
  getActive,
  data,
  handleUpdateActive
) =>
  (value, tableMeta, updateValue) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton id="demo-customized-button" aria-haspopup="true" variant="contained"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => clickMenuActions(event, value)}
        sx={{ marginRight: 1 }}>
        <MoreHorizIcon />
      </IconButton>
      {(loadingUpdateActive && itemId === value) ? <LoaderSwitch on={getActive(value, data)} /> :
        <FormControlLabel style={{ marginRight: 4 }}
          control={
            <Android12Switch checked={getActive(value, data)}
              disabled={(loadingUpdateActive && itemId === value)}
              onClick={() => handleUpdateActive(value)} />
          } />
      }
    </div>
  )

export const MUIDatatableOptions = (
  t,
  customBodyRenderActions,
  open,
  handleClickMenuActions,
  loadingUpdateActive,
  itemId,
  getActive,
  data,
  handleUpdateActive
) =>
  [
    {
      name: "logo_show", label: t('politicalparties_table_logo_column'),
      options: {
        filter: true, sort: true,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>,
        customBodyRender: (value, tableMeta, updateValue) => <img src={value} alt={value} style={{ width: 50, height: 50, borderRadius: "10%" }} />
      }
    },
    {
      name: "name", label: t('politicalparties_table_name_column'),
      options: {
        filter: true, sort: true,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>
      }
    },
    {
      name: "abbreviation", label: t('politicalparties_table_abbreviation_column'),
      options: {
        filter: true, sort: true,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>
      }
    },
    {
      name: "_id", label: t('politicalparties_table_actions_column'),
      options: {
        filter: false, sort: false, download: false,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>,
        customBodyRender: customBodyRenderActions(open, handleClickMenuActions, loadingUpdateActive, itemId, getActive, data, handleUpdateActive)
      }
    },
  ];