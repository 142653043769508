import { MapContainer, TileLayer } from "react-leaflet"
import 'leaflet/dist/leaflet.css'
import SetBoundsRectanglesMap9 from "./SetBoundsRectanglesMap9"
import { useEffect, useState } from "react"
import { GetMap9 } from "../services/api"
import { useQuery } from "@tanstack/react-query"
import { InfoWindow } from "../components/InfoWindow"
import { Loader } from "../components/Loader"

export const Map9 = ({ localDistrictId, politicianParty }) => {
  const [coordinates, setCoordinates] = useState([])
  const [year] = useState("2021")
  const [infoStickyBottomRightWindow, setInfoStickyBottomRightWindow] = useState(null)

  const { isFetching: iFgetMap9, data: getMap9 } = useQuery(
    ["GET_MAP9", localDistrictId, year, politicianParty],
    () => GetMap9({ id: localDistrictId, year: year, politician_party: politicianParty }),
    { refetchOnWindowFocus: false, enabled: localDistrictId !== undefined && localDistrictId !== null }
  )

  useEffect(() => {
    if (getMap9 !== undefined && getMap9 !== null) {
      const sections = getMap9.sections.map((section) => {
        return {
          coordinates: section.coordinates,
          electoralresults: [{ color: section.extraData.color }],
          name: section.identifier,
          extraData: section.extraData,
          tags: []
        }
      })
      setCoordinates(sections)
    }
  }, [getMap9])

  const handleBuildInfosWindow = ({ name, electoralresults, extraData }) => {
    if (extraData) {
      let info = `<div><b>¿Tu partido ganó esta sección?: </b> ${extraData.isWinnerByPoliticianParty ? "Sí" : "No"}</div>`
      info += `<div>`
      info += `<b>Número de mujeres: </b> ${extraData.nm}`
      info += `</div>`
      info += `<div>`
      info += `<b>Porcentaje de mujeres: </b> ${extraData.nmp}%`
      info += `</div>`
      setInfoStickyBottomRightWindow(info)
    } else {
      setInfoStickyBottomRightWindow("Proximamente")
    }
  }

  return (<>
    {coordinates.length > 0 ? <>
      <div style={{ position: "relative" }}>
        <MapContainer
          center={[19.3264047, -99.6049788]}
          zoom={15}
          scrollWheelZoom={true}
          style={{ height: "500px", width: "100%", borderRadius: "14px", boxShadow: "0 3px 4px 0px rgba(0,0,0,0.2)" }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" />
          <SetBoundsRectanglesMap9 coordinates={coordinates} infoSticky={handleBuildInfosWindow} extraData={() => console.log("data")} />
          <Loader show={iFgetMap9} />
        </MapContainer>
        <InfoWindow
          show={infoStickyBottomRightWindow !== null}
          close={() => setInfoStickyBottomRightWindow(null)}
          title="Resultados"
          placeholder="Da click sobre una localidad"
          position="bottom-right"
          info={infoStickyBottomRightWindow} />
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: extraData }} /> */}
    </> : null}
  </>)
}