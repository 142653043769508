import { useState } from "react";
import { Box, Tab, Tabs, Tooltip, useMediaQuery } from "@mui/material";
import { Map as MapIcon, ShowChart as ShowChartIcon } from '@mui/icons-material';
import Map from "components/Map";
import DataMex from "components/DataMex";

export default function Politician() {
  const userLS = JSON.parse(localStorage.getItem('LOAu'))
  const [value, setValue] = useState('electoraldemografico');
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const handleChange = (event, newValue) => setValue(newValue)

  return (<>
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="secondary tabs example">
        <Tab value="electoraldemografico"
          label={
            matchesMD ? "Electorales / Demográficos" :
              <Tooltip title="Electorales / Demográficos"><MapIcon /></Tooltip>
          }
        />
        <Tab value="otherdata"
          label={
            matchesMD ? "Otras estadísticas" :
              <Tooltip title="Otras estadísticas"><ShowChartIcon /></Tooltip>
          }
        />
      </Tabs>
    </Box>
    <div style={{ marginTop: 20 }} role="tabpanel" hidden={value !== "electoraldemografico"} id={`simple-tabpanel-electoraldemografico`} aria-labelledby={`simple-tab-electoraldemografico`}>
      <Map localDistrictId={userLS.district} />
    </div>
    <div style={{ marginTop: 20 }} role="tabpanel" hidden={value !== "otherdata"} id={`simple-tabpanel-otherdata`} aria-labelledby={`simple-tab-otherdata`}>
      <DataMex municipalities={userLS.municipalities} />
    </div>
  </>)
}