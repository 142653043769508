import { Box, Tab, Tabs } from "@mui/material";
import { DemographicResultsMap } from "./DemographicResultsMap";
import { ElectoralResultsMap } from "./ElectoralResultsMap";
import { useState } from "react";

export default function Map({ localDistrictId }) {

  const [value, setValue] = useState('electoral');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (<>
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="secondary tabs example"
      >
        <Tab value="electoral" label="Resultados electorales" />
        <Tab value="demographic" label="Resultados demográficos" />
      </Tabs>
    </Box>
    <div style={{ marginTop: 20 }} role="tabpanel" hidden={value !== "electoral"} id={`simple-tabpanel-electoral`} aria-labelledby={`simple-tab-electoral`}>
      <ElectoralResultsMap localDistrictId={localDistrictId} />
    </div>
    <div style={{ marginTop: 20 }} role="tabpanel" hidden={value !== "demographic"} id={`simple-tabpanel-demographic`} aria-labelledby={`simple-tab-demographic`}>
      <DemographicResultsMap localDistrictId={localDistrictId} />
    </div>
  </>)
}