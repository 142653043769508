import { useContext, useEffect, useState } from "react";
import { Button, TextField, Box, Container, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, CircularProgress, Paper } from '@mui/material'
import { Visibility, VisibilityOff, Email as EmailIcon, Lock as LockIcon } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReCAPTCHA from 'react-google-recaptcha'
import { AuthContext } from 'context/AuthContext'
import ValidationSchema from "./services/SchemaValidation"
import { SnackbarGlobal } from 'components/Snackbar'
import { useMutation } from "@tanstack/react-query";
import { login } from "./services/api/Auth";
import { useNavigate } from 'react-router-dom'
import { FMPageOpacityTransition } from "components/FMTransitions/PageTransitions";

export default function Login() {
  const { activateAuth, loadUser, loadToken, isAuth } = useContext(AuthContext)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  let { handleSubmit, register, formState: { errors } } = useForm({ resolver: yupResolver(ValidationSchema) })

  useEffect(() => {
    if (isAuth) { navigate("/admin/dashboard") }
  })

  const mutation = useMutation(login, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        setMessageSnackbar('Correo y/o contraseña incorrectos');
        setAuthError(true);
      } else {
        loadUser(JSON.stringify(data.user))
        loadToken(data.token)
        activateAuth()
        if (data.user.company) {
          navigate("/admin/dashboard")
        } else {
          navigate("/admin/dashboard")
        }
      }
    }
  })

  const onSubmit = (params) => { //console.log("Params:", JSON.stringify(params))
    if (recaptcha == null) {
      setMessageSnackbar('Es necesario marcar el captcha');
      setAuthError(true); return;
    }
    setIsLoading(true)
    mutation.mutate(params)
  }

  const handleClickShowPassword = () => { setShowPassword(!showPassword) }
  const handleMouseDownPassword = (event) => { event.preventDefault() }
  function onChangeRecaptcha(value) { setRecaptcha(value) }

  return (<FMPageOpacityTransition>
    <Container sx={{ padding: 0, maxMidth: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Paper sx={{ padding: 2, backgroundColor: "#f7f7f7", borderRadius: 4 }}>
        <div style={{ margin: "0 auto" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img src="/images/logo_observatorio.png" alt="logo" style={{ maxWidth: 350, width: "90%", marginRight: 10 }} />
          </div>
          <Box sx={{ mt: 2 }}>
            <InputLabel htmlFor="email" required style={{ fontSize: 16 }}>Email</InputLabel>
            <TextField required fullWidth autoFocus margin="normal" size="small" name="email"
              {...register("email")}
              error={errors.email ? true : false}
              style={{ marginTop: 8 }}
              InputProps={{
                startAdornment: <InputAdornment position="start"><EmailIcon fontSize="small" /></InputAdornment>,
              }}
            />
            <InputLabel htmlFor="password" required style={{ fontSize: 16, marginTop: 14 }}>Contraseña</InputLabel>
            <FormControl variant="outlined" fullWidth margin="normal" required style={{ marginTop: 8, marginBottom: 8 }}>
              <OutlinedInput required fullWidth size="small"
                type={showPassword ? 'text' : 'password'}
                name="password"
                {...register("password")}
                error={errors.password ? true : false}
                startAdornment={<InputAdornment position="start"><LockIcon fontSize="small" /></InputAdornment>}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" edge="end"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    > {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />} </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <ReCAPTCHA 
              style={{ display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 10 }}
              id="recaptcha" 
              sitekey="6LemSy8bAAAAABqOAmOu9I6-L2ME8FPAm0ogNcA2" 
              onChange={onChangeRecaptcha} hl="en" />
            {(recaptcha !== null) && <>
              {!isLoading ?
                <Button onClick={handleSubmit(onSubmit)} fullWidth variant="contained" color="primary"> Acceder </Button>
                : <div style={{ display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 10 }}> <CircularProgress /> </div>}
            </>}
          </Box>
        </div>
      </Paper>
    </Container>
    <SnackbarGlobal vertical='bottom' horizontal='center' open={authError} close={() => setAuthError(false)} message={messageSnackbar} />
  </FMPageOpacityTransition>);
}