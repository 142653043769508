import { IMaskInput } from "react-imask";
const { forwardRef } = require("react");

export const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, mask, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={mask}
      inputRef={ref}
      onAccept={(value) => {
        onChange({ target: { name: props.name, value } })
      }}
      overwrite
    />
  );
});