import { useEffect, useState } from "react"
import { FormControl, Grid, InputLabel, MenuItem, Select, Button, CircularProgress } from "@mui/material"
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material"
import { useDropzone } from "react-dropzone";
import papaparse from "papaparse";
import { MUITable } from "components/Table/MUITable";
import { useMutation } from "@tanstack/react-query";
import { load, downloadTemplate } from "../services/api";
import { SaveUpdateInstructions } from "./SaveUpdateInstructions";

export const SaveUpdate = ({ data, reportAction }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selects, setSelects] = useState({ legislative_period: "", info_type: "", })
  const [tableData, setTableData] = useState({ columns: [], rows: [] })
  const [parseCSV, setParseCSV] = useState(null)
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: { 'text/csv': ['.csv'] },
    maxFiles: 1
  })

  const mutation = useMutation(load, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error') }
      else { reportAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleLoad = () => {
    const formData = new FormData()
    formData.append('file', acceptedFiles[0])
    formData.append('period', selects.legislative_period)
    formData.append('info_type', selects.info_type)
    setIsLoading(true)
    mutation.mutate(formData)
  }

  const mutationDownloadTemplate = useMutation(downloadTemplate, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al obtener datos para la pantilla', 'error')
      } else {
        const parseCSV = papaparse.unparse(data.data, { delimiter: ",", header: true })

        const element = document.createElement("a");
        const file = new Blob([parseCSV], { type: 'text/csv;charset=utf-8' });
        element.href = URL.createObjectURL(file);
        element.download = "cajon_de_asistencias_diputados_plantilla.csv";
        document.body.appendChild(element);
        element.click();

        reportAction(true, 'Acción realizada correctamente', 'success')
      }
    }
  })

  const handleDownloadTemplate = () => mutationDownloadTemplate.mutate()

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      papaparse.parse(acceptedFiles[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) { setParseCSV(results.data) }
      })
    }
  }, [acceptedFiles])

  useEffect(() => {
    if (parseCSV !== null) {
      if ('id' in parseCSV[0] && 'diputado' in parseCSV[0]) {
        let rows = []
        parseCSV.forEach((item, index) => {
          let temp = {}
          for (const [key, value] of Object.entries(item)) { temp[key] = value }
          rows.push(temp)
        })

        let parseCSVTemp = parseCSV[0]
        delete parseCSVTemp.id
        delete parseCSVTemp.diputado

        const ordered = Object.keys(parseCSVTemp).sort().reduce((obj, key) => {
          obj[key] = parseCSVTemp[key];
          return obj;
        }, {});

        let columns = [
          { id: 'id', label: 'ID', minWidth: 170 },
          { id: "diputado", label: "Diputado", minWidth: 170 }
        ]
        for (const [key, value] of Object.entries(ordered)) {
          columns.push({ id: key, label: key, minWidth: 115, align: 'right' })
        }

        setTableData({ ...tableData, rows: rows, columns: columns })
      }
    }
  }, [parseCSV])

  const handleChangeSelects = (event) => setSelects({ ...selects, [event.target.name]: event.target.value })

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const cellHeaderRender = (column) => {
    const splitColumn = column.split("_")
    let columnRender = ""
    splitColumn.forEach((item, index) => {
      if (index === 0) { columnRender += item.charAt(0).toUpperCase() + item.slice(1) + "<br/>" }
      else { columnRender += " " + item.charAt(0).toUpperCase() + item.slice(1) }
    })
    return <div dangerouslySetInnerHTML={{ __html: columnRender }} />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <div style={{ marginBottom: 24 }}>
          <Button fullWidth variant="contained" color="primary" onClick={() => handleDownloadTemplate()}>Descargar plantilla</Button>
        </div>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="demo-simple-select-label">Periodo legislativo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="legislative_period"
            value={selects.legislative_period}
            label="Periodo legislativo"
            onChange={handleChangeSelects} >
            <MenuItem value="">Selecciona un periodo</MenuItem>
            {data.map((item, index) => <MenuItem key={index} value={item.identifier}>{item.name}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="demo-simple-select-label">Tipo de información</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            name="info_type"
            id="demo-simple-select"
            value={selects.info_type}
            label="Tipo de información"
            onChange={handleChangeSelects} >
            <MenuItem value="">Selecciona un tipo de información</MenuItem>
            <MenuItem value="full">Completo</MenuItem>
            <MenuItem value="partial">Partial</MenuItem>
          </Select>
        </FormControl>
        <div {...getRootProps({ className: 'dropzone' })} style={{ border: "1px dashed #ababab", textAlign: "center", padding: 15, backgroundColor: "#ffffff", marginBottom: 16 }}>
          <input {...getInputProps()} />
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CloudUploadIcon /> Arrastra el archivo csv aquí, o haz click para seleccionar el archivo
          </div>
        </div>
        {selects.legislative_period !== "" && selects.info_type !== "" ?
          <div>
            {!isLoading ? <>
              <Button fullWidth variant="contained" color="primary" disabled={tableData.rows.length === 0} onClick={() => handleLoad()} sx={{ mb: 2 }}>Guardar</Button>
              <Button fullWidth variant="contained" color="secondary" disabled={tableData.rows.length === 0} onClick={() => handleLoad()}>Limpiar datos</Button>
            </> : <CircularProgress />}
          </div> : null}
      </Grid>
      <Grid item xs={12} md={8}>
        {tableData.rows.length > 0 && tableData.columns.length === Object.keys(tableData.rows[0]).length ?
          <MUITable
            columns={tableData.columns}
            rows={tableData.rows}
            cellHeaderLabelRender={cellHeaderRender}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage} />
          : <SaveUpdateInstructions />}
      </Grid>
    </Grid>
  )
}