import * as Yup from "yup"

export const SchemaValidation = (t) => Yup.object().shape({
  name: Yup.string().required(t('required')),
  email: Yup.string().required(t('required')),
  password: Yup.string().required(t('required')),
  role: Yup.string().required(t('required')),
  active: Yup.boolean().required(t('required'))
})

export const SchemaValidationUpdate = (t) => Yup.object().shape({
  name: Yup.string().required(t('required')),
  email: Yup.string().required(t('required')),
  password: Yup.string().nullable(),
  role: Yup.string().required(t('required')),
  active: Yup.boolean().required(t('required'))
})