
export const InfoWindow = ({
  show = false,
  close,
  title,
  placeholder = "Hover over a state",
  position = "top-right",
  info = null,
}) => {
  const positions = position.split("-")
  return (<>
    {show ?
      <div style={{
        margin: 10,
        backgroundColor: "rgba(255,255,255,0.8)",
        zIndex: 1000,
        position: "absolute",
        top: positions[0] === "top" ? 20 : null,
        right: positions[1] === "right" ? 0 : null,
        bottom: positions[0] === "bottom" ? 0 : null,
        left: positions[1] === "left" ? 0 : null,
        padding: "6px 8px",
        borderRadius: 5,
        boxShadow: "0 0 15px rgba(0,0,0,0.2)",
        fontSize: 14,
        maxWidth: 300,
        width: 200,
      }}>
        <div onClick={close}
        style={{
          width: 25, height: 25, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "red", color: "#ffffff", fontSize: 12, borderRadius: 20, position: "absolute", top: "-5px", right: "-5px", cursor: "pointer"
        }}>
          X
        </div>
        <div><h4 style={{ margin: 0 }}>{title}</h4></div>
        {info === null ?
          <div>{placeholder}</div> :
          <div dangerouslySetInnerHTML={{ __html: info }} style={{ overflowX: "hidden" }} />
        }
      </div>
      : null}
  </>)
}