import React from "react";
import { Box, SwipeableDrawer, useMediaQuery, useTheme } from "@mui/material";
import Menu from "./Menu";

const Drawer = ({ open, actionOpen }) => {
  const theme = useTheme();
  const matchesDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const usr = JSON.parse(localStorage.getItem('LOAu'))

  const drawerWidth = 251;

  return (<>
    {usr !== null ?
      <Box component="nav" sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      })}>
        {matchesDownMD ?
          <SwipeableDrawer
            variant="temporary"
            open={open}
            onClose={actionOpen}
            onOpen={actionOpen}
            sx={{
              display: { xs: 'block', sm: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#212145', color: '#fff' },
            }}
          >
            <Menu action={actionOpen} />
          </SwipeableDrawer>
          :
          <SwipeableDrawer
            variant="permanent"
            open
            onClose={() => console.log("close")}
            onOpen={() => console.log("open")}
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderRight: 0, backgroundColor: '#212145', color: '#fff' },
            }}
          >
            <Menu action={actionOpen} />
          </SwipeableDrawer>
        }
      </Box>
      : null}
  </>);
};

export default Drawer;
