import { MapContainer, TileLayer } from "react-leaflet"
import 'leaflet/dist/leaflet.css'
import SetBoundsRectanglesMap6 from "./SetBoundsRectanglesMap6"
import { useEffect, useState } from "react"
import { GetMap6 } from "../services/api"
import { useQuery } from "@tanstack/react-query"
import { InfoWindow } from "../components/InfoWindow"
import { Loader } from "../components/Loader"
import { politicianParties } from "../services/politicianParties"

export const Map6 = ({ localDistrictId, politicianParty }) => {
  const [coordinates, setCoordinates] = useState([])
  const [year] = useState("2021")
  const [infoStickyBottomRightWindow, setInfoStickyBottomRightWindow] = useState(null)

  const { isFetching: iFgetMap6, data: getMap6 } = useQuery(
    ["GET_MAP9", localDistrictId, year, politicianParty],
    () => GetMap6({ id: localDistrictId, year: year, politician_party: politicianParty }),
    { refetchOnWindowFocus: false, enabled: localDistrictId !== undefined && localDistrictId !== null }
  )

  const buildelectoralResults = (abbreviation) => {
    const find = politicianParties.find(party => party.identifier === abbreviation)

    return {
      color: find !== undefined ? find.color : "#000000",
    }
  }

  useEffect(() => {
    if (getMap6 !== undefined && getMap6 !== null) {
      const sections = getMap6.sections.map((section) => {
        return {
          coordinates: section.coordinates,
          electoralresults: [buildelectoralResults(section.extraData.pg1f)],
          name: section.identifier,
          extraData: section.extraData,
          tags: []
        }
      })
      setCoordinates(sections)
    }
  }, [getMap6])

  const buildPoliticianPartyCard = (abbreviation) => {
    const find = politicianParties.find(party => party.identifier === abbreviation)

    return {
      image: find.logo,
      abbreviation: abbreviation,
    }
  }

  const handleBuildInfosWindow = ({ name, electoralresults, extraData }) => {
    if (extraData) {
      const f1 = buildPoliticianPartyCard(extraData.pg1f)
      const f2 = buildPoliticianPartyCard(extraData.pg2f)
      let info = `<div><b>Tipo de sección: </b> ${extraData.section_type}</div>`
      //electoralresults.forEach(element => {
        info += `<div><b>Primera fuerza</b></div>`
        info += `<div style="width: 100%%; display: flex; align-items: center; padding: 5px 0px; border-bottom: 1px solid #cecece;">`
        info += `<div>`
        info += `<img src="${f1.image}" style="height: 40px;" />`
        info += `</div>`
        info += `<div style="padding: 0px 5px">`
        info += `<span style="text-transform: uppercase; font-weight: bold">${f1.abbreviation}</span> <br/>`
        info += `<div><span>Votos: ${extraData.v1f}<span/></div>`
        info += `</div>`
        info += `</div>`
        info += `<div><b>Segunda fuerza</b></div>`
        info += `<div style="width: 100%%; display: flex; align-items: center; padding: 5px 0px; border-bottom: 1px solid #cecece;">`
        info += `<div>`
        info += `<img src="${f2.image}" style="height: 40px;" />`
        info += `</div>`
        info += `<div style="padding: 0px 5px">`
        info += `<span style="text-transform: uppercase; font-weight: bold">${f2.abbreviation}</span> <br/>`
        info += `<div><span>Votos: ${extraData.v2f}<span/></div>`
        info += `</div>`
        info += `</div>`
        info += `<div><b>Margen de victoria nominal: </b> ${extraData.mvn < 0 ? (-1 * extraData.mvn) : extraData.mvn}</div>`
        info += `<div><b>Margen de victoria nominal (%): </b> ${extraData.mvp < 0 ? (-1 * extraData.mvp) : extraData.mvp}%</div>`
      //});
      setInfoStickyBottomRightWindow(info)
    } else {
      setInfoStickyBottomRightWindow("Proximamente")
    }
  }

  return (<>
    {coordinates.length > 0 ? <>
      <div style={{ position: "relative" }}>
        <MapContainer
          center={[19.3264047, -99.6049788]}
          zoom={15}
          scrollWheelZoom={true}
          style={{ height: "500px", width: "100%", borderRadius: "14px", boxShadow: "0 3px 4px 0px rgba(0,0,0,0.2)" }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" />
          <SetBoundsRectanglesMap6 coordinates={coordinates} infoSticky={handleBuildInfosWindow} extraData={() => console.log("data")} />
          <Loader show={iFgetMap6} />
        </MapContainer>
        <InfoWindow
          show={infoStickyBottomRightWindow !== null}
          close={() => setInfoStickyBottomRightWindow(null)}
          title="Resultados"
          placeholder="Da click sobre una localidad"
          position="bottom-right"
          info={infoStickyBottomRightWindow} />
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: extraData }} /> */}
    </> : null}
  </>)
}