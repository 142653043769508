export const esmx = {
  translation: {
    // Login
    "login_page_title": "InteliQR Menú",
    "login_page_subtitle": "Inicie sesión para acceder a su panel",
    "login_form_signin_google_button": "Sign In",
    "login_form_divider_options": "Ó",
    "login_form_email": "Correo electrónico",
    "login_form_password": "Contraseña",
    "login_form_button": "Iniciar Sesión",

    //commissions
    "commissions_page_title": "Etiquetas de producto",
    "commissions_page_add_button_text": "Agregar",
    "commissions_page_edit_button_text": "Editar",
    "commissions_page_delete_button_text": "Eliminar",
    "commissions_search_placeholder_text": "Buscar",
    "commissions_table_commission_id_column": "ID",
    "commissions_table_name_column": "Nombre",
    "commissions_table_sesions_number_column": "No. de sesiones",
    "commissions_table_description_column": "Descripción",
    "commissions_table_actions_column": "Opciones",
    "commissions_dialog_title_add": "Agregar",
    "commissions_dialog_title_edit": "Editar",
    "commissions_dialog_title_delete": "Eliminar",
    "commissions_dialog_confirm_delete_text": "¿Estas seguro de querer eliminar la comisión {{name}}?",
    "commissions_form_name": "Nombre",
    "commissions_form_textcolor": "Color del texto",
    "commissions_form_backgroundcolor": "Color del fondo",
    "commissions_form_active": "Activar",

    //political parties
    "politicalparties_page_title": "Etiquetas de producto",
    "politicalparties_page_add_button_text": "Agregar",
    "politicalparties_page_edit_button_text": "Editar",
    "politicalparties_page_delete_button_text": "Eliminar",
    "politicalparties_table_logo_column": "Logo",
    "politicalparties_table_name_column": "Nombre",
    "politicalparties_table_abbreviation_column": "Abreviación",
    "politicalparties_table_actions_column": "Opciones",
    "politicalparties_dialog_title_add": "Agregar",
    "politicalparties_dialog_title_edit": "Editar",
    "politicalparties_dialog_title_delete": "Eliminar",
    "politicalparties_dialog_confirm_delete_text": "¿Estas seguro de querer eliminar {{name}}?",
    "politicalparties_form_name": "Nombre",
    "politicalparties_form_textcolor": "Color del texto",
    "politicalparties_form_backgroundcolor": "Color del fondo",
    "politicalparties_form_active": "Activar",

    //districts
    "districts_page_title": "Distritos",
    "districts_page_add_button_text": "Agregar",
    "districts_page_edit_button_text": "Editar",
    "districts_page_delete_button_text": "Eliminar",
    "districts_table_commission_id_column": "ID",
    "districts_table_name_column": "Nombre",
    "districts_table_roman_number_column": "No. romano",
    "districts_table_sections_column": "Secciones",
    "districts_table_actions_column": "Opciones",
    "districts_dialog_title_add": "Agregar",
    "districts_dialog_title_edit": "Editar",
    "districts_dialog_title_delete": "Eliminar",
    "districts_dialog_confirm_delete_text": "¿Estas seguro de querer eliminar el distrito {{name}}?",
    "districts_form_name": "Nombre",
    "districts_form_textcolor": "Color del texto",
    "districts_form_backgroundcolor": "Color del fondo",
    "districts_form_active": "Activar",

    //legislatures
    "legislatures_page_title": "Legislaturas",
    "legislatures_page_add_button_text": "Agregar",
    "legislatures_page_edit_button_text": "Editar",
    "legislatures_page_delete_button_text": "Eliminar",
    "legislatures_table_commission_id_column": "ID",
    "legislatures_table_name_column": "Nombre",
    "legislatures_table_actions_column": "Opciones",
    "legislatures_dialog_title_add": "Agregar",
    "legislatures_dialog_title_edit": "Editar",
    "legislatures_dialog_title_delete": "Eliminar",
    "legislatures_dialog_confirm_delete_text": "¿Estas seguro de querer eliminar la legislatura {{name}}?",
    "legislatures_form_name": "Nombre",
    "legislatures_form_textcolor": "Color del texto",
    "legislatures_form_backgroundcolor": "Color del fondo",
    "legislatures_form_active": "Activar",

    //legislature periods
    "legislatureperiods_page_title": "Periodos legislativos",
    "legislatureperiods_page_add_button_text": "Agregar",
    "legislatureperiods_page_edit_button_text": "Editar",
    "legislatureperiods_page_delete_button_text": "Eliminar",
    "legislatureperiods_table_name_column": "Nombre",
    "legislatureperiods_table_identifier_column": "Identificador",
    "legislatureperiods_table_actions_column": "Opciones",
    "legislatureperiods_dialog_title_add": "Agregar",
    "legislatureperiods_dialog_title_edit": "Editar",
    "legislatureperiods_dialog_title_delete": "Eliminar",
    "legislatureperiods_dialog_confirm_delete_text": "¿Estas seguro de querer eliminar el periodo legislativo {{name}}?",
    "legislatureperiods_form_name": "Nombre",

    //politicians
    "politicians_page_title": "Diputados",
    "politicians_page_add_button_text": "Agregar",
    "politicians_page_edit_button_text": "Editar",
    "politicians_page_delete_button_text": "Eliminar",

    "politicians_table_photo_column": "Imagen",
    "politicians_table_name_column": "Nombre",
    "politicians_table_political_party_column": "Partido político",
    "politicians_table_chosen_by_column": "Elegido por",
    "politicians_table_district_column": "Distrito",
    "politicians_table_actions_column": "Opciones",

    "politicians_dialog_title_add": "Agregar",
    "politicians_dialog_title_edit": "Editar",
    "politicians_dialog_title_delete": "Eliminar",
    "politicians_dialog_confirm_delete_text": "¿Estas seguro de querer eliminar al diputado {{name}}?",
    "politicians_form_name": "Nombre",
    "politicians_form_textcolor": "Color del texto",
    "politicians_form_backgroundcolor": "Color del fondo",
    "politicians_form_active": "Activar",

    //users
    "users_page_title": "usuarios",
    "users_page_add_button_text": "Agregar",
    "users_page_edit_button_text": "Editar",
    "users_page_delete_button_text": "Eliminar",
    "users_table_name_column": "Nombre",
    "users_table_email_column": "Correo electrónico",
    "users_table_role_column": "Rol",
    "users_table_actions_column": "Opciones",
    "users_dialog_title_add": "Agregar",
    "users_dialog_title_edit": "Editar",
    "users_dialog_title_delete": "Eliminar",
    "users_dialog_confirm_delete_text": "¿Estas seguro de querer eliminar el usuario {{name}}?",

    //municipalities
    "municipalities_page_title": "municipios",
    "municipalities_page_add_button_text": "Agregar",
    "municipalities_page_edit_button_text": "Editar",
    "municipalities_page_delete_button_text": "Eliminar",
    "municipalities_table_municipality_id_column": "Municipio ID",
    "municipalities_table_name_column": "Nombre",
    "municipalities_table_districts_column": "Distritos asignados",
    "municipalities_table_actions_column": "Opciones",
    "municipalities_dialog_title_add": "Agregar",
    "municipalities_dialog_title_edit": "Editar",
    "municipalities_dialog_title_delete": "Eliminar",
    "municipalities_dialog_confirm_delete_text": "¿Estas seguro de querer eliminar el municipio {{name}}?",

    //Validaciones
    "required": "Requerido",

    //Buttons text
    "button_text_save": "Guardar",
    "button_text_add": "Agregar",
    "button_text_edit": "Editar",
    "button_text_delete": "Eliminar",
    "button_text_cancel": "Cancelar",
    "button_text_copy": "Copiar",
    "button_text_move": "Mover",



    //MUIDataTable
    "muidt_search_input_placeholder": "Buscar",
    "muidt_body_no_match": "No se encontraron registros",
    "muidt_body_tooltip_order": "Ordenar",
    "muidt_body_custom_tooltip_order": "Ordenar por",
    "muidt_pagination_next": "Siguiente",
    "muidt_pagination_previous": "Anterior",
    "muidt_pagination_rowsperpage": "Filas por página:",
    "muidt_pagination_displayrows": "de",
    "muidt_toolbar_search": "Buscar",
    "muidt_toolbar_downloadcsv": "Descargar CSV",
    "muidt_toolbar_print": "Imprimir",
    "muidt_toolbar_viewcolumns": "Ver columnas",
    "muidt_toolbar_filtertable": "Filtros de tabla",
    "muidt_filter_all": "Todos",
    "muidt_filter_title": "Filtros",
    "muidt_filter_reset": "Reiniciar",
    "muidt_viewcolumnas_title": "Mostrar columnas",
    "muidt_viewcolumnas_titlearia": "Mostrar/Ocultar columnas",
    "muidt_selectedrows_text": "fila(s) seleccionadas",
    "muidt_selectedrows_delete": "Eliminar",
    "muidt_selectedrows_deletearia": "Eliminar filas seleccionadas",
  }
}