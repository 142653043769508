import { useState } from "react"
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import Crimes from "./Crimes"

export default function DataMex({ municipalities }) {
  const [filters, setFilters] = useState({
    municipality: 0,
    year: 0,
    month: 0,
  })

  const handleChooseMunicipality = (event) => {
    setFilters({ ...filters, municipality: event.target.value })
  }

  const handleChooseYear = (event) => {
    setFilters({ ...filters, year: event.target.value })
  }

  const handleChooseMonth = (event) => {
    setFilters({ ...filters, month: event.target.value })
  }

  const RangeYearsFromStartToCurrent = (start) => {
    let getCurrentYear = new Date().getFullYear()
    return Array(getCurrentYear - start + 1).fill().map((_, idx) => start + idx)
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
        <Grid item xs={12} md={4}>
          <div style={{ position: "sticky", top: 100 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Municipio</InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filters.municipality}
                label="Municipio"
                onChange={handleChooseMunicipality}>
                <MenuItem value={0}>Selecciona un municipio</MenuItem>
                {municipalities.map((municipality) => (
                  <MenuItem value={municipality.municipality_id}>{municipality.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Año</InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filters.year}
                label="Año"
                onChange={handleChooseYear}>
                <MenuItem value={0}>Selecciona un año</MenuItem>
                {RangeYearsFromStartToCurrent(2015).map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Mes</InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filters.month}
                label="Año"
                onChange={handleChooseMonth}>
                <MenuItem value={0}>Selecciona un mes</MenuItem>
                <MenuItem value="01">Enero</MenuItem>
                <MenuItem value="02">Febrero</MenuItem>
                <MenuItem value="03">Marzo</MenuItem>
                <MenuItem value="04">Abril</MenuItem>
                <MenuItem value="05">Mayo</MenuItem>
                <MenuItem value="06">Junio</MenuItem>
                <MenuItem value="07">Julio</MenuItem>
                <MenuItem value="08">Agosto</MenuItem>
                <MenuItem value="09">Septiembre</MenuItem>
                <MenuItem value="10">Octubre</MenuItem>
                <MenuItem value="11">Noviembre</MenuItem>
                <MenuItem value="12">Diciembre</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          {filters.municipality !== 0 && filters.year !== 0 && filters.month !== 0 ?
            <Crimes municipality={filters.municipality} year={filters.year} month={filters.month} />
            : null}
        </Grid>
      </Grid>
    </div>
  )
}