import { createTheme, ThemeProvider } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      //light: '#616161',
      main: '#212145',
      //dark: '#212121',
      //contrastText: '#fff',
    },
    secondary: {
      //light: '#e0e0e0',
      main: '#F5F3DC',
      //dark: '#9e9e9e',
      //contrastText: '#000',
    },
    error: {
      main: '#D0021B',
      contrastText: '#fff'
    },
    warning: {
      main: '#ff9800',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      main: '#2196f3',
      contrastText: '#fff'
    },
    success: {
      main: '#4caf50',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    abbBar: {
      main: '#F5F3DC',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  components: {
    MUIDataTable: {
      styleOverrides: {
        root: {
          padding: "0px 10px 0px 10px",
          backgroundColor: "#efefef",
          borderRadius: 10,
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
          backgroundColor: "transparent !important",
        },
        data: {
          fontSize: 14,
        }
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          fontSize: 14
        }
      }
    }
  }
})

export default function ThemeMUIProvider({ children }) {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}