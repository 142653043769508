import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import Drawer from "./Drawer";
import { AuthContext } from "context/AuthContext";
import { CssBaseline, Toolbar } from "@mui/material";

const Layout = () => {
  const navigate = useNavigate()
  const { isAuth } = useContext(AuthContext)
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isAuth) { navigate("/login") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlerOpenMenu = () => {
    setOpen(!open);
  }

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      {isAuth && <>
        <Header actionMenu={handlerOpenMenu} />
        <Drawer open={open} actionOpen={handlerOpenMenu} />
      </>}
      <main style={{ width: "100%", flexGrow: 1 }}>
        <Toolbar />
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
