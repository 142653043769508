import { Grid, IconButton, Typography } from "@mui/material"
import { Delete as DeleteIcon } from "@mui/icons-material"

export const CardFileUpload = ({
  index, imgFile, altImageFile, pathFile, mbSizeFile, onClickAction, onDeleteAction
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <img src={imgFile} alt={altImageFile} style={{ width: "50px", maxWidth: "fit-content" }} />
      </Grid>
      <Grid item xs={8} sx={{ cursor: "pointer" }} onClick={() => onClickAction(index)}>
        <Typography variant="body2" component="div" noWrap>{pathFile}</Typography>
        <Typography variant="body2" component="div">{mbSizeFile}</Typography>
      </Grid>
      <Grid item xs={2}>
        <IconButton size="small" onClick={() => onDeleteAction(index)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}