import { useEffect, useState } from "react"
import { Autocomplete, TextField } from "@mui/material"

export const Categories = ({ folderSelected, categorySelected, onChangeCategorySelected }) => {
  const [categoriesList, setCategoriesList] = useState(null)

  useEffect(() => {
    if (folderSelected !== undefined && folderSelected !== null) {
      const categories = folderSelected.categories.map((category) => {
        return { value: category._id, label: category.name }
      })
      setCategoriesList(categories)
    }
  }, [folderSelected])

  return (<>
    {categoriesList !== null ?
      <Autocomplete
        disablePortal
        size="small"
        id="knowledgeCategoryFilter"
        options={categoriesList}
        getOptionLabel={(option) => option.label}
        sx={{ width: "100%" }}
        onChange={(event, newValue) => onChangeCategorySelected(newValue)}
        renderInput={(params) => <TextField {...params} label="Filtrar por categoría" />}
      />
      : null
    }
  </>)
}