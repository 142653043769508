export const enus = {
  translation: {
    // Login
    "login_page_title": "InteliQR Menú",
    "login_page_subtitle": "Please log in to access your panel",
    "login_form_signin_google_button": "Sign In",
    "login_form_divider_options": "Or",
    "login_form_email": "Email",
    "login_form_password": "Password",
    "login_form_button": "Sign in",
    // Register
    "register_page_title": "Create account",
    "register_page_subtitle": "Build your menú",
    "register_form_company": "Company name",
    "register_form_signin_google_button": "Sign Up",
    "register_form_divider_options": "Or",
    "register_form_email": "Email",
    "register_form_password": "Password",
    "register_form_button": "Create account",
    "register_already_account_text": "Already have an account",
    "register_already_account_button": "Log in",
    //
    "cancelar": "cancel",
    "bienvenido": "Welcome",
    "mensaje_bienvenida": "Here you can manage everything related to the menus of your company",
    //branch office
    "branchoffice_page_title": "Branch Offices",
    "branchoffice_page_add_button_text": "Add",
    "branchoffice_page_edit_button_text": "Edit",
    "branchoffice_page_delete_button_text": "Delete",
    "branchoffice_search_placeholder_text": "Search",
    "branchoffice_card_name": "Name",
    "branchoffice_card_address": "Address (street and number)",
    "branchoffice_card_city": "City",
    "branchoffice_card_state": "State",
    "branchoffice_card_postalcode": "Postal code",
    "branchoffice_card_phone": "Phone (permanent)",
    "branchoffice_card_cellphone": "Phone (mobil - whatsapp)",
    "branchoffice_card_email": "Email",
    "branchoffice_dialog_title_add": "Add branch office",
    "branchoffice_dialog_title_edit": "Edit branch office",
    "branchoffice_dialog_title_delete": "Delete branch office",
    "branchoffice_dialog_confirm_delete_text": "Are you sure you want to delete the branch office {{name}}?",
    "branchoffice_form_name": "Name",
    "branchoffice_form_address": "Address",
    "branchoffice_form_city": "City",
    "branchoffice_form_state": "State",
    "branchoffice_form_zip_code": "Postal code",
    "branchoffice_form_phone": "Phone (permanent)",
    "branchoffice_form_whatsapp": "Phone (mobil - whatsapp)",
    "branchoffice_form_email": "Email",
    "branchoffice_form_logo": "Logo",
    "branchoffice_form_active": "Activate",
    //users
    "users_page_title": "Users",
    "users_search_placeholder_text": "Search",
    "users_table_name_column": "Name",
    "users_table_email_column": "Email",
    "users_table_role_column": "Role",
    "users_table_actions_column": "Actions",
    "users_dialog_title_add": "Add user",
    "users_dialog_title_edit": "Edit user",
    "users_dialog_title_permission": "Permisions user",
    "users_dialog_title_delete": "Delete user",
    "users_dialog_confirm_delete_text": "¿Are you sure you want to delete the user {{name}}?",
    "users_form_name": "Name",
    "users_form_phone": "Phone",
    "users_form_email": "Email",
    "users_form_password": "Password",
    "users_form_role": "User role",
    "users_form_branch_office": "Branch office",
    "users_form_active": "Activate",
    //menus
    "menu_page_title": "Menus",
    "menu_page_add_button_text": "Add",
    "menu_page_edit_button_text": "Edit",
    "menu_page_delete_button_text": "Delete",
    "menu_page_show_menu_button_text": "Show menu",
    "menu_search_placeholder_text": "Search",
    "menu_table_name_column": "Name",
    "menu_table_description_column": "Description",
    "menu_table_actions_column": "Actions",
    "menu_dialog_title_add": "Add menú",
    "menu_dialog_title_edit": "Edit menú",
    "menu_dialog_title_delete": "Delete menú",
    "menu_dialog_confirm_delete_text": "Are you sure you want to delete the menu {{name}}?",
    "menu_form_name": "Name",
    "menu_form_description": "Description",
    "menu_form_branch_office": "Branch Office",
    "menu_form_active": "Activate",
    //menu design
    "designmenu_page_title": "Menu design",
    "designmenu_menu_design_text": "Menu design",
    "designmenu_select_options_placeholder": "Selection of options",
    "designmenu_select_option_options": "Options",
    "designmenu_select_option_general_features": "General Features",
    "designmenu_select_option_header": "Header",
    "designmenu_select_option_navigation_menu": "Navigation Menu",
    "designmenu_select_option_separators": "Separators",
    "designmenu_select_option_typography": "Tipography",
    //product tags
    "producttags_page_title": "Product label",
    "producttags_page_add_button_text": "Add",
    "producttags_page_edit_button_text": "Edit",
    "producttags_page_delete_button_text": "Delete",
    "producttags_search_placeholder_text": "Search",
    "producttags_table_name_column": "Name",
    "producttags_table_actions_column": "Actions",
    "producttags_dialog_title_add": "Add label",
    "producttags_dialog_title_edit": "Edit label",
    "producttags_dialog_title_delete": "Delete label",
    "producttags_dialog_confirm_delete_text": "Are you sure you want to delete the label {{name}}?",
    "producttags_form_name": "Name",
    "producttags_form_textcolor": "Text color",
    "producttags_form_backgroundcolor": "Background color",
    "producttags_form_active": "Activate",
    //promotions tags
    "promotions_page_title": "Promotions",
    "promotions_page_add_button_text": "Add",
    "promotions_page_edit_button_text": "Edit",
    "promotions_page_delete_button_text": "Delete",
    "promotions_page_show_button_text": "Show promotion",
    "promotions_page_copy_button_text": "Copy",
    "promotions_search_placeholder_text": "Search",
    "promotions_page_change_menu_button_text": "Change menu",
    "promotions_table_name_column": "Name",
    "promotions_table_validity_column": "Validity",
    "promotions_table_actions_column": "Actions",
    "promotions_dialog_title_add": "Add promotion",
    "promotions_dialog_title_edit": "Edit promotion",
    "promotions_dialog_title_delete": "Delete promotion",
    "promotions_dialog_title_copy": "Copy promotion",
    "promotions_dialog_instrucions_copy_text": "Select the menu(s) in which the promotion is required to be copied",
    "promotions_dialog_confirm_delete_text": "Are you sure you want to delete the promotion {{name}}?",
    "promotions_form_name": "Name",
    "promotions_form_description": "Description",
    "promotions_form_image": "Image",
    "promotions_form_duration": "Duration",
    "promotions_form_validity_start": "Validity - Start time and date",
    "promotions_form_validity_end": "Validity - Time and date of termination",
    "promotions_form_active": "Activate",

    //Builder
    "builder_page_title": "Categories y Subcategories",
    "builder_page_instructions": "Menu to which categories, subcategories and products are added",
    "builder_page_menu_select_label": "Choice",
    //Builder Categories
    "builder_categories_title": "Categories",
    "builder_categories_dialog_title_add": "Add category",
    "builder_categories_dialog_title_edit": "Edit category",
    "builder_categories_dialog_title_delete": "Delete category",
    "builder_categories_dialog_confirm_delete_text": "Are you sure you want to delete the category {{name}}?",
    "builder_categories_form_name": "Name",
    "builder_categories_form_show_name": "Show name",
    "builder_categories_form_description": "Description",
    "builder_categories_form_footer": "Text at the bottom of the category",
    "builder_categories_form_image": "Navigation menu inactive icon",
    "builder_categories_form_image_active": "Navigation menu active icon",
    "builder_categories_form_show_image": "Show ícon",
    "builder_categories_form_header_image": "Header image",
    "builder_categories_form_show_header_image": "Show header image",
    "builder_categories_form_active": "Activate",
    //Builder Subcategories
    "builder_subcategories_title": "Subcategorys",
    "builder_subcategories_dialog_title_add": "Add subcategory",
    "builder_subcategories_dialog_title_edit": "Edit subcategory",
    "builder_subcategories_dialog_title_delete": "Delete subcategory",
    "builder_subcategories_dialog_confirm_delete_text": "Are you sure you want to delete the subcategory {{name}}?",
    "builder_subcategories_form_name": "Name",
    "builder_subcategories_form_show_name": "Show name",
    "builder_subcategories_form_description": "Description",
    "builder_subcategories_form_footer": "Text at the bottom of thesubcategory",
    "builder_subcategories_form_image": "Image",
    "builder_subcategories_form_show_image": "Show image",
    "builder_subcategories_form_presentation": "Presentation type",
    "builder_subcategories_form_active": "Activate",
    // Qr code
    "qrcode_page_title": "Qr Codes",
    "qrcode_page_add_button_text": "Add",
    "qrcode_page_edit_button_text": "Edit",
    "qrcode_page_delete_button_text": "Delete",
    "qrcode_page_style_button_text": "Style",
    "qrcode_search_placeholder_text": "Search",
    "qrcode_table_name_column": "Name",
    "qrcode_table_branchoffice_column": "Branch office",
    "qrcode_table_menu_column": "Menu",
    "qrcode_table_actions_column": "Actions",
    "qrcode_dialog_title_add": "Add qr code",
    "qrcode_dialog_title_edit": "Edit qr code",
    "qrcode_dialog_title_delete": "Delete qr code",
    "qrcode_dialog_title_style": "Style qr code",
    "qrcode_dialog_confirm_delete_text": "Are you sure you want to delete the qr code {{name}}?",
    "qrcode_form_name": "Name",
    "qrcode_form_description": "Description",
    "qrcode_form_branch_office": "Branch office",
    "qrcode_form_menu": "Menu",
    "qrcode_form_active": "Activate",
    "qrcode_style_form_size": "QR size",
    "qrcode_style_form_margin": "Margin",
    "qrcode_style_form_image": "Central image",
    "qrcode_style_form_background": "Backgorund color",
    "qrcode_style_form_points_style": "Points style",
    "qrcode_style_form_points_color": "Points color",
    "qrcode_style_form_corners_square_style": "Corners square style",
    "qrcode_style_form_corners_square_color": "Corners square color",
    "qrcode_style_form_corners_dot_style": "Points into corners square style",
    "qrcode_style_form_corners_dot_color": "Points into corners square color",

    //Validaciones
    "required": "Required",

    //Buttons text
    "button_text_add": "Add",
    "button_text_edit": "Edit",
    "button_text_delete": "Delete",
    "button_text_cancel": "Cancel",
    "button_text_copy": "Copy",
    "button_text_move": "Move",

    //Menu Drawer
    "menu_drawer_text_dashboard": "Dashboard",
    "menu_drawer_text_branchoffices": "Branch Offices",
    "menu_drawer_text_users": "Users",
    "menu_drawer_text_menus": "Menus",
    "menu_drawer_text_design": "Design",
    "menu_drawer_text_builder": "Categories & Sub",
    "menu_drawer_text_labels": "Labels",
    "menu_drawer_text_promotions": "Promotions",
    "menu_drawer_text_qrcode": "QR Codes",

    //MUIDataTable
    "muidt_body_no_match": "Sorry, no matching records found",
    "muidt_body_tooltip_order": "Sort",
    "muidt_body_custom_tooltip_order": "Sort for",
    "muidt_pagination_next": "Next page",
    "muidt_pagination_previous": "Previous page",
    "muidt_pagination_rowsperpage": "Rows per page:",
    "muidt_pagination_displayrows": "of",
    "muidt_toolbar_search": "Search",
    "muidt_toolbar_downloadcsv": "Download CSV",
    "muidt_toolbar_print": "Print",
    "muidt_toolbar_viewcolumns": "View columns",
    "muidt_toolbar_filtertable": "Filter table",
    "muidt_filter_all": "All",
    "muidt_filter_title": "Filters",
    "muidt_filter_reset": "Reset",
    "muidt_viewcolumnas_title": "Show  columns",
    "muidt_viewcolumnas_titlearia": "Show/Hide table columns",
    "muidt_selectedrows_text": "row(s) selected",
    "muidt_selectedrows_delete": "Delete",
    "muidt_selectedrows_deletearia": "Delete selected rows",
  }
}