import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material"
import { IconButton } from "@mui/material"

export const handleSetInstance = (id, data, setData) => {
  const instance = data.data.filter(item => (item._id === id))
  setData(instance[0])
}

export const getActive = (id, data) => {
  const instance = data.data.filter(item => (item._id === id))
  if (instance.length === 0)
    return false
  return instance[0].active
}

export const MUIDatatableCustomBodyRenderActions = (
  open, 
  clickMenuActions
) =>
  (value, tableMeta, updateValue) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton id="demo-customized-button" aria-haspopup="true" variant="contained"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => clickMenuActions(event, value)}
        sx={{ marginRight: 1 }}>
        <MoreHorizIcon />
      </IconButton>
    </div>
  )

export const MUIDatatableOptions = (
  customBodyRenderActions,
  open,
  handleClickMenuActions,
) =>
  [
    {
      name: "full_name", label: "Nombre",
      options: {
        filter: true, sort: true,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>
      }
    },
    {
      name: "political_abbreviation", label: "Partido político",
      options: {
        filter: true, sort: true,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>
      }
    },
    {
      name: "percentile_indicators", label: "Estadísticas",
      options: {
        filter: false, sort: false,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div><b>Asistencia:</b>{value.assistances}</div>
            <div><b>Asistencia a comisiones:</b> {value.commisions_assistances} <br /></div>
            <div><b>Participación en pleno:</b> {value.full_participation} <br /></div>
            <div><b>Iniciativas presentadas:</b> {value.initiatives_presented} <br /></div>
            <div><b>Transparencia:</b> {value.transparency} <br /></div>
            <div><b>Iniciativas aprobadas:</b> {value.initiatives_approved} <br /></div>
            <div><b>Total:</b> {value.total} <br /></div>
          </div>
        )
      }
    },
    {
      name: "_id", label: "Opciones",
      options: {
        filter: false, sort: false, download: false,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>,
        customBodyRender: customBodyRenderActions(open, handleClickMenuActions)
      }
    },
  ];