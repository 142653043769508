

export const SaveUpdateInstructions = () => {
  return (
    <div>
      <p><b>INSTRUCCIONES:</b></p>
      <p><b>1. Descarga la plantilla</b></p>
      <p><b>2. Llena la plantilla con los datos que deseas cargar</b></p>
      <p>
        <ul>
          <li><b>NUNCA:</b> se deberán eliminar o cambiar el nombre a las columnas id y diputado</li>
          <li>Para el numero y fecha de la sesión se deberá usar el siguiente orden en las columnas: <b>[número de la sesión]_[fecha de la sesion en formato YYYY-MM-DD]</b>, ejemplo. Si queremos decir que la sesion es la 32 y la fecha el primero de diciembre de 2023 sería colocar el nombre de la columna de la siguiente forma: <b>32_2023-12-01</b></li>
        </ul>
      </p>
      <p><b>3. Selecciona el perido legislativo al cual pertenecen los datos</b></p>
      <p><b>4. Selecciona el tipo de información (completo ó parcial)</b></p>
      <p>
        <ul>
          <li><b>COMPLETO:</b> se sustituirá la información completamente por la nueva para cada diputado para el periodo seleccionado</li>
          <li><b>PARCIAL:</b> se añadirá esta nueva información a la ya existente</li>
        </ul>
      </p>
      <p><b>5. Sube el archivo</b></p>
    </div>
  )
}