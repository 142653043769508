import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../../firebase/firebase"
import { IconButton, Tooltip } from "@mui/material";
import { CloudDownload as CloudDownloadIcon } from "@mui/icons-material";

export const Viewer = ({ name, path }) => {

  const handlerDownloadFile = () => {
    getDownloadURL(ref(storage, path))
      .then((url) => {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', `${name}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }).catch((error) => {
        console.log(error)
      })
  }

  return (<>
    <Tooltip title="Descargar">
      <IconButton onClick={() => { handlerDownloadFile() }} >
        <CloudDownloadIcon />
      </IconButton>
    </Tooltip>
  </>)
}