import { useEffect, useState } from "react";
import { Polygon, Tooltip, useMap } from "react-leaflet";


export default function SetBoundsRectanglesMap4({ coordinates, infoSticky, extraData }) {
  const [color, setColor] = useState("purple");
  const [opacitySelected, setOpacitySelected] = useState(null);
  const map = useMap();

  useEffect(() => {
    if (coordinates.length > 0) {
      console.log("coordinates", coordinates);
      const onlyCoordinates = coordinates.map((item) => item.coordinates);
      map.fitBounds(onlyCoordinates);
    }
  }, [coordinates]);

  return (
    <div>
      {coordinates.map((item, index) => (
        <Polygon
          key={index}
          eventHandlers={{
            click: () => {
              infoSticky(item);
              extraData(item);
              setOpacitySelected(index);
              map.fitBounds(item.coordinates);
            }
          }}
          pathOptions={{
            color: item.electoralresults !== undefined ? item.electoralresults[0].color : color,
            fillColor: item.electoralresults !== undefined ? item.electoralresults[0].color : color,
            opacity: opacitySelected === index ? 1 : 0.5,
            fillOpacity: opacitySelected === index ? 1 : 0.5,
          }}
          positions={item.coordinates}
        >
          <Tooltip sticky>
            <div>
              <div>
                <b>Identificador: </b> {item.name} <br />
                <b>Tipo de sección </b> {item.extraData.section_type}
              </div>
            </div>
          </Tooltip>
        </Polygon>
      ))}
    </div>
  );
}