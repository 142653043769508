import { Menu, MenuItem } from "@mui/material";
import { StyleModuleMenu } from "./StyleModuleMenu";

export const OptionMenus = ({ anchor, id, open, close, options }) => {
  return (
    <Menu
      anchorEl={anchor}
      id={id}
      open={open}
      onClose={close}
      onClick={close}
      PaperProps={StyleModuleMenu}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={option.action}>
            {option.icon} {option.label}
          </MenuItem>
        ))}
    </Menu>
  )
}