import { useState } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from "react-i18next";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { remove } from "../services/api";

export default function Remove({ open, close, instance, reportAction }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)

  const mutation = useMutation(remove, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction(false, 'Error al guardar los datos')
      } else { reportAction(true, 'Acción realizada correctamente') }
    }
  })

  const handleRemove = () => { 
    setIsLoading(true)
    mutation.mutate(instance._id)
  }

  const handleClose = () => { close() }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>{t("button_text_cancel")}</Button>
      <Button variant="contained" color="error" onClick={ () => handleRemove() }>{t("button_text_delete")}</Button>
      </> : <CircularProgress /> }
  </>)

  return (
    <FlexibleWidthDialog open={open} title={t("districts_dialog_title_delete")} actions={<Actions />}>
      <Typography variant="body1" component="p">
        {t("districts_dialog_confirm_delete_text", { name: instance.name })}
      </Typography>
    </FlexibleWidthDialog>
  )
}