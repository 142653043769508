import { MapContainer, TileLayer } from "react-leaflet"
import 'leaflet/dist/leaflet.css'
import SetBoundsRectanglesMap7 from "./SetBoundsRectanglesMap7"
import { useEffect, useState } from "react"
import { GetMap7 } from "../services/api"
import { useQuery } from "@tanstack/react-query"
import { InfoWindow } from "../components/InfoWindow"
import { Loader } from "../components/Loader"
import { politicianParties } from "../services/politicianParties"

export const Map7 = ({ localDistrictId }) => {
  const [coordinates, setCoordinates] = useState([])
  const [year] = useState("2021")
  const [infoStickyBottomRightWindow, setInfoStickyBottomRightWindow] = useState(null)
  //const [extraData, setExtraData] = useState(null)

  const { isFetching: iFgetMap7, data: getMap7 } = useQuery(
    ["GET_MAP5", localDistrictId, year],
    () => GetMap7({ id: localDistrictId, year: year }),
    { refetchOnWindowFocus: false, enabled: localDistrictId !== undefined && localDistrictId !== null }
  )

  const buildelectoralResults = (abbreviation, votes) => {
    const find = politicianParties.find(party => party.identifier === abbreviation)

    return {
      color: find !== undefined ? find.color : "#86785C",
      abbreviation: abbreviation,
      image: find !== undefined ? find.logo : null,
      votes: votes,
    }
  }

  useEffect(() => {
    if (getMap7 !== undefined && getMap7 !== null) {
      const sections = getMap7.sections.map((section) => {
        return {
          coordinates: section.coordinates,
          electoralresults: [buildelectoralResults(section.extraData.pg2f, section.extraData.v2f)],
          name: section.identifier,
          extraData: section.extraData,
          tags: []
        }
      })
      setCoordinates(sections)
    }
  }, [getMap7])

  const handleBuildInfosWindow = ({ name, electoralresults, extraData }) => {
    if (extraData) {
      let info = `<div><b>Tipo de sección: </b> ${extraData.section_type}</div>`
      info += `<div>`
      info += `<b>Porcentaje de participación ciudadana: </b> ${extraData.pcp}%`
      info += `</div>`
      setInfoStickyBottomRightWindow(info)
    } else {
      setInfoStickyBottomRightWindow("Proximamente")
    }
  }

  /* const handleExtraData = ({ electoralresults, extraData, tags }) => {
    if (extraData !== undefined) {
      const electoralResultsSort = electoralresults.sort((a, b) => b.totalVotes - a.totalVotes)
      let info = ""
      info += `<div style="width: 100%; display: flex; align-items: baseline; padding: 5px 0px; border-bottom: 1px solid #cecece;">`
      info += `<div style="width: 50%;">`
      info += `<p><h2>Datos de la Votación</h2></p>`
      info += `<p><b>Lista Nominal</b>: ${new Intl.NumberFormat('es-MX').format(extraData.nominal_list)}</p>`
      info += `<p><noteb>Votos no Registrados</noteb>: ${new Intl.NumberFormat('es-MX').format(extraData.num_nreg_votes)}</p>`
      info += `<p><b>Votos Nulos</b>: ${new Intl.NumberFormat('es-MX').format(extraData.num_null_votes)}</p>`
      info += `<p><b>Votos Totales</b>:${new Intl.NumberFormat('es-MX').format(extraData.num_total_votes)}</p>`
      info += `<p><b>Margen de Víctoria Nominal</b>: ${new Intl.NumberFormat('es-MX').format(electoralResultsSort[0].totalVotes - electoralResultsSort[1].totalVotes)}</p>`
      info += `<p><b>Margen de Victoria Porcentual</b>: ${new Intl.NumberFormat('es-MX').format((((electoralResultsSort[0].totalVotes - electoralResultsSort[1].totalVotes) / extraData.num_total_votes) * 100).toFixed(2))}%</p>`
      info += `</div>`

      if (tags !== undefined) {
        info += `<div style="width: 50%;">`
        info += `<p><h2>Categorías</h2></p>`
        tags.forEach(tag => {
          info += `<p><b>${tag.name}</b>: ${tag.value}</p>`
        })
        info += `</div>`
      }

      info += `</div>`
      setExtraData(info)
    } else {
      setExtraData("Proximamente")
    }
  } */

  return (<>
    {coordinates.length > 0 ? <>
      <div style={{ position: "relative" }}>
        <MapContainer
          center={[19.3264047, -99.6049788]}
          zoom={15}
          scrollWheelZoom={true}
          style={{ height: "500px", width: "100%", borderRadius: "14px", boxShadow: "0 3px 4px 0px rgba(0,0,0,0.2)" }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" />
          <SetBoundsRectanglesMap7 coordinates={coordinates} infoSticky={handleBuildInfosWindow} extraData={() => console.log("data")} />
          <Loader show={iFgetMap7} />
        </MapContainer>
        <InfoWindow
          show={infoStickyBottomRightWindow !== null}
          close={() => setInfoStickyBottomRightWindow(null)}
          title="Resultados"
          placeholder="Da click sobre una localidad"
          position="bottom-right"
          info={infoStickyBottomRightWindow} />
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: extraData }} /> */}
    </> : null}
  </>)
}