import { Divider, Fab, MenuItem, Tooltip } from "@mui/material";
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import Add from "./Add";
import Edit from "./Edit";
import Remove from "./Remove";

export function ActionsDialogPage({ open, close, instance, reportAction }) {
  return (<>
    <Add
      open={open === 1}
      close={close}
      reportAction={reportAction} />
    <Edit
      open={open === 2}
      close={close}
      instance={instance}
      reportAction={reportAction} />
    <Remove
      open={open === 3}
      close={close}
      instance={instance}
      reportAction={reportAction} />
  </>)
}

export function ActionFabButtonAdd({ t, action }) {
  return (
    <span className="fab_add">
      <Tooltip title={t("districts_page_add_button_text")}>
        <Fab color="primary" onClick={action}><AddIcon /></Fab>
      </Tooltip>
    </span>
  )
}

export function ActionsMenuPage({ t, action }) {
  // 2: edit, 3: delete
  return (<>
    <MenuItem onClick={() => { action(2)}} disableRipple>
      <EditIcon /> {t("districts_page_edit_button_text")}
    </MenuItem>
    <Divider />
    <MenuItem onClick={() => { action(3) }} disableRipple>
      <DeleteIcon /> {t("districts_page_delete_button_text")}
    </MenuItem>
  </>)
}