import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"


export const MapNumbers = ({ listMapNumbers, mapNumber, onChange }) => {

    console.log(listMapNumbers)
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Mapas</InputLabel>
      <Select
        size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={mapNumber}
        label="Mapas"
        onChange={onChange} >
        <MenuItem value={0}>Selecciona un mapa</MenuItem>
        {listMapNumbers.map((map, index) => (
          <MenuItem key={index} value={map.id}>{map.title}</MenuItem>
        ))
        }
      </Select>
    </FormControl>
  )
}