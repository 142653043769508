import { API_BASE_URL, HEADERS_WITH_TOKEN } from "services/api/BaseUrl"


export const getData = async (params) => {
  const { category, page } = params
  const user = JSON.parse(localStorage.getItem('LOAu'))

  const res = await fetch(
    `${API_BASE_URL}/library-politican?politician_id=${user.politician_id}&category=${category}&page=${page}`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt')),
    }
  )
  return await res.json()
}

// Library Knowledge Categories

export const getAllLibraryFoldersPolitican = () =>
  fetch(
    `${API_BASE_URL}/library-folders-politician/show-for-politicians`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt'))
    }
  )
    .then(res => res.json())