import { FMPageOpacityTransition } from "components/FMTransitions/PageTransitions";
import { useUserRole } from "hooks/useUserRole";
import Administrator from "./modules/Administrator";
import Politician from "./modules/Politician";
import PoliticianAdmin from "./modules/PoliticianAdmin";
import { Box, Container, Paper } from "@mui/material";

export default function Dashboard() {
  useUserRole(["SUPERADMIN", "ADMIN", "EDITOR", "POLITICIAN", "POLITICIAN_ADMIN"])
  const userLS = JSON.parse(localStorage.getItem('LOAu'))
  return (<>
    <FMPageOpacityTransition>
      <Box component="main" style={{ marginBottom: 100, }}>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Paper sx={{
            padding: "20px 10px 20px 10px",
            backgroundColor: "#efefef",
            borderRadius: "10px",
          }}>
            {userLS.role.identifier === "SUPERADMIN" || userLS.role.identifier === "ADMIN" ? <Administrator /> : null}
            {userLS.role.identifier === "POLITICIAN" ? <Politician /> : null}
            {userLS.role.identifier === "POLITICIAN_ADMIN" ? <PoliticianAdmin /> : null}
          </Paper>
        </Container>
      </Box>
    </FMPageOpacityTransition >
  </>)
}