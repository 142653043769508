import { MapContainer, TileLayer } from "react-leaflet"
import 'leaflet/dist/leaflet.css'
import SetBoundsRectangles from "./modules/SetBoundsRectangles"
import { useEffect, useState } from "react"
import { getDistrict, getSectionsByDistrict } from "./services/api"
import { useQuery } from "@tanstack/react-query"
import { InfoWindow } from "./modules/InfoWindow"
import { Loader } from "./modules/Loader"
import { Filters } from "./modules/Filters"

const years = ["2015", "2018", "2021"]

export const ElectoralResultsMap = ({ localDistrictId }) => {
  const [coordinates, setCoordinates] = useState([])
  const [switchGeoData, setSwitchGeoData] = useState(1) // 1 = district, 2 = sections
  const [year, setYear] = useState("2021")
  const [infoStickyBottomRightWindow, setInfoStickyBottomRightWindow] = useState(null)
  const [extraData, setExtraData] = useState(null)
  const { isFetching: iFlocalDistrict, data: localDistrict } = useQuery(
    ["GET_DISTRICT", localDistrictId, year],
    () => getDistrict(localDistrictId, year),
    { refetchOnWindowFocus: false, enabled: localDistrictId !== undefined && localDistrictId !== null && switchGeoData === 1 && years.includes(year) }
  )
  const { isFetching: iFsectionsByDistrict, data: sectionsByDistrict } = useQuery(
    ["GET_SECTIONS_BY_DISTRICT", localDistrictId, year],
    () => getSectionsByDistrict(localDistrictId, year),
    { refetchOnWindowFocus: false, enabled: switchGeoData === 2 && years.includes(year) }
  )

  useEffect(() => {
    if (localDistrict !== undefined && localDistrict !== null && switchGeoData === 1) {
      if (localDistrict.status === "not-found") { setCoordinates([]) }
      else { setCoordinates([localDistrict.data]) }
    }
  }, [localDistrict, switchGeoData])

  useEffect(() => {
    if (sectionsByDistrict !== undefined && sectionsByDistrict !== null && switchGeoData === 2) {
      const sections = sectionsByDistrict.sections.map((section) => {
        return {
          coordinates: section.coordinates,
          electoralresults: section.electoralresults.length > 0 ? section.electoralresults : [{ color: "#000000" }],
          name: section.identifier,
          extraData: section.extraData,
          tags: section.tags
        }
      })
      setCoordinates(sections)
    }
  }, [sectionsByDistrict, switchGeoData])

  const handleBuildInfosWindow = ({ name, electoralresults }) => {
    if (electoralresults !== undefined && electoralresults.length > 0) {
      let info = ""
      electoralresults.forEach(element => {
        info += `<div style="width: 100%%; display: flex; align-items: center; padding: 5px 0px; border-bottom: 1px solid #cecece;">`
        info += `<div>`
        info += `<img src="${element.image}" style="height: 40px;" />`
        info += `</div>`
        info += `<div style="padding: 0px 5px">`
        info += `<span style="text-transform: uppercase; font-weight: bold">${element._id}</span> <br/>`
        info += `<div><span>Votos: ${element.totalVotes}<span/></div>`
        info += `</div>`
        info += `</div>`
      });
      setInfoStickyBottomRightWindow(info)
    } else {
      setInfoStickyBottomRightWindow("Proximamente")
    }
  }

  const handleExtraData = ({ electoralresults, extraData, tags }) => {
    if (extraData !== undefined) {
      const electoralResultsSort = electoralresults.sort((a, b) => b.totalVotes - a.totalVotes)
      let info = ""
      info += `<div style="width: 100%; display: flex; align-items: baseline; padding: 5px 0px; border-bottom: 1px solid #cecece;">`
      info += `<div style="width: 50%;">`
      info += `<p><h2>Datos de la Votación</h2></p>`
      info += `<p><b>Lista Nominal</b>: ${new Intl.NumberFormat('es-MX').format(extraData.nominal_list)}</p>`
      info += `<p><noteb>Votos no Registrados</noteb>: ${new Intl.NumberFormat('es-MX').format(extraData.num_nreg_votes)}</p>`
      info += `<p><b>Votos Nulos</b>: ${new Intl.NumberFormat('es-MX').format(extraData.num_null_votes)}</p>`
      info += `<p><b>Votos Totales</b>:${new Intl.NumberFormat('es-MX').format(extraData.num_total_votes)}</p>`
      info += `<p><b>Margen de Víctoria Nominal</b>: ${new Intl.NumberFormat('es-MX').format(electoralResultsSort[0].totalVotes - electoralResultsSort[1].totalVotes)}</p>`
      info += `<p><b>Margen de Victoria Porcentual</b>: ${new Intl.NumberFormat('es-MX').format((((electoralResultsSort[0].totalVotes - electoralResultsSort[1].totalVotes) / extraData.num_total_votes) * 100).toFixed(2))}%</p>`
      info += `</div>`

      if (tags !== undefined && switchGeoData === 2) {
        info += `<div style="width: 50%;">`
        info += `<p><h2>Categorías</h2></p>`
        tags.forEach(tag => {
          info += `<p><b>${tag.name}</b>: ${tag.value}</p>`
        })
        info += `</div>`
      }

      info += `</div>`
      setExtraData(info)
    } else {
      setExtraData("Proximamente")
    }
  }

  const handleVisibilityTypeSelect = (event) => {
    setInfoStickyBottomRightWindow(null)
    setExtraData(null)
    setSwitchGeoData(event.target.value)
  }
  const handleYearSelect = (event) => {
    setInfoStickyBottomRightWindow(null)
    setExtraData(null)
    setYear(event.target.value)
  }

  return (<>
    {coordinates.length > 0 ? <>
      <Filters
        switchGeoData={switchGeoData}
        handleVisibilityTypeSelect={handleVisibilityTypeSelect}
        year={year}
        handleYearSelect={handleYearSelect} />
      <div style={{ position: "relative" }}>
        <MapContainer
          center={[19.3264047, -99.6049788]}
          zoom={15}
          scrollWheelZoom={true}
          style={{ height: "500px", width: "100%", borderRadius: "14px", boxShadow: "0 3px 4px 0px rgba(0,0,0,0.2)" }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" />
          <SetBoundsRectangles coordinates={coordinates} infoSticky={handleBuildInfosWindow} extraData={handleExtraData} />
          <Loader show={iFlocalDistrict || iFsectionsByDistrict} />
        </MapContainer>
        <InfoWindow
          show={infoStickyBottomRightWindow !== null}
          close={() => setInfoStickyBottomRightWindow(null)}
          title="Resultados electorales"
          placeholder="Da click sobre una localidad"
          position="bottom-right"
          info={infoStickyBottomRightWindow} />
      </div>
      <div dangerouslySetInnerHTML={{ __html: extraData }} />
    </> : null}
  </>)
}