import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Container, Paper, Tab, Tabs } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FMPageOpacityTransition } from "components/FMTransitions/PageTransitions";
import { getAll, save } from "./services/api";
import { getAll as getAllPolitician } from "../Politician/services/api";
import { SnackbarGlobal } from "components/Snackbar";
import { useUserRole } from "hooks/useUserRole";
import Folders from "./Folders";
import Files from "./Files";

export default function LibraryV2() {
  useUserRole(["SUPERADMIN", "ADMIN", "LIBRARY"])
  const { data, isFetched } = useQuery(['librariesList'], getAll, { refetchOnWindowFocus: false })
  const [notification, setNotification] = useState({ open: false, message: "", severity: "" })
  const [existLibrary, setExistLibrary] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()
  const { data: politicianList } = useQuery(['politicianList'], getAllPolitician, { refetchOnWindowFocus: false })
  const [politicians, setPoliticians] = useState([])

  const [tabSelected, setTabSelected] = useState(0); // 0 = folders, 1 = files

  useEffect(() => {
    if (data !== undefined && data !== null && data !== "") {
      if (data.data !== undefined && data.data !== null && data.data !== "") {
        if (data.data._id !== undefined && data.data._id !== null) setExistLibrary(true)
      } else {
        setExistLibrary(false)
      }
    }
  }, [data])

  useEffect(() => {
    if (politicianList !== undefined && politicianList !== null && politicianList !== "") {
      let politician = politicianList.data.map((politician) => {
        return { value: politician._id, label: politician.full_name }
      })
      setPoliticians(politician)
    }
  }, [politicianList])

  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity })
    if (success) { queryClient.invalidateQueries(['librariesList']) }
  }

  const mutation = useMutation(save, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") { handleWatchAction(false, 'Error al guardar los datos', 'error') }
      else { handleWatchAction(true, 'Acción realizada correctamente', 'success') }
    }
  })

  const handleChangeTabSelected = (event, newValue) => setTabSelected(newValue)

  return (<>
    <FMPageOpacityTransition>
      <Box component="main" style={{ marginBottom: 100, }}>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Paper sx={{ padding: "20px 10px 20px 10px", backgroundColor: "#efefef", borderRadius: "10px" }}>
            {existLibrary ? <>
              <Tabs value={tabSelected} onChange={handleChangeTabSelected} aria-label="basic tabs example">
                <Tab value={0} label="Folders / Categorías" />
                <Tab value={1} label="Archivos" />
              </Tabs>
              <div style={{ marginTop: 20 }} role="tabpanel" hidden={tabSelected !== 0} id={`simple-tabpanel-0`} aria-labelledby={`simple-tab-0`}>
                <Folders/>
              </div>
              <div style={{ marginTop: 20 }} role="tabpanel" hidden={tabSelected !== 1} id={`simple-tabpanel-1`} aria-labelledby={`simple-tab-1`}>
                <Files libraryID={data.data._id} />
              </div>
            </> : <div style={{ textAlign: "center" }}>
              {!isLoading && isFetched ?
                <Button variant="contained" size="large" onClick={() => mutation.mutate()}>
                  Agregar biblioteca a Legislatura
                </Button>
                : <CircularProgress />}
            </div>
            }
          </Paper>
        </Container>
      </Box>
    </FMPageOpacityTransition>
    <SnackbarGlobal
      open={notification.open}
      close={() => setNotification({ open: false, message: "", severity: "" })}
      message={notification.message}
      severity={notification.severity} />
  </>)
}