import { useEffect, useState } from "react";
import { Button, CircularProgress, Grid } from "@mui/material"
import { useMutation } from "@tanstack/react-query";
import { updateLibraryFiles } from "routes/Library/services/api";
import { useTranslation } from "react-i18next";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog";
import { PoliticianGroup } from "./modules/PoliticianGroup";
import { FoldersGroup } from "./modules/FoldersGroup";

export const Edit = ({ open, close, instance, folders, politicians, reportAction }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [fileIndexToAssign, setFileIndexToAssign] = useState(null)
  const [politiciansFilter, setPoliticiansFilter] = useState([])

  useEffect(() => {
    if (open) {
      setFiles([instance])
      setFileIndexToAssign(0)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (politicians.length > 0) { setPoliticiansFilter(politicians) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [politicians])

  const handleClose = () => {
    setFiles([])
    setFileIndexToAssign(null)
    close()
  }

  const mutation = useMutation(updateLibraryFiles, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al guardar los datos', 'error')
      } else {
        setFiles([]);
        reportAction(true, 'Acción realizada correctamente', 'success')
      }
    }
  })

  const handleEdit = () => {
    setIsLoading(true)
    let dataTemp = {
      politicians: files[fileIndexToAssign]["politicians"],
      categoriesFolderPolitician: files[fileIndexToAssign]["categoriesFolderPolitician"]
    }
    console.log(dataTemp)
    mutation.mutate({ id: instance._id, params: { data: dataTemp } })
  }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>Cancelar</Button>
      <Button variant="contained" color="info" onClick={() => handleEdit()} disabled={files.length === 0}>Actualizar</Button>
    </> : <CircularProgress />}
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={`Editar - ${instance.name}`} actions={<Actions />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {fileIndexToAssign !== null && files.length > 0 ? <>
            <h4>Asignacion de categoría</h4>
            <FoldersGroup
              folders={folders}
              files={files} setFiles={setFiles}
              fileIndexToAssign={fileIndexToAssign} />
            <h4>Asignación de diputado</h4>
            <PoliticianGroup
              politicians={politicians}
              politiciansFilter={politiciansFilter} setPoliticiansFilter={setPoliticiansFilter}
              files={files} setFiles={setFiles}
              fileIndexToAssign={fileIndexToAssign} />
          </> : null}
        </Grid>
      </Grid>
    </FlexibleWidthDialog>
  </>)
}