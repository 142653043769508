//export const API_GEOPOLITICAL_URL = 'http://localhost:8080/api/v1'; // Local
export const API_GEOPOLITICAL_URL = 'https://geopolitical-microservice-hltza4osjq-uc.a.run.app/api/v1'; // Produccion

export const HEADERS_API = () => {
  return {
    'Content-Type': 'application/json',
  }
}

// Crimes
export const getCrimes = async (municipality, year, month) => {
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/datamex/crimes`,
    {
      method: 'POST',
      headers: HEADERS_API(),
      body: JSON.stringify({ mun: municipality, month: month, year: year}),
    }
  )
  return await res.json()
}
