import { useState } from "react";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Tab, Tabs, Tooltip, useMediaQuery } from "@mui/material";
import { Dashboard as DashboardIcon, Map as MapIcon, ShowChart as ShowChartIcon } from '@mui/icons-material';
import Map from "components/Map";
import DataMex from "components/DataMex";
import { DashboardMap } from "components/Map/DashboardMap";

export default function PoliticianAdmin() {
  const userLS = JSON.parse(localStorage.getItem('LOAu'))
  const [value, setValue] = useState('mapasdashboard');
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [district, setDistrict] = useState(0)

  const handleChange = (event, newValue) => setValue(newValue)

  const handleChangeDistrict = (event) => setDistrict(event.target.value)

  return (<>
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="secondary tabs example">
        <Tab value="mapasdashboard"
          label={
            matchesMD ? "Mapas Dashboard" :
              <Tooltip title="Mapas Dashboard"><DashboardIcon /></Tooltip>
          }
        />
        <Tab value="electoraldemografico"
          label={
            matchesMD ? "Electorales / Demográficos" :
              <Tooltip title="Electorales / Demográficos"><MapIcon /></Tooltip>
          }
        />
        <Tab value="otherdata"
          label={
            matchesMD ? "Otras estadísticas" :
              <Tooltip title="Otras estadísticas"><ShowChartIcon /></Tooltip>
          }
        />
      </Tabs>
    </Box>
    
    <div style={{ marginTop: 20 }} role="tabpanel" hidden={value !== "mapasdashboard"} id={`simple-tabpanel-mapasdashboard`} aria-labelledby={`simple-tab-mapasdashboard`}>
      <DashboardMap user={userLS} />
    </div>

    <div style={{ marginTop: 20 }} role="tabpanel" hidden={value !== "electoraldemografico"} id={`simple-tabpanel-electoraldemografico`} aria-labelledby={`simple-tab-electoraldemografico`}>

      <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Distrito</InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={district}
              label="Distrito"
              onChange={handleChangeDistrict} >
              <MenuItem value={0}>Selecciona un Distrito</MenuItem>
              {userLS.district.map((district, index) => (
                <MenuItem key={index} value={district.district_id}>{district.district_id} - {district.name}</MenuItem>
              ))
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {district !== 0 ? <Map localDistrictId={district} /> : null}

    </div>
    <div style={{ marginTop: 20 }} role="tabpanel" hidden={value !== "otherdata"} id={`simple-tabpanel-otherdata`} aria-labelledby={`simple-tab-otherdata`}>
      <DataMex municipalities={userLS.municipalities} />
    </div>
  </>)
}