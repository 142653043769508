import { Autocomplete, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"

export const Filters = ({
  switchGeoData,
  handleVisibilityTypeSelect,
  year,
  handleYearSelect,
  demographicCategories,
  handleDemographicCategorySelect,
  demographicSubcategories,
  handleDemographicSubcategorySelect,
  demographicCategorySelected,
  demographicSubcategorySelected,
  handleSearch,
  isLoading
}) => {
  return (
    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Tipo de visibilidad</InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={switchGeoData}
            label="Tipo de visibilidad"
            onChange={handleVisibilityTypeSelect} >
            <MenuItem value={1}>Distrito Electoral</MenuItem>
            <MenuItem value={2}>Secciones</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Año</InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={year}
            label="Año"
            onChange={handleYearSelect} >
            <MenuItem value="2020">2020</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          size="small"
          disablePortal
          options={demographicCategories !== undefined && demographicCategories !== null ? demographicCategories.data : []}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Categoria demografica" />}
          onChange={(event, newValue) => handleDemographicCategorySelect(newValue)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {demographicSubcategories !== undefined && demographicSubcategories !== null ?
          <Autocomplete
            size="small"
            disablePortal
            options={demographicSubcategories}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Subcategoria demografica" />}
            onChange={(event, newValue) => handleDemographicSubcategorySelect(newValue)}
          /> : null
        }
      </Grid>
      {demographicCategorySelected !== null && demographicSubcategorySelected !== null ?
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button variant="contained" onClick={() => handleSearch()} disabled={isLoading}>
            {isLoading ? <CircularProgress color="secondary" size={25}/> : "Buscar" }
          </Button>
        </Grid> : null
      }
    </Grid>
  )
}