import { useEffect, useState } from "react";
import { Button, CircularProgress, Grid} from "@mui/material"
import { useDropzone } from "react-dropzone";
import { useMutation } from "@tanstack/react-query";
import { saveLibraryFiles } from "routes/Library/services/api";
import { useTranslation } from "react-i18next";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog";
import { detectTypeFile, MBOfFile } from "helpers/library"
import { CardFileUpload } from "components/Card/CardFileUpload";
import { DragAndDropZone } from "./modules/DragAndDropZone";
import { PoliticianGroup } from "./modules/PoliticianGroup";
import { FoldersGroup } from "./modules/FoldersGroup";

export const Upload = ({ open, close, libraryID, folders, politicians, reportAction }) => {
  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone()
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [fileIndexToAssign, setFileIndexToAssign] = useState(null)
  const [politiciansFilter, setPoliticiansFilter] = useState([])

  useEffect(() => {
    if (politicians.length > 0) { setPoliticiansFilter(politicians) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [politicians])

  const handleClose = () => {
    setFiles([])
    setFileIndexToAssign(null)
    close()
  }

  const mutation = useMutation(saveLibraryFiles, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al guardar los datos', 'error')
      } else {
        setFiles([]);
        reportAction(true, 'Acción realizada correctamente', 'success')
      }
    }
  })

  const handleAdd = () => {
    const formData = new FormData()
    for (var x = 0; x < files.length; x++) { formData.append('filesupload[]', files[x].fileB) }
    formData.append('data', JSON.stringify(files))
    setIsLoading(true)
    mutation.mutate({ id: libraryID, params: formData })
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      let newFiles = [...files]
      acceptedFiles.forEach(file => newFiles.push({ fileB: file,  politicians: [], categoriesFolderPolitician: [] }))
      setFiles(newFiles)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  const handleDeleteAceptedFile = (index) => {
    let newAcceptedFiles = [...files]
    newAcceptedFiles.splice(index, 1)
    setFiles(newAcceptedFiles)
    setFileIndexToAssign(null)
  }

  //
  const handleSetFileIndexToAssign = (index) => {
    setFileIndexToAssign(index)
  }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>Cancelar</Button>
      <Button variant="contained" color="info" onClick={() => handleAdd()} disabled={files.length === 0}>Subir Archivos</Button>
    </> : <CircularProgress />}
  </>)

  return (<>
    <FlexibleWidthDialog open={open} maxWidth="lg" title="Subir archivos" actions={<Actions />}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DragAndDropZone getRootProps={getRootProps} getInputProps={getInputProps} />
          <Grid container spacing={2}>
            {files.map((file, index) => (
              <Grid item xs={12} key={index} sx={{ backgroundColor: fileIndexToAssign === index ? "#d4d4d4" : "#ffffff" }}>
                <CardFileUpload
                  imgFile={detectTypeFile(file.fileB)} altImageFile={file.fileB.path}
                  pathFile={file.fileB.path} mbSizeFile={MBOfFile(file.fileB)}
                  onClickAction={() => handleSetFileIndexToAssign(index)}
                  onDeleteAction={() => handleDeleteAceptedFile(index)} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          {fileIndexToAssign !== null && files.length > 0 ? <>
            <h4>Asignacion de categoría</h4>
            <FoldersGroup
              folders={folders}
              files={files} setFiles={setFiles}
              fileIndexToAssign={fileIndexToAssign} />
            <h4>Asignación de diputado</h4>
            <PoliticianGroup
              politicians={politicians}
              politiciansFilter={politiciansFilter} setPoliticiansFilter={setPoliticiansFilter}
              files={files} setFiles={setFiles}
              fileIndexToAssign={fileIndexToAssign} />
          </> : null}
        </Grid>
      </Grid>
    </FlexibleWidthDialog>
  </>)
}