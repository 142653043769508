import { InputNumberBuilder, InputSwitchBuilder, InputTextAreaBuilder, InputTextBuilder } from "components/InputBuilder";

export default function SharedForm(
  { dataInstance, handleOnChangeData, handleChangeBoolean, register, errors }
) {
  return (<>
    <InputTextBuilder
      label="ID de distrito" id="district_id" name="district_id" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputTextBuilder
      label="Nombre" id="name" name="name" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputNumberBuilder
      label="Total de representados" id="nominal" name="nominal" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputTextBuilder
      label="No. romano" id="roman_number" name="roman_number" required={false}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    {/* <InputTextAreaBuilder
      label="Secciones" id="sections" name="sections" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} /> */}
    <InputSwitchBuilder
      label="Distrito Activo" id="active" name="active" required={true}
      instance={dataInstance} handleOnChangeData={handleChangeBoolean}
      register={register} errors={errors} />
  </>)
}