/* import './App.css'; */
import { useContext } from 'react';
import ThemeMUIProvider from 'theme';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from 'routes/Login';
import Layout from 'components/Layout';
import Dashboard from 'routes/Dashboard';
import Error from 'components/Error/Error';
import { AuthContext } from 'context/AuthContext';
import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next";
import { enus, esmx } from "helpers/languages"
import Commission from 'routes/Comissions';
import CommissionCharge from 'routes/ComissionsCharge';
import PoliticalParty from 'routes/PoliticalParty';
import District from 'routes/Distric';
import Legislature from './routes/Legislature';
import Politician from 'routes/Politician';
import User from 'routes/User';
/* import Library from 'routes/Library'; */
import Municipality from 'routes/Municipality';
import Ranking from 'routes/Ranking';
import PoliticianLibrary from 'routes/PoliticianLibrary';
import PoliticianAssistance from 'routes/PoliticianAssistance';
import LegislaturePeriod from './routes/LegislaturePeriod';
import LibraryV2 from './routes/LibraryV2';

const ProtectRoute = ({ children }) => {
  const { isAuth } = useContext(AuthContext)
  console.log("ProtectRoute", isAuth)
  if (!isAuth || isAuth === null) {
    return <Navigate to="/login" />
  }
  return children
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      es: esmx,
      en: enus
    },
    lng: "es",
    fallbackLng: "es",
    interpolation: { escapeValue: false }
  });

function App() {
  return (
   <ThemeMUIProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/login' element={<Login />} />          
          <Route path='/admin' element={<ProtectRoute><Layout /></ProtectRoute>}>
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='commissions' element={<Commission />} />
            <Route path='commission-charges' element={<CommissionCharge />} />
            <Route path='political-parties' element={<PoliticalParty />} />
            <Route path='districts' element={<District />} />
            <Route path='legislatures' element={<Legislature />} />
            <Route path='legislature-periods' element={<LegislaturePeriod />} />
            <Route path='politicians' element={<Politician />} />
            <Route path='politicians-ranking' element={<Ranking />} />
            <Route path='politicians-assistance' element={<PoliticianAssistance />} />
            <Route path='users' element={<User />} />
            {/* <Route path='library' element={<Library />} /> */}
            <Route path='library-v2' element={<LibraryV2 />} />
            <Route path='politician-library' element={<PoliticianLibrary />} />
            <Route path='municipalities' element={<Municipality />} />
          </Route>
          <Route path='*' element={<Error />} />
        </Routes>
      </BrowserRouter>
   </ThemeMUIProvider> 
  )
}

export default App;
