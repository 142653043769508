import { IconButton } from "@mui/material"
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material"

export const handleSetInstance = (id, data, setData) => {
  const instance = data.data.filter(item => (item._id === id))
  setData(instance[0])
}

export const getActive = (id, data) => {
  const instance = data.data.filter(item => (item._id === id))
  if (instance.length === 0)
    return false
  return instance[0].active
}

export const MUIDatatableCustomBodyRenderActions = (
  open, 
  clickMenuActions
) =>
  (value, tableMeta, updateValue) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton id="demo-customized-button" aria-haspopup="true" variant="contained"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => clickMenuActions(event, value)}
        sx={{ marginRight: 1 }}>
        <MoreHorizIcon />
      </IconButton>
    </div>
  )

export const MUIDatatableOptions = (
  t, 
  customBodyRenderActions,
  open,
  handleClickMenuActions
) =>
  [
    {
      name: "name", label: t('legislatureperiods_table_name_column'),
      options: {
        filter: true, sort: true,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>
      }
    },
    {
      name: "identifier", label: t('legislatureperiods_table_identifier_column'),
      options: {
        filter: true, sort: true,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>
      }
    },
    {
      name: "_id", label: t('legislatureperiods_table_actions_column'),
      options: {
        filter: false, sort: false, download: false,
        customHeadLabelRender: (columnMeta) => <b>{columnMeta.label}</b>,
        customBodyRender: customBodyRenderActions(open, handleClickMenuActions)
      }
    },
  ];