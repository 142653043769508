import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Chart from "react-apexcharts";
import { getCrimes } from "./services/api";
import { Typography } from "@mui/material";

export default function Crimes({ municipality, year, month }) {
  const { data } = useQuery(['thiefsData', municipality, year, month], () => getCrimes(municipality, year, month), { refetchOnWindowFocus: false })
  const [chartData, setChartData] = useState(null)
  const [noData, setNoData] = useState(false)

  useEffect(() => {
    setNoData(false)
    setChartData(null)
    if (data !== undefined && data !== null) buildPoliticiansChosenByOptionsChart(data.data)
  }, [data])

  const buildPoliticiansChosenByOptionsChart = (data) => {
    if (data === undefined || data === null) {
      setChartData(null)
      return
    }
    if (data.length === 0) {
      setNoData(true)
      return
    }

    const data_series = data.map((item) => {
      return { x: item.name, y: item.value }
    })

    let tmp = {
      series: [{ data: data_series }],
      options: {
        legend: { show: false },
        chart: {
          toolbar: {
            export: {
              csv: { filename: "Tipos de delito" },
              svg: { filename: "Tipos de delito" },
              png: { filename: "Tipos de delito" }
            }
          },
          type: 'treemap'
        },
        dataLabels: {
          enabled: true,
          style: { fontSize: '12px' },
          formatter: function (text, op) { return [text, op.value] },
          offsetY: -4
        },
        plotOptions: {
          treemap: {
            enableShades: true,
            shadeIntensity: 0.5,
            reverseNegativeShade: true,
            colorScale: {
              ranges: [
                {
                  from: data_series[data_series.length - 1].y,
                  to: data_series[0].y,
                  color: '#CD363A'
                }
              ]
            }
          }
        }
      },
    }
    setChartData(tmp)
  }

  return (
    <div>
      <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight: "bold" }}>Tipos de Delito</Typography>
      {chartData !== null ?
        <Chart
          series={chartData.series}
          options={chartData.options}
          type="treemap"
          width="98%" />
        : null}
      {noData ? <Typography variant="body1" gutterBottom component="div">No hay datos</Typography> : null}
    </div>
  )
}   