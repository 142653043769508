import { useState } from "react";
import { Toolbar, IconButton, AppBar, Menu, MenuItem, Divider, Paper, useTheme } from "@mui/material";
import { AccountCircle as AccountCircleIcon, Logout as LogoutIcon } from "@mui/icons-material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { AuthContext } from "context/AuthContext";
import { useContext } from "react";
import { useLocation/* , useNavigate */ } from "react-router-dom";
import { MenuItems } from "../Drawer/MenuData"
import { useEffect } from "react";

const Header = ({ actionMenu }) => {
  //const navigate = useNavigate()
  const theme = useTheme();
  const location = useLocation()
  const usr = JSON.parse(localStorage.getItem('LOAu'))
  const [dataPage, setDataPage] = useState(null)

  const drawerWidth = usr.name !== undefined ? "251px" : "0px";

  const { logOut } = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleSetTitleIconPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const handleSetTitleIconPage = () => {
    const data = MenuItems()
    const findData = data.find((item) => item.to === location.pathname)
    setDataPage(findData)
    console.log("handleSetTitleIconPage", findData)
  }

  return (<>
    <AppBar position="fixed" 
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          width: `calc(100% - ${drawerWidth}) !important`,
          marginLeft: drawerWidth,
        },
        //backgroundColor: "transparent !important",
        //boxShadow: "none !important",
        backdropFilter: "blur(16px) saturate(180%)",
        "-webkit-backdrop-filter": "blur(16px) saturate(180%)",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
      })
      }
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={actionMenu}
          sx={(theme) => ({
            marginLeft: `${theme.spacing(0.5)} !important`,
            marginRight: `${theme.spacing(2)} !important`,
            [theme.breakpoints.up('md')]: {
              display: 'none !important',
            }
          })}
        >
          <MenuIcon />
        </IconButton>
        <div style={{ flexGrow: 1 }}>
          {dataPage === null || dataPage === undefined ? "InteliQR Menu" :
            <div style={{ display: "flex", alignItems: "center" }}>
              <Paper elevation={3} sx={{ padding: 1, display: "flex", marginRight: 1, borderRadius: "50px" }}>
                {dataPage.icon}
              </Paper>
              <div style={{ marginRight: 8, fontWeight: 700, fontSize: 20, textShadow: "#000 1px 0 10px" }}>{dataPage.text}</div>
            </div>
          }
        </div>
        <div>
          <IconButton
            onClick={handleClick}
            size="small"
            color="inherit"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <AccountCircleIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 12,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {/* <MenuItem>
        Profile
      </MenuItem>
      <Divider /> */}
      <MenuItem onClick={logOut}>
        <LogoutIcon /> &nbsp; Cerrar sesión
      </MenuItem>
    </Menu>
  </>);
};

export default Header;