import { API_BASE_URL, HEADERS_WITH_TOKEN } from "services/api/BaseUrl"

// User
export const getAll = () =>
  fetch(
    `${API_BASE_URL}/users`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt'))
    }
  )
    .then(res => res.json())

export const save = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/users`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const edit = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/users/${id}`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const remove = async (id) => {
  const res = await fetch(
    `${API_BASE_URL}/users/${id}`,
    {
      method: 'DELETE',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt'))
    }
  )
  return await res.json()
}

export const updateActive = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/users/update-active/${id}`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt')),
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

//Update permissions
export const editPermissions = async (data) => {
  const { id, permissions } = data
  const res = await fetch(
    `${API_BASE_URL}/users/permissions/${id}`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt')),
      body: JSON.stringify({ permissions: permissions })
    }
  )
  return await res.json()
}


// Role
export const getAllRoles = () =>
  fetch(
    `${API_BASE_URL}/roles`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt'))
    }
  )
    .then(res => res.json())

// Permission
export const getAllPermissions = () =>
  fetch(
    `${API_BASE_URL}/permissions`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt'))
    }
  )
    .then(res => res.json())