import React/* , { useContext } */ from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { MenuItems } from "./MenuData";

const Menu = ({ action }) => {
  /* const { logOut, globalUser } = useContext(AuthContext) */
  const menuItems = MenuItems()
  const location = useLocation()
  const usr = JSON.parse(localStorage.getItem('LOAu'))
  console.log(usr)
  return (<>
    {usr !== null ?
      <Box
        //className={classes.toolbar}
        sx={(theme) => ({ 
          width: 251,
          ...theme.mixins.toolbar
        })}
        role="presentation"
      //onClick={() => action()}
      //onKeyDown={() => action()}
      >
        <div style={{ textAlign: "center", backgroundColor: "#ffffff", position: "sticky", top: 0, zIndex: 1 }}>
          <img src="/images/logo_observatorio.png" style={{ width: "100%" }} alt="logo-img" />
        </div>
        <List>
          {menuItems.map((item, index) => {
            if (item.type === "menu" && item.role.includes(usr.role.identifier)) {
              return (
                <Link to={item.to} key={`${index}-${item.text}`} style={{ textDecoration: 'none', color: '#000000' }}>
                  <ListItem button
                    onClick={() => action()}
                    sx={{
                      backgroundColor: (location.pathname === item.to) ? "#0268CA" : "#212145",
                      paddingTop: 0.5,
                      paddingBottom: 0.5,
                      '&:hover': {
                        backgroundColor: (location.pathname === item.to) ? "#0268CA" : "#212145",
                      }
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: (location.pathname === item.to) ? "#ffffff" : "#ffffff"
                      }}
                    >{item.icon}</ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{
                        color: (location.pathname === item.to) ? "#ffffff" : "#ffffff"
                      }}
                    />
                  </ListItem>
                </Link>
              )
            } else if (item.type === "divider") {
              return (
                <Divider key={index} color="#ffffff" sx={{ marginTop: 2, marginBottom: 2 }} />
              )
            } else { return null }
          })}
        </List>
      </Box>
      : null}
  </>);
};
export default Menu;
