import * as Yup from "yup"

export const SchemaValidationEdit = (t) => Yup.object().shape({
  assistances: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  commisions_assistances: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  full_participation: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  initiatives_presented: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  transparency: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  initiatives_approved: Yup.number().typeError("Campo requerido").required("Campo requerido"),
  total: Yup.number().typeError("Campo requerido").required("Campo requerido")
})