import { InputFileImageBuilder, InputSwitchBuilder, InputTextBuilder } from "components/InputBuilder";

export default function SharedForm(
  { type, dataInstance, handleOnChangeData, handleChangeBoolean, rhfSetValue, register, errors }
) {
  return (<>
    {type === "edit" ?
      <InputFileImageBuilder
        label="Logo" id="logo_file" name="logo_file" required={true}
        handleOnChangeData={handleOnChangeData}
        register={register} errors={errors} setValue={rhfSetValue} />
      :
      <InputFileImageBuilder
        label="Logo" id="logo" name="logo" required={true}
        handleOnChangeData={handleOnChangeData}
        register={register} errors={errors} setValue={rhfSetValue} />
    }
    <InputTextBuilder
      label="Nombre" id="name" name="name" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    {/*  <InputTextBuilder
      label="Logo" id="logo" name="logo" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} /> */}
    <InputTextBuilder
      label="Abreviation" id="abbreviation" name="abbreviation" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputTextBuilder
      label="Fecha de fundación" id="foundation_year" name="foundation_year" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputTextBuilder
      label="Ideología" id="ideology" name="ideology" required={true}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputTextBuilder
      label="Página de facebook" id="facebook_account" name="facebook_account" required={false}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputTextBuilder
      label="Página de Twitter" id="twitter_account" name="twitter_account" required={false}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputTextBuilder
      label="Página de instagram" id="instagram_account" name="instagram_account" required={false}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputTextBuilder
      label="Página web" id="website" name="website" required={false}
      instance={dataInstance} handleOnChangeData={handleOnChangeData}
      register={register} errors={errors} />
    <InputSwitchBuilder
      label="Activo" id="active" name="active" required={true}
      instance={dataInstance} handleOnChangeData={handleChangeBoolean}
      register={register} errors={errors} />
  </>)
}