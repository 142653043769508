import { Diversity3 as Diversity3Icon, Groups as GroupsIcon, Map as MapIcon } from "@mui/icons-material";
import { Grid, Paper, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getAll } from "../services/api";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";

export default function Administrator() {
  const { data } = useQuery(['dashboardData'], getAll, { refetchOnWindowFocus: false })
  const [dashData, setDashData] = useState(null)
  const [politiciansChosenByOptionsChart, setPoliticiansChosenByOptionsChart] = useState(null)

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setDashData(data.data)
      buildPoliticiansChosenByOptionsChart(data.data.politiciansByChoosenBy)
    }
  }, [data])

  const buildPoliticiansChosenByOptionsChart = (data) => {
    if (data === undefined || data === null) {
      setPoliticiansChosenByOptionsChart(null)
      return
    }
    let tmp = {
      series: [45, 30],
      options: {
        chart: { type: 'pie' },
        labels: ['Mayoria Relativa', 'Rep. Proporcional']
      },
    };

    for (let i = 0; i < data.length; i++) {
      tmp.series[i] = data[i].count
      tmp.options.labels[i] = data[i].name
    }

    setPoliticiansChosenByOptionsChart(tmp)
  }


  return (<>
    {dashData !== null && politiciansChosenByOptionsChart !== null ? <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Paper elevation={3} sx={{ borderRadius: 3, p: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <Typography variant="h6" component="div" sx={{ px: 2, fontSize: 16 }}>Diputados</Typography>
              <Typography variant="h6" component="div" sx={{ px: 2, fontWeight: "bold" }}>{dashData.politicians}</Typography>
            </div>
            <Diversity3Icon sx={{ fontSize: 50 }} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Paper elevation={3} sx={{ borderRadius: 3, p: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <Typography variant="h6" component="div" sx={{ px: 2, fontSize: 16 }}>Municipios</Typography>
              <Typography variant="h6" component="div" sx={{ px: 2, fontWeight: "bold" }}>{dashData.municipalities}</Typography>
            </div>
            <MapIcon sx={{ fontSize: 50 }} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Paper elevation={3} sx={{ borderRadius: 3, p: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <Typography variant="h6" component="div" sx={{ px: 2, fontSize: 16 }}>Distritos</Typography>
              <Typography variant="h6" component="div" sx={{ px: 2, fontWeight: "bold" }}>{dashData.districts}</Typography>
            </div>
            <MapIcon sx={{ fontSize: 50 }} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Paper elevation={3} sx={{ borderRadius: 3, p: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <Typography variant="h6" component="div" sx={{ px: 2, fontSize: 16 }}>Partidos políticos</Typography>
              <Typography variant="h6" component="div" sx={{ px: 2, fontWeight: "bold" }}>{dashData.politicalParty}</Typography>
            </div>
            <GroupsIcon sx={{ fontSize: 50 }} />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ borderRadius: 3, p: 1 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>Tipo de diputados</Typography>
            <Chart
              options={politiciansChosenByOptionsChart.options}
              series={politiciansChosenByOptionsChart.series}
              type="pie"
              width="100%"
            />
          </Paper>
        </Grid>
      </Grid>
    </> : null}
  </>)
}