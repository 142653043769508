import { useState } from "react";
import { FormControlLabel, Grid } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCategoriesByFolder, updateActive, updateOrder } from "./services/api";
import { SnackbarGlobal } from "components/Snackbar";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Android12Switch, StyledMenu } from "components/Styled";
import { useTranslation } from "react-i18next";
import { ActionFabButtonAdd, ActionsDialogPage, ActionsMenuPage } from "./ActionsPage";
import { ReactBeautifulDNDUpdateOrder } from "./routeHelpers";
import { DraggableRowActivateAccordionIcon, DraggableRowButtonMenu, DraggableRowDragIndicatorIcon, DraggableRowImage, DraggableRowText } from "components/Builder/DraggableRow";

export function Categories({ folder, active }) {
  const { t } = useTranslation();
  const [notification, setNotification] = useState({ open: false, message: "", severity: "" })
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: add, 2: edit, 3: delete
  const [instanceSelected, setInstanceSelected] = useState({})
  const [showSubcategories, setShowSubcategories] = useState(null)
  const queryClient = useQueryClient()
  const { data: categoriesByFolder } = useQuery(
    ['categoriesByFolder', folder], () => getCategoriesByFolder(folder),
    { refetchOnWindowFocus: false, enabled: folder !== null && folder !== undefined }
  )

  const handleResetActions = () => { setOpenAction(0) }
  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity })
    if (success) { handleResetActions(); queryClient.invalidateQueries('categoriesByFolder') }
  }

  const [anchorElMenuActions, setAnchorElMenuActions] = useState(null)
  const open = Boolean(anchorElMenuActions)
  const handleClickMenuActions = (event, item) => {
    setAnchorElMenuActions(event.currentTarget); setInstanceSelected(item);
  }
  const handleCloseMenuActions = () => setAnchorElMenuActions(null)
  const handleMenuItemClick = (option) => { setOpenAction(option); handleCloseMenuActions(); }

  /**
   * Mutation to execute function to order a category
   */
  const mutation = useMutation(updateOrder, {
    onSuccess: (data) => { if (data.status === "success") { handleWatchAction(true, 'Acción realizada correctamente', 'success') } }
  })
  const handlerOrder = (params) => mutation.mutate(params)
  const handleDrop = (droppedItem) => {
    const order = ReactBeautifulDNDUpdateOrder(categoriesByFolder.data, droppedItem)
    if (order === false) return
    handlerOrder({ value: order })
  }

  const mutationUpdateActive = useMutation(updateActive, {
    onSuccess: (data) => {
      if (data.status === "error") { handleWatchAction(false, 'Error al guardar los datos', 'error') }
      else { handleWatchAction(true, 'Acción realizada correctamente', 'success') }
    }
  })
  const handleUpdateActive = (item) => mutationUpdateActive.mutate({ id: item._id, params: { active: !item.active } })

  return (<>
    <Grid container spacing={0}>
      {categoriesByFolder !== undefined ?
        <Grid item xs={12}>
          {categoriesByFolder.data.length > 0 ?
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <Grid container spacing={0} className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
                    {categoriesByFolder.data.map((category, index) => (
                      <Draggable key={`${index}-${category._id}`} draggableId={category._id} index={index} isDragDisabled={showSubcategories !== null}>
                        {(provided) => (
                          <Grid ref={provided.innerRef} {...provided.draggableProps} container spacing={0} sx={{ padding: "10px 0px", alignItems: "center", borderTop: "1px solid rgba(224, 224, 224, 1)" }}>
                            <Grid item xs={1} {...provided.dragHandleProps}> <DraggableRowDragIndicatorIcon disabled={showSubcategories !== null} /> </Grid>
                            <Grid item xs={2}> <DraggableRowImage image={category.image} /> </Grid>
                            <Grid item xs={6}> <DraggableRowText text={category.name} /> </Grid>
                            <Grid item xs={3}>
                              <DraggableRowButtonMenu
                                open={open}
                                disabled={showSubcategories !== null}
                                action={(event) => handleClickMenuActions(event, category)} />
                              <FormControlLabel sx={{ marginRight: 0 }} control={<Android12Switch checked={category.active} disabled={showSubcategories !== null} onClick={() => handleUpdateActive(category)} />} />
                            </Grid>
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
            : <p>NO hay registros</p>
          }
        </Grid>
        : null}
    </Grid>
    {active !== null ? <ActionFabButtonAdd t={t} action={() => setOpenAction(1)} /> : null }
    <StyledMenu
      id={`demo-customized-menu`}
      MenuListProps={{ 'aria-labelledby': `demo-customized-button` }}
      anchorEl={anchorElMenuActions}
      open={open}
      onClose={handleCloseMenuActions} >
      <ActionsMenuPage t={t} action={handleMenuItemClick} />
    </StyledMenu>
    <ActionsDialogPage
      open={openAction}
      close={() => handleResetActions()}
      folder={folder}
      instance={instanceSelected}
      reportAction={handleWatchAction} />
    <SnackbarGlobal
      open={notification.open}
      close={() => setNotification({ open: false, message: "", severity: "" })}
      message={notification.message}
      severity={notification.severity} />
  </>)
}