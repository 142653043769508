import { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from '@tanstack/react-query'
import { SchemaValidationUpdate } from "../services/schemaValidation";
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { edit, getAllRoles } from "../services/api";
import { useTranslation } from "react-i18next";
import { isEmptyObject } from "helpers";
import SharedForm from "../modules/SharedForm";

export default function Edit({ open, close, instance, reportAction }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [dataInstance, setDataInstance] = useState({})
  let { reset, handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(SchemaValidationUpdate(t))
  })
  const { data: roleList } = useQuery(['rolesList'], getAllRoles, { refetchOnWindowFocus: false, enabled: open })
  const [roles, setRoles] = useState([])

  useEffect(() => {
    if (roleList) {
      const rolesTemp = roleList.data.map((item) => ({ value: item._id, label: item.name }))
      setRoles(rolesTemp)
    }
  }, [roleList])

  useEffect(() => {
    if (open) { 
      const instanceTemp = { ...instance }
      delete instanceTemp.password
      instanceTemp.role = instanceTemp.role._id
      setDataInstance(instanceTemp); reset(instanceTemp); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const mutation = useMutation(edit, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        reportAction(false, 'Error al guardar los datos', 'error')
      } else {
        reset(); setDataInstance({});
        reportAction(true, 'Acción realizada correctamente', 'success')
      }
    }
  })

  const handleEdit = (params) => {
    setIsLoading(true)
    let id = params._id
    delete params._id
    delete params.createdAt
    delete params.updatedAt
    delete params.deleted
    mutation.mutate({ id, params })
  }

  const handleClose = () => { reset(); setDataInstance({}); close() }

  const handleOnChangeData = (event) => {
    if (event.target.name.includes(".")) {
      const separate = event.target.name.split(".")
      setDataInstance({ ...dataInstance, [separate[0]]: { ...dataInstance[separate[0]], [separate[1]]: event.target.value } })
      return;
    }
    setDataInstance({ ...dataInstance, [event.target.name]: event.target.value })
  }

  const handleChangeBoolean = (name) => {
    setDataInstance({ ...dataInstance, [name]: !dataInstance[name] })
  }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>{t("button_text_cancel")}</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)} disabled={isEmptyObject(dataInstance)} >{t("button_text_edit")}</Button>
    </> : <CircularProgress />}
  </>)

  return (<>
    <FlexibleWidthDialog open={open} title={t('users_dialog_title_edit')} actions={<Actions />}>
      {roles.length > 0 ?
        <SharedForm
          dataInstance={dataInstance}
          roles={roles}
          handleOnChangeData={handleOnChangeData}
          handleChangeBoolean={handleChangeBoolean}
          register={register}
          errors={errors} />
        : null}
    </FlexibleWidthDialog>
  </>)
}