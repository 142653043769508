import { Grid, Tooltip, tooltipClasses, styled } from "@mui/material"
import { GroupAdd as GroupAddIcon, GroupRemove as GroupRemoveIcon } from '@mui/icons-material';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#212145"
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#212145",
    color: '#ffffff',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
  },
}));

export const ColorIndicatorBar = ({ colorInitial, colorEnd, textInitial, textEnd }) => {
  return (
    <Grid container spacing={1} sx={{ margin: "10px 0px", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
        <HtmlTooltip title={textInitial}> <GroupAddIcon /> </HtmlTooltip>
      </Grid>
      <Grid item xs={8}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{
            width: "100%",
            height: 12,
            backgroundImage: `linear-gradient(to right, ${colorInitial}, ${colorEnd})`
          }} />
        </div>
      </Grid>
      <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
        <HtmlTooltip title={textEnd} arrow> <GroupRemoveIcon /> </HtmlTooltip>
      </Grid>
    </Grid>
  )
}