import { useState } from "react"
import { Grid } from "@mui/material"
import { DistrictSelect } from "./components/DistrictSelect"
import { listMapNumbers } from "./services/listMapNumbers"
import { MapNumbers } from "./components/MapNumbers"
import { Map4 } from "./modules/Map4"
import { Map5 } from "./modules/Map5"
import { Map7 } from "./modules/Map7"
import { Map8 } from "./modules/Map8"
import { Map11 } from "./modules/Map11"
import { Map10 } from "./modules/Map10"
import { Map9 } from "./modules/Map9"
import { Map6 } from "./modules/Map6"

export const DashboardMap = ({ user }) => {
  const [district, setDistrict] = useState(0)
  const [mapNumber, setMapNumber] = useState(0)

  const handleChangeDistrict = (event) => setDistrict(event.target.value)
  const handleChangeMapNumber = (event) => setMapNumber(event.target.value)

  return (<>
    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
      <Grid item xs={12} md={6}>
        <DistrictSelect districtList={user.district} district={district} onChange={handleChangeDistrict} />
      </Grid>
      {district !== 0 ?
        <Grid item xs={12} md={6}>
          <MapNumbers listMapNumbers={listMapNumbers} mapNumber={mapNumber} onChange={handleChangeMapNumber} />
        </Grid>
        : null}
      {mapNumber !== 0 ?
        <Grid item xs={12}>
          <b>Descripción del mapa: </b> {listMapNumbers.find(map => map.id === mapNumber).description}
        </Grid>
        : null}
    </Grid>

    <div>
      {mapNumber === 4 ? <Map4 localDistrictId={district}/> : null}
      {mapNumber === 5 ? <Map5 localDistrictId={district}/> : null}
      {mapNumber === 6 ? <Map6 localDistrictId={district} politicianParty={user.politician_party.abbreviation}/> : null}
      {mapNumber === 7 ? <Map7 localDistrictId={district}/> : null}
      {mapNumber === 8 ? <Map8 localDistrictId={district}/> : null}
      {mapNumber === 9 ? <Map9 localDistrictId={district} politicianParty={user.politician_party.abbreviation}/> : null}
      {mapNumber === 10 ? <Map10 localDistrictId={district} politicianParty={user.politician_party.abbreviation}/> : null}
      {mapNumber === 11 ? <Map11 localDistrictId={district}/> : null}
    </div>

  </>)
}