import { useEffect, useState } from "react"
import { Fab, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { CloudUpload as CloudUploadIcon, Sort as SortIcon, Apps as AppsIcon } from "@mui/icons-material";
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getAll as getAllPolitician } from "../../Politician/services/api";
import { getAll as getAllFolders } from "../Folders/services/api";
import { getFilesByFilter } from "./services/api";
import { SnackbarGlobal } from "components/Snackbar";
import { Upload } from "./Upload";
import { NoFiles } from "./modules";
import { CardDocument } from "components/Card/CardDocument";
import { Viewer } from "./Viewer";
import { Filters } from "./modules/Filters";
import { RenderOptionMenusCardDocument } from "./helpers";
import { Edit } from "./Edit";

export default function Files({ libraryID }) {
  const [notification, setNotification] = useState({ open: false, message: "", severity: "" })
  const { data: politicianList } = useQuery(['politicianList'], getAllPolitician, { refetchOnWindowFocus: false })
  const [politicians, setPoliticians] = useState([])
  const { data: foldersList } = useQuery(['foldersList'], getAllFolders, { refetchOnWindowFocus: false })
  const [folders, setFolders] = useState([])
  const [filesComp, setFilesComp] = useState([])
  const [filesFilter, setFilesFilter] = useState([])

  const [politicianFilter, setPoliticianFilter] = useState([])
  const [folderCategoryFilter, setCategoryFolderFilter] = useState([])

  const queryClient = useQueryClient()
  const { data: filesList } = useQuery(
    ['filesList', {
      id: libraryID,
      params: { filters: { politicians: politicianFilter, categoriesFolderPolitician: folderCategoryFilter } }
    }],
    () => getFilesByFilter({
      id: libraryID,
      params: { filters: { politicians: politicianFilter, categoriesFolderPolitician: folderCategoryFilter } }
    }),
    { refetchOnWindowFocus: false, enabled: politicianFilter.length > 0 && folderCategoryFilter.length > 0 }
  )
  const [openAction, setOpenAction] = useState(0) // 0: close, 1: upload, 2: edit, 3: delete
  const [typeShow, setTypeShow] = useState("grid") // list, grid
  const [itemID, setItemID] = useState(null)

  useEffect(() => {
    if (filesList !== undefined && filesList !== null && filesList !== "") {
      if (filesList.data !== undefined && filesList.data !== null && filesList.data !== "") {
        if (filesList.data.files !== undefined && filesList.data.files !== null && filesList.data.files !== "") {
          setFilesComp(filesList.data.files)
          setFilesFilter(filesList.data.files)
        } else {
          setFilesComp([])
          setFilesFilter([])
        }
      } else {
        setFilesComp([])
        setFilesFilter([])
      }
    }
  }, [filesList])

  useEffect(() => { setFilesFilter(filesComp) }, [filesComp])

  /* Consts and function for menu card document */
  const [anchorElMenuCardDoc, setAnchorElMenuCardDoc] = useState(null);
  const openMenuCardDoc = Boolean(anchorElMenuCardDoc);
  const handleClickMenuCardDoc = (event) => setAnchorElMenuCardDoc(event.currentTarget)
  const handleCloseMenuCardDoc = () => setAnchorElMenuCardDoc(null)

  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity })
    setOpenAction(0)
    queryClient.invalidateQueries(['filesList'])
  }

  useEffect(() => {
    if (politicianList !== undefined && politicianList !== null && politicianList !== "") {
      let politician = politicianList.data.map((politician) => {
        return { value: politician._id, label: politician.full_name }
      })
      setPoliticians(politician)
    }
  }, [politicianList])

  useEffect(() => {
    if (foldersList !== undefined && foldersList !== null && foldersList !== "") {
      setFolders(foldersList.data)
    }
  }, [foldersList])

  return (<>
    <Paper sx={{ padding: "10px 16px", marginBottom: 3, position: "sticky", top: "60px", backgroundColor: "#ffffff", zIndex: 2, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "10px", }}>
      <Grid container spacing={0} >
        <Grid item xs={10}> <Typography variant="h6" component="div" noWrap> Documentos </Typography> </Grid>
        <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }}>
          <Tooltip title="Agregar archivos"> <Fab color="primary" size="small" onClick={() => setOpenAction(1)}><CloudUploadIcon /></Fab> </Tooltip>
        </Grid>
      </Grid>
    </Paper>
    <Grid container spacing={0} style={{ display: "flex", justifyContent: "end", marginBottom: 16 }}>
      {/* <Grid item xs={12} md={10}> <Search onChange={handleFilter} /> </Grid> */}
      <Grid item xs={12} md={10} style={{ display: "flex", justifyContent: "end" }}>
        <Filters folders={folders} setCategoryFolderPolitician={setCategoryFolderFilter} politicians={politicians} setPoliticianFilter={setPoliticianFilter} />
      </Grid>
      <Grid item xs={12} md={2} style={{ display: "flex", justifyContent: "end" }}>
        {typeShow === "grid" ? <Tooltip title="Lista"> <IconButton onClick={() => setTypeShow("list")}><SortIcon /></IconButton> </Tooltip>
          : <Tooltip title="Cuadrícula"> <IconButton onClick={() => setTypeShow("grid")}><AppsIcon /></IconButton> </Tooltip>}
      </Grid>
    </Grid>
    {filesComp.length > 0 ? <>
      <Grid container spacing={1}>
        {filesFilter.map((file, index) => (
          <Grid item xs={12} sm={typeShow === "grid" ? 4 : 12} key={index}>
            <CardDocument
              file={file}
              typeShow={typeShow}
              selectedItemID={setItemID}
              onClickActionsOptions={handleClickMenuCardDoc}
              openActionsOptions={openMenuCardDoc}
              viewer={<Viewer {...file} />} />
          </Grid>
        ))}
      </Grid>
    </> : <NoFiles />}
    <Upload
      open={openAction === 1}
      close={() => setOpenAction(0)}
      libraryID={libraryID}
      folders={folders}
      politicians={politicians}
      reportAction={handleWatchAction} />
    {itemID !== null ? <>
      <Edit
        open={openAction === 2}
        close={() => setOpenAction(0)}
        instance={itemID}
        folders={folders}
        politicians={politicians}
        reportAction={handleWatchAction} />
    </> : null}
    <RenderOptionMenusCardDocument
      anchor={anchorElMenuCardDoc}
      id="carddoc-menu"
      open={openMenuCardDoc}
      close={handleCloseMenuCardDoc}
      action={setOpenAction} />
    <SnackbarGlobal
      open={notification.open}
      close={() => setNotification({ open: false, message: "", severity: "" })}
      message={notification.message}
      severity={notification.severity} />
  </>)
}