import { Checkbox, CircularProgress, FormControlLabel, Grid, Typography } from "@mui/material";
import { InputFileImageBuilder, InputSelectBuilder, InputSwitchBuilder, InputTextBuilder, InputTextMaskBuilder } from "components/InputBuilder";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase/firebase"
import { useEffect, useState } from "react";

export default function SharedForm(
  { typeAction, dataInstance, politicalParties, districts, handleOnChangeData, handleChangeBoolean, register, errors }
) {
  const [showSocialNetworks, setShowSocialNetworks] = useState({
    facebook: false, twitter: false, instagram: false, website: false
  })
  const [loadingPhoto, setLoadingPhoto] = useState(true)
  const [currentPhoto, setCurrentPhoto] = useState("")
  const [loadingRadiography, setLoadingRadiography] = useState(true)
  const [currentRadiography, setCurrentRadiography] = useState("")

  useEffect(() => {
    console.log(dataInstance)
    if (typeAction === "edit") {
      downloadImageFromFirebasePhoto(dataInstance.photo)
      downloadImageFromFirebaseRadiography(dataInstance.radiography)
    }
    setShowSocialNetworks({
      facebook: dataInstance.facebook_account.page !== "" ? true : false,
      twitter: dataInstance.twitter_account.page !== "" ? true : false,
      instagram: dataInstance.instagram_account.page !== "" ? true : false,
      website: dataInstance.website !== "" ? true : false
    }) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleShowSocialNetwork = (name) => setShowSocialNetworks({ ...showSocialNetworks, [name]: !showSocialNetworks[name] })

  const downloadImageFromFirebasePhoto = async (data) => {
    if (data !== "") {
      getDownloadURL(ref(storage, data))
        .then((url) => {
          setCurrentPhoto(url)
          setLoadingPhoto(false)
        }
        )
        .catch((error) => {
          console.log(error)
        }
        )
    }
  }

  const downloadImageFromFirebaseRadiography = async (data) => {
    if (data !== "" && data !== undefined) {
      getDownloadURL(ref(storage, data))
        .then((url) => {
          setCurrentRadiography(url)
          setLoadingRadiography(false)
        }
        )
        .catch((error) => {
          console.log(error)
        }
        )
    } else {
      setLoadingRadiography(false)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          {typeAction === "edit" ?
            <Grid item xs={12} md={4}>
              {loadingPhoto ? <CircularProgress /> : <img src={currentPhoto} style={{ width: "100%" }} alt="imagen politician" />}
            </Grid>
            : null}
          <Grid item xs={12} md={typeAction === "edit" ? 8 : 12} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight: "bold", marginBottom: 2, paddingBottom: 2, borderBottom: "1px solid rgba(0,0,0,.1)", width: "100%" }}>Información Personal</Typography>
          </Grid>
        </Grid>
        {typeAction === "add" ?
          <InputFileImageBuilder
            label="Foto" id="photo" name="photo" required={true}
            instance={dataInstance} handleOnChangeData={handleOnChangeData}
            register={register} errors={errors} />
          :
          <InputFileImageBuilder
            label="Foto" id="photo_file" name="photo_file" required={true}
            instance={dataInstance} handleOnChangeData={handleOnChangeData}
            register={register} errors={errors} />
        }
        <InputTextBuilder
          label="Nombre" id="full_name" name="full_name" required={true}
          instance={dataInstance} handleOnChangeData={handleOnChangeData}
          register={register} errors={errors} />
        <InputTextBuilder
          label="Fecha de Nacimiento" id="birthday" name="birthday" required={true} mask="0000/00/00"
          instance={dataInstance} handleOnChangeData={handleOnChangeData}
          register={register} errors={errors} type="date" />
        <InputSelectBuilder
          label="Género" id="gender" name="gender" required={false}
          instance={dataInstance} handleOnChangeData={handleOnChangeData}
          register={register} errors={errors}
          options={[
            { value: "HOMBRE", label: "Hombre" },
            { value: "MUJER", label: "Mujer" },
          ]} />
        <InputSelectBuilder
          label="Nivel de Estudios" id="academic_degree.degree" name="academic_degree.degree" required={false}
          instance={dataInstance} handleOnChangeData={handleOnChangeData}
          register={register} errors={errors}
          options={[
            { value: "Sin Estadio", label: "Sin Estadio" },
            { value: "Primaria", label: "Primaria" },
            { value: "Secundaria", label: "Secundaria" },
            { value: "Bachillerato", label: "Bachillerato" },
            { value: "Licenciatura", label: "Licenciatura" },
            { value: "Maestria", label: "Maestria" },
            { value: "Doctorado", label: "Doctorado" }
          ]} />
        <InputTextBuilder
          label="Último Cargo" id="last_job" name="last_job" required={false}
          instance={dataInstance} handleOnChangeData={handleOnChangeData}
          register={register} errors={errors} />
        <InputTextBuilder
          label="Pasatiempos" id="hobbies" name="hobbies" required={false}
          instance={dataInstance} handleOnChangeData={handleOnChangeData}
          register={register} errors={errors} />

        <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight: "bold", marginBottom: 2, paddingBottom: 2, borderBottom: "1px solid rgba(0,0,0,.1)" }}>Radiografía</Typography>
        
        {typeAction === "edit" ? <>
          {loadingRadiography ? <CircularProgress /> : currentRadiography !== "" ? <img src={currentRadiography} style={{ width: "100%" }} alt="imagen politician radiography" /> : "No hay radiografía"}
        </> : null}

        {typeAction === "add" ?
          <InputFileImageBuilder
            label="Imagen" id="radiography" name="radiography" required={true}
            instance={dataInstance} handleOnChangeData={handleOnChangeData}
            register={register} errors={errors} />
          :
          <InputFileImageBuilder
            label="Imagen" id="radiography_file" name="radiography_file" required={true}
            instance={dataInstance} handleOnChangeData={handleOnChangeData}
            register={register} errors={errors} />
        }

      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight: "bold", marginBottom: 2, paddingBottom: 2, borderBottom: "1px solid rgba(0,0,0,.1)" }}>Redes Sociales</Typography>
        <div>
          <FormControlLabel control={
            <Checkbox checked={showSocialNetworks.facebook} onClick={() => handleShowSocialNetwork("facebook")} />
          } label="¿Tienes Facebook?" />
          {showSocialNetworks.facebook ? <>
            <InputTextBuilder
              label="FanPage " id="facebook_account.page" name="facebook_account.page" required={true}
              instance={dataInstance} handleOnChangeData={handleOnChangeData}
              register={register} errors={errors} />
            <InputTextBuilder
              label="Seguidores" id="facebook_account.followers" name="facebook_account.followers" required={true}
              instance={dataInstance} handleOnChangeData={handleOnChangeData}
              register={register} errors={errors} />
          </> : null}
        </div>
        <div>
          <FormControlLabel control={
            <Checkbox checked={showSocialNetworks.twitter} onClick={() => handleShowSocialNetwork("twitter")} />
          } label="¿Tienes Twitter?" />
          {showSocialNetworks.twitter ? <>
            <InputTextBuilder
              label="FanPage " id="twitter_account.page" name="twitter_account.page" required={true}
              instance={dataInstance} handleOnChangeData={handleOnChangeData}
              register={register} errors={errors} />
            <InputTextBuilder
              label="Seguidores" id="twitter_account.followers" name="twitter_account.followers" required={true}
              instance={dataInstance} handleOnChangeData={handleOnChangeData}
              register={register} errors={errors} />
          </> : null}
        </div>
        <div>
          <FormControlLabel control={
            <Checkbox checked={showSocialNetworks.instagram} onClick={() => handleShowSocialNetwork("instagram")} />
          } label="¿Tienes Instagram?" />
          {showSocialNetworks.instagram ? <>
            <InputTextBuilder
              label="FanPage " id="instagram_account.page" name="instagram_account.page" required={true}
              instance={dataInstance} handleOnChangeData={handleOnChangeData}
              register={register} errors={errors} />
            <InputTextBuilder
              label="Seguidores" id="instagram_account.followers" name="instagram_account.followers" required={true}
              instance={dataInstance} handleOnChangeData={handleOnChangeData}
              register={register} errors={errors} />
          </> : null}
        </div>
        <div>
          <FormControlLabel control={
            <Checkbox checked={showSocialNetworks.website} onClick={() => handleShowSocialNetwork("website")} />
          } label="¿Tienes Página Web?" />
          {showSocialNetworks.website ? <>
            <InputTextBuilder
              label="Página Web " id="website" name="website" required={true}
              instance={dataInstance} handleOnChangeData={handleOnChangeData}
              register={register} errors={errors} />
          </> : null}
        </div>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight: "bold", marginBottom: 2, paddingBottom: 2, borderBottom: "1px solid rgba(0,0,0,.1)" }}>Datos Políticos</Typography>
        <InputSelectBuilder
          label="Selecciona Partido Político" id="political_party" name="political_party" required={true}
          instance={dataInstance} handleOnChangeData={handleOnChangeData}
          register={register} errors={errors}
          options={politicalParties} />
        <InputSelectBuilder
          label="Elegido por..." id="chosen_by" name="chosen_by" required={true}
          instance={dataInstance} handleOnChangeData={handleOnChangeData}
          register={register} errors={errors}
          options={[
            { value: "MR", label: "Mayoría Relativa" },
            { value: "RP", label: "Representación Proporcional" }
          ]} />
        {/* {dataInstance.chosen_by === "MR" ? */}
        <InputSelectBuilder
          label="Selecciona Distrito" id="district" name="district" required={true}
          instance={dataInstance} handleOnChangeData={handleOnChangeData}
          register={register} errors={errors}
          options={districts} />
        {/* : null} */}
        <InputSwitchBuilder
          label="¿Experiencia Legislativa?" id="legislative_experience" name="legislative_experience" required={true}
          instance={dataInstance} handleOnChangeData={handleChangeBoolean}
          register={register} errors={errors} />
        <InputSwitchBuilder
          label="¿Presentó el informe anual de actividades?" id="annual_activities_report.isSubmitted" name="annual_activities_report.isSubmitted" required={true}
          instance={dataInstance} handleOnChangeData={handleChangeBoolean}
          register={register} errors={errors} />
        <InputSwitchBuilder
          label="¿Presentó 3 de 3?*" id="three_of_three_report.isSubmitted" name="three_of_three_report.isSubmitted" required={true}
          instance={dataInstance} handleOnChangeData={handleChangeBoolean}
          register={register} errors={errors} />
        {dataInstance.three_of_three_report.isSubmitted ?
          <InputTextBuilder
            label="Fecha en que presento 3 de 3 (YYYY-MM-DD)" id="three_of_three_report.date" name="three_of_three_report.date" required={false} mask="0000/00/00"
            instance={dataInstance} handleOnChangeData={handleOnChangeData}
            register={register} errors={errors} type="date" />
          : null}
      </Grid>
    </Grid>
  )
}