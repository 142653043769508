import { Box, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material"


export const PoliticianGroup = ({
  politicians, politiciansFilter, setPoliticiansFilter, files, setFiles, fileIndexToAssign
}) => {

  const handleSelectPolitician = (value) => {
    let filesTemp = [...files]
    if (filesTemp[fileIndexToAssign]["politicians"].includes(value)) {
      filesTemp[fileIndexToAssign]["politicians"].splice(filesTemp[fileIndexToAssign]["politicians"].indexOf(value), 1)
    } else {
      filesTemp[fileIndexToAssign]["politicians"].push(value)
    }
    setFiles(filesTemp)
  }

  const handlePoliticianFilter = (event) => {
    const value = event.target.value.toLowerCase()
    const filter = politicians.filter(politician => politician.label.toLowerCase().includes(value))
    setPoliticiansFilter(filter)
  }

  return (
    <Box sx={{ p: 1 }}>
      <TextField fullWidth margin="dense" size="small" type="text" placeholder="Buscar diputado" onChange={handlePoliticianFilter} />
      <List sx={{ width: '100%', maxHeight: 360 }} dense>
        {politiciansFilter.map((politician, index) => (
          <ListItem key={politician.value} disablePadding >
            <ListItemButton role={undefined} onClick={() => handleSelectPolitician(politician.value)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={files[fileIndexToAssign].politicians.includes(politician.value) ? true : false}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': politician.value }}
                />
              </ListItemIcon>
              <ListItemText id={politician.value} primary={politician.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}