import { Box, InputLabel, MenuItem, Select, } from "@mui/material"
import { useEffect, useState } from "react"

export const FoldersGroup = ({ folders, files, setFiles, fileIndexToAssign }) => {

  useEffect(() => {
    console.log("folders", folders)
  }, [folders])

  const [folderSelected, setFolderSelected] = useState("")
  const [categoriesFolder, setCategoriesFolder] = useState([])
  const [categorySelected, setCategorySelected] = useState("")

  const handleSelectFolder = (event) => {
    if (event.target.value === "") {
      setFolderSelected("")
      setCategorySelected("")
      setCategoriesFolder([])
      return
    }
    setFolderSelected(event.target.value)
    const folder = folders.filter(folder => folder._id === event.target.value)
    setCategoriesFolder(folder[0].categories)
  }

  const handleSelectCategoryFolderPolitician = (event) => {
    setCategorySelected(event.target.value)
    let filesTemp = [...files]
    /* if (filesTemp[fileIndexToAssign]["categoriesFolderPolitician"].includes(event.target.value)) {
      filesTemp[fileIndexToAssign]["categoriesFolderPolitician"].splice(filesTemp[fileIndexToAssign]["categoriesFolderPolitician"].indexOf(event.target.value), 1)
    } else { */
      filesTemp[fileIndexToAssign]["categoriesFolderPolitician"] = [event.target.value]
    //}
    setFiles(filesTemp)
  }

  return (<>
    <Box sx={{ minWidth: 120 }}>
      <InputLabel id="demo-simple-select-label">Folders</InputLabel>
      <Select
        size="small"
        fullWidth
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={folderSelected}
        label="Folders"
        onChange={handleSelectFolder}
      >
        <MenuItem value="">Selecciona un folder</MenuItem>
        {folders.map((folder, index) => (
          <MenuItem key={folder._id} value={folder._id}>{folder.name}</MenuItem>
        ))}
      </Select>
    </Box>
    { categoriesFolder.length > 0 && folderSelected !== "" ?
    <Box sx={{ minWidth: 120, mt: 1 }}>
      <InputLabel id="demo-simple-select-label">Categorías</InputLabel>
      <Select
        size="small"
        fullWidth
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={categorySelected}
        label="Categorías"
        onChange={handleSelectCategoryFolderPolitician}
      >
        <MenuItem value="">Selecciona una categoría</MenuItem>
        {categoriesFolder.map((category, index) => (
          <MenuItem key={category._id} value={category._id}>{category.name}</MenuItem>
        ))}
      </Select>
    </Box>
    : null }
  </>)
}