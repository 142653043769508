import { Avatar, List, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material"
import { Folder as FolderIcon, FolderOpen as FolderOpenIcon } from "@mui/icons-material"
import { useEffect } from "react"

export const Folders = ({ folders, folderSelected, onChangeFolderSelected }) => {

  useEffect(() => {
    console.log("folders", folders)
  }, [folders])

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {folders.map((folder, index) => (
        <ListItemButton key={folder.name}
          selected={folderSelected !== null ? folderSelected._id === folder._id : false}
          onClick={(event) => onChangeFolderSelected(folder)} >
          <ListItemAvatar>
            <Avatar>
              {folderSelected !== null ? folderSelected._id === folder._id ? <FolderOpenIcon /> : <FolderIcon /> : <FolderIcon />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={folder.name} />
        </ListItemButton>
      ))}
    </List>
  )
}
