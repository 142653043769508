import { CircularProgress } from "@mui/material"

export const Loader = ({ show }) => {
  return (<>
    { show ?
    <div style={{
      backgroundColor: "rgba(0,0,0,0.7)",
      zIndex: 1020,
      position: "absolute",
      borderRadius: 5,
      width: "100%",
      height: 500,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <CircularProgress color="secondary" />
    </div>
    : null}
  </>)
}