//export const API_GEOPOLITICAL_URL = 'http://localhost:8080/api/v1'; // Local
export const API_GEOPOLITICAL_URL = 'https://geopolitical-microservice-hltza4osjq-uc.a.run.app/api/v1'; // Produccion

export const HEADERS_API = () => {
  return {
    'Content-Type': 'application/json',
  }
}

export const GetMap4 = async (params) => {
  const { id, year } = params
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/observatorio-legislativo/capas-mapas-dashboard/map4/${id}?year=${year}`,
    {
      method: 'POST',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}

export const GetMap5 = async (params) => {
  const { id, year } = params
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/observatorio-legislativo/capas-mapas-dashboard/map5/${id}?year=${year}`,
    {
      method: 'POST',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}

export const GetMap6 = async (params) => {
  const { id, year, politician_party } = params
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/observatorio-legislativo/capas-mapas-dashboard/map6/${id}?year=${year}&politician_party=${politician_party}`,
    {
      method: 'POST',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}

export const GetMap7 = async (params) => {
  const { id, year } = params
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/observatorio-legislativo/capas-mapas-dashboard/map7/${id}?year=${year}`,
    {
      method: 'POST',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}

export const GetMap8 = async (params) => {
  const { id, year } = params
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/observatorio-legislativo/capas-mapas-dashboard/map8/${id}?year=${year}`,
    {
      method: 'POST',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}

export const GetMap9 = async (params) => {
  const { id, year, politician_party } = params
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/observatorio-legislativo/capas-mapas-dashboard/map9/${id}?year=${year}&politician_party=${politician_party}`,
    {
      method: 'POST',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}

export const GetMap10 = async (params) => {
  const { id, year, politician_party } = params
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/observatorio-legislativo/capas-mapas-dashboard/map10/${id}?year=${year}&politician_party=${politician_party}`,
    {
      method: 'POST',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}

export const GetMap11 = async (params) => {
  const { id, year } = params
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/observatorio-legislativo/capas-mapas-dashboard/map11/${id}?year=${year}`,
    {
      method: 'POST',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}
