//export const API_GEOPOLITICAL_URL = 'http://localhost:8080/api/v1'; // Local
export const API_GEOPOLITICAL_URL = 'https://geopolitical-microservice-hltza4osjq-uc.a.run.app/api/v1'; // Produccion

export const HEADERS_API = () => {
  return {
    'Content-Type': 'application/json',
  }
}

export const getDistrict = async (id, year) => {
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/observatorio-legislativo/electoral-results/localdistrict/${id}?year=${year}`,
    {
      method: 'POST',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}

export const getSectionsByDistrict = async (id, year) => {
  const res = await fetch(
    `${API_GEOPOLITICAL_URL}/observatorio-legislativo/electoral-results/sections-by-localdistrict/${id}?year=${year}`,
    {
      method: 'POST',
      headers: HEADERS_API()
    }
  )
  return await res.json()
}

