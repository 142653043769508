import { useEffect, useState } from "react";
import { Button, Checkbox, CircularProgress, FormControlLabel, Typography } from "@mui/material";
import { useMutation, useQuery } from '@tanstack/react-query'
import FlexibleWidthDialog from "components/Dialog/FlexibleWidthDialog"
import { editPermissions, getAllPermissions } from "../services/api";
import { useTranslation } from "react-i18next";

export default function Permission({ open, close, instance, reportAction }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [permissionsSelected, setPermissionsSelected] = useState([])
  const { data: permissionsList } = useQuery(['permissionsList'], getAllPermissions, { refetchOnWindowFocus: false, enabled: open })
  const [groupPermissions, setGroupPermissions] = useState([])

  useEffect(() => {
    if (permissionsList) { setGroupPermissions(permissionsList.data) }
  }, [permissionsList])

  useEffect(() => {
    setPermissionsSelected(instance.permissions) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const mutation = useMutation(editPermissions, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") { reportAction(false, 'Error al guardar los datos', 'error') }
      else {
        reportAction(true, 'Acción realizada correctamente', 'success')
      }
    }
  })

  const handleEdit = () => {
    console.log("permissionsSelected", permissionsSelected)
    setIsLoading(true)
    mutation.mutate({ id: instance._id, permissions: permissionsSelected })
  }

  const handleClose = () => { close() }

  const Actions = () => (<>
    {!isLoading ? <>
      <Button onClick={handleClose}>{t("button_text_cancel")}</Button>
      <Button variant="contained" color="info" onClick={handleEdit}>{t("button_text_edit")}</Button>
    </> : <CircularProgress />}
  </>)

  const handleUpdatePermission = (id) => {
    let temp = [...permissionsSelected]
    let index = temp.indexOf(id)
    if (index !== -1) { temp.splice(index, 1) } else { temp.push(id) }
    setPermissionsSelected(temp)
  }

  return (<>
    <FlexibleWidthDialog open={open} title={t('users_dialog_title_permission')} actions={<Actions />}>
      {groupPermissions.length > 0 ?
        <>
          {groupPermissions.map((g, i) => (
            <div key={`${i}-container-group`}>
              <Typography component="p" variant="h6">{g.name}</Typography>
              {g.permissions.map((p, j) => (<>
                <FormControlLabel key={`${j}-${p._id}`} control={<Checkbox onClick={() => handleUpdatePermission(p.name)} />} label={p.description} checked={permissionsSelected !== undefined ? permissionsSelected.includes(p.name) : false} /><br />
              </>))}
              <br />
            </div>))}
        </> : null}
    </FlexibleWidthDialog>
  </>)
}