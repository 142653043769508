import { API_BASE_URL, HEADERS_WITH_TOKEN, HEADERS_WITH_TOKEN_WITHOUT_CONTENTTYPE } from "services/api/BaseUrl"

export const getAll = () =>
  fetch(
    `${API_BASE_URL}/dashboard`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN(localStorage.getItem('LOAt'))
    }
  )
    .then(res => res.json())