import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"


export const DistrictSelect = ({ districtList, district, onChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Distrito</InputLabel>
      <Select
        size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={district}
        label="Distrito"
        onChange={onChange} >
        <MenuItem value={0}>Selecciona un Distrito</MenuItem>
        {districtList.map((district, index) => (
          <MenuItem key={index} value={district.district_id}>{district.district_id} - {district.name}</MenuItem>
        ))
        }
      </Select>
    </FormControl>
  )
}