import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Typography } from "@mui/material"
import { Delete as DeleteIcon, ExpandMore as ExpandMoreIcon } from "@mui/icons-material"
import { LabelBuilder } from "components/InputBuilder"
import { ValidateErrorMessage } from "components/ValidateErrorMessage"
import { useEffect, useState } from "react"

export const Sections = ({ sections, updateSections, rhf }) => {
  const { errors } = rhf
  const [sectionsTemp, setSectionsTemp] = useState([])
  const [addSections, setAddSections] = useState({ from: 0, to: 0 })
  const [addSection, setAddSection] = useState(0)
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (sections.length > 0) {
      const sectionsFormat = sections.map((section, index) => {
        return { key: index, label: section }
      })
      console.log("sectionsFormat")
      setSectionsTemp(sectionsFormat)
    }
  }, [sections])

  const handleFormatSections = (data) => {
    const sectionsFormat = data.map((section) => {
      return section.label
    })
    console.log("sectionsFormat", sectionsFormat)
    updateSections(sectionsFormat)
  }

  const handleAddSectionsTemp = () => {
    if (addSections.from === 0 && addSections.to === 0) return
    const sectionsAdd = []
    for (let i = addSections.from; i <= addSections.to; i++) {
      sectionsAdd.push({ key: i, label: i })
    }
    setSectionsTemp(sectionsAdd)
    handleFormatSections(sectionsAdd)
    setAddSections({ from: 0, to: 0 })
  }

  const handleAddSectionTemp = () => {
    if (addSection === 0) return
    if (sectionsTemp.find((section) => section.label === addSection)) return
    const lastKeySections = sectionsTemp.length > 0 ? sectionsTemp[sectionsTemp.length - 1].key : 0
    const sectionAdd = { key: lastKeySections + 1, label: addSection }
    setSectionsTemp([...sectionsTemp, sectionAdd])
    handleFormatSections([...sectionsTemp, sectionAdd])
    setAddSection(0)
  }

  const handleDeleteSection = (index) => {
    const sections = sectionsTemp.filter((section, indexTemp) => indexTemp !== index)
    setSectionsTemp(sections)
    handleFormatSections(sections)
  }

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (<>
    <LabelBuilder label="Secciones" required={true} />
    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
        Agrega secciones
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" color="text.secondary" sx={{ marginBottom: 2, fontSize: 13 }}>
          Agrega secciones de forma automática desde un número inicial hasta un número final. Se reemplazará las secciones ya agregadas.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField label="Desde" variant="standard" size="small" fullWidth type="number" InputProps={{ inputProps: { min: 0 } }} value={addSections.from}
              onChange={(event) => setAddSections({ ...addSections, from: event.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="Hasta" variant="standard" size="small" fullWidth type="number" InputProps={{ inputProps: { min: 0 } }} value={addSections.to}
              onChange={(event) => setAddSections({ ...addSections, to: event.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="info" fullWidth disabled={addSections.from === 0 || addSections.to === 0} onClick={() => handleAddSectionsTemp()}>Agregar</Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
        Agrega sección
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Sección" variant="standard" size="small" fullWidth type="number" InputProps={{ inputProps: { min: 0 } }} value={addSection}
              onChange={(event) => setAddSection(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="info" fullWidth disabled={addSection === 0} onClick={() => handleAddSectionTemp()}>Agregar</Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>

    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', listStyle: 'none', p: 0.5, m: 0, maxHeight: 200, overflowX: "hidden", marginTop: 2, border: "1px solid #cecece" }} >
      {sectionsTemp.length > 0 ? <>
        {sectionsTemp.map((section, index) => (
          <div key={index} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "25px", backgroundColor: "#cecece", borderRadius: "10px", padding: "5px 10px", margin: 5 }}>
            <span>{section.label}</span>
            <DeleteIcon fontSize="small" sx={{ cursor: "pointer", color: "#858585" }} onClick={() => handleDeleteSection(index)} />
          </div>
        ))}
      </> : <Typography variant="body1" color="text.secondary" sx={{ marginTop: 2, fontSize: 13 }}>No hay secciones agregadas</Typography>
      }
    </div>
    <ValidateErrorMessage message={errors.sections?.message} />
  </>)
}