import { useEffect, useState } from "react";
import { Box, Container } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { getAll } from "../LegislaturePeriod/services/api";
import { SnackbarGlobal } from "components/Snackbar";
import { FMPageOpacityTransition } from "components/FMTransitions/PageTransitions";
import { useUserRole } from "hooks/useUserRole";
import { Paper, Tab, Tabs, Tooltip } from "@mui/material";
import { SaveUpdate } from "./modules/SaveUpdate";

export default function PoliticianAssistance() {
  useUserRole(["SUPERADMIN", "ADMIN", "EDITOR"])
  const { t } = useTranslation();
  const [value, setValue] = useState('saveupdate');
  const handleChange = (event, newValue) => setValue(newValue)
  const [dataFilter, setDataFilter] = useState(null)
  const [notification, setNotification] = useState({ open: false, message: "", severity: "" })
  const { data } = useQuery(['legislativePeriodsList'], getAll, { refetchOnWindowFocus: false })

  useEffect(() => {
    if (data !== undefined && data !== null && data !== "") {
      setDataFilter(data.data)
    }
  }, [data])

  const handleWatchAction = (success, message, severity) => {
    setNotification({ open: true, message: message, severity: severity })
  }

  return (<>
    <FMPageOpacityTransition>
      <Box component="main" style={{ marginBottom: 100, }}>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Paper sx={{
            padding: "20px 10px 20px 10px",
            backgroundColor: "#efefef",
            borderRadius: "10px",
          }}>
            {dataFilter !== null ? <>
              <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="secondary tabs example">
                <Tab value="saveupdate" label="Cargar / actualizar datos" />
                {/* <Tab value="show" label="Ver datos" /> */}
              </Tabs>
              <div style={{ marginTop: 20 }} role="tabpanel" hidden={value !== "saveupdate"} id={`simple-tabpanel-electoraldemografico`} aria-labelledby={`simple-tab-electoraldemografico`}>
                <SaveUpdate data={dataFilter} reportAction={handleWatchAction} />
              </div>
             {/*  <div style={{ marginTop: 20 }} role="tabpanel" hidden={value !== "show"} id={`simple-tabpanel-otherdata`} aria-labelledby={`simple-tab-otherdata`}>
                data2
              </div> */}
            </> : null}
          </Paper>
        </Container>
      </Box>
    </FMPageOpacityTransition>
    <SnackbarGlobal
      open={notification.open}
      close={() => setNotification({ open: false, message: "", severity: "" })}
      message={notification.message}
      severity={notification.severity} />
  </>)
}