import { OptionMenus } from "./modules/OptionMenus";
import { Category as CategoryIcon, Delete as DeleteIcon, Edit as EditIcon, Groups as GroupsIcon, GroupWork as GroupWorkIcon, Map as MapIcon } from "@mui/icons-material";

export const RenderOptionMenusCategories = ({ anchor, id, open, close, action }) => {
  return (
    <OptionMenus anchor={anchor} id={id} open={open} close={close}
      options={[
        { icon: <CategoryIcon sx={{ marginRight: 2 }} />, label: "Categorías", action: () => action(1) },
        { icon: <GroupsIcon sx={{ marginRight: 2 }} />, label: "Diputados", action: () => action(2) },
        { icon: <GroupWorkIcon sx={{ marginRight: 2 }} />, label: "Partidos políticos", action: () => action(3) },
        { icon: <MapIcon sx={{ marginRight: 2 }} />, label: "Municipios", action: () => action(4) },
      ]} />
  )
}

export const RenderOptionMenusCardDocument = ({ anchor, id, open, close, action }) => {
  return (
    <OptionMenus anchor={anchor} id={id} open={open} close={close}
      options={[
        { icon: <EditIcon sx={{ marginRight: 2 }} />, label: "Editar", action: () => action(2) },
        { icon: <DeleteIcon sx={{ marginRight: 2 }} />, label: "Eliminar", action: () => action(3) }
      ]} />
  )
}