import { BackupTable as BackupTableIcon, Dashboard as DashboardIcon, Diversity3 as Diversity3Icon, FormatListBulleted as FormatListBulletedIcon, FormatListNumbered as FormatListNumberedIcon, Groups as GroupsIcon, LocalLibrary as LocalLibraryIcon, Map as MapIcon, PeopleAlt as PeopleAltIcon } from '@mui/icons-material';

export const MenuItems = () => [
  {
    type: "menu",
    text: 'Dashboard',
    icon: <DashboardIcon />,
    to: "/admin/dashboard",
    role: ["SUPERADMIN", "ADMIN", "EDITOR", "POLITICIAN", "POLITICIAN_ADMIN"]
  },
  /* {
    type: "menu",
    text: 'Configuración global',
    icon: <SettingsIcon />,
    to: "/admin/global-settings",
    role: ["SUPERADMIN"]
  }, */
  {
    type: "menu",
    text: 'Comisiones',
    icon: <FormatListBulletedIcon />,
    to: "/admin/commissions",
    role: ["SUPERADMIN", "ADMIN"]
  },
  {
    type: "menu",
    text: 'Cargos de comisiones',
    icon: <FormatListNumberedIcon />,
    to: "/admin/commission-charges",
    role: ["SUPERADMIN", "ADMIN"]
  },
  {
    type: "menu",
    text: 'Distritos',
    icon: <MapIcon />,
    to: "/admin/districts",
    role: ["SUPERADMIN", "ADMIN"]
  },
  {
    type: "menu",
    text: 'Municipios',
    icon: <MapIcon />,
    to: "/admin/municipalities",
    role: ["SUPERADMIN", "ADMIN"]
  },
  {
    type: "menu",
    text: 'Diputados',
    icon: <Diversity3Icon />,
    to: "/admin/politicians",
    role: ["SUPERADMIN", "ADMIN", "EDITOR"],
  },
  {
    type: "menu",
    text: 'Ranking de diputados',
    icon: <Diversity3Icon />,
    to: "/admin/politicians-ranking",
    role: ["SUPERADMIN", "ADMIN", "EDITOR"]
  },
  {
    type: "menu",
    text: 'Asistencia de diputados',
    icon: <Diversity3Icon />,
    to: "/admin/politicians-assistance",
    role: ["SUPERADMIN", "ADMIN", "EDITOR"]
  },
  {
    type: "menu",
    text: 'Legislaturas',
    icon: <LocalLibraryIcon />,
    to: "/admin/legislatures",
    role: ["SUPERADMIN", "ADMIN"]
  },
  {
    type: "menu",
    text: 'Periodos legislativos',
    icon: <LocalLibraryIcon />,
    to: "/admin/legislature-periods",
    role: ["SUPERADMIN", "ADMIN"]
  },
  {
    type: "menu",
    text: 'Partidos políticos',
    icon: <GroupsIcon />,
    to: "/admin/political-parties",
    role: ["SUPERADMIN", "ADMIN"]
  },
  {
    type: "menu",
    text: 'Librería',
    icon: <BackupTableIcon />,
    to: "/admin/library-v2",
    role: ["SUPERADMIN", "ADMIN", "LIBRARY"]
  },
  {
    type: "menu",
    text: 'Documentos',
    icon: <BackupTableIcon />,
    to: "/admin/politician-library",
    role: ["POLITICIAN", "POLITICIAN_ADMIN"]
  },
  {
    type: "menu",
    text: 'Usuarios',
    icon: <PeopleAltIcon />,
    to: "/admin/users",
    role: ["SUPERADMIN"]
  },
  {
    type: "divider",
  }
]