import { Box, Grid, Skeleton } from "@mui/material";
import { GlobalOptionsLoader } from "./variables"

export default function LoaderTable() {
  return (<>
    <Grid container spacing={0} sx={{ marginBottom: 1 }}>
      <Grid item xs={12} md={12}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: "center" }}>
          <Skeleton 
            variant="circular" 
            width={40} 
            height={40} 
            sx={{ bgcolor: GlobalOptionsLoader.backgroundColor }} 
          />
          <Skeleton sx={{ bgcolor: GlobalOptionsLoader.backgroundColor }}
            variant="rectangular"
            width="100%"
            height={32}
          />
        </Box>
      </Grid>
    </Grid>
    <Grid container spacing={0}>
      <Grid item xs={12} md={12}>
        <Skeleton 
          sx={{ bgcolor: GlobalOptionsLoader.backgroundColor }}
          variant="rectangular"
          width="100%"
          height="30vh"
        />
      </Grid>
    </Grid>
  </>)
}