import { useEffect, useState } from "react";
import { Box, CircularProgress, Container, Grid, IconButton, Paper, Tooltip } from "@mui/material";
import { Sort as SortIcon, Apps as AppsIcon } from "@mui/icons-material";
import { FMPageOpacityTransition } from "components/FMTransitions/PageTransitions";
import { Folders } from "./modules/Folders";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getData, getAllLibraryFoldersPolitican } from "./services/api";
import { Categories } from "./modules/Categories";
import { CardDocumentPolitician } from "components/Card/CardDocumentPolitician";
import { NoFiles } from "./modules/NoFiles";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase/firebase"

export default function PoliticianLibrary() {
  const [folderSelected, setFolderSelected] = useState(null)
  const [categorySelected, setCategorySelected] = useState(null)
  const [typeShow, setTypeShow] = useState("grid") // list, grid
  const [dataFilter, setDataFilter] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  //const [metadata, setMetadata] = useState(null)
  const { data: foldersList } = useQuery(["foldersList"], getAllLibraryFoldersPolitican, { refetchOnWindowFocus: false })
  const { data } = useQuery(
    ['filesList', {
      category: categorySelected !== null && categorySelected !== undefined ? categorySelected.value : null,
      page: 1
    }],
    () => getData({
      category: categorySelected !== null && categorySelected !== undefined ? categorySelected.value : null,
      page: 1
    }),
    { refetchOnWindowFocus: false, enabled: categorySelected !== null }
  )

  useEffect(() => {
    if (data !== undefined) {
      setDataFilter(data.data)
      localStorage.setItem("metadataFiles", JSON.stringify(data.metadata))
    } // eslint-disable-next-line
  }, [data])

  const handleFolderSelected = (folder) => {
    setFolderSelected(folder)
    setDataFilter([])
    setCategorySelected(null)
  }

  const handleCategorySelected = (category) => setCategorySelected(category)

  const handlerDownloadFile = ({ name, path }) => {
    getDownloadURL(ref(storage, path))
      .then((url) => {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', `${name}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }).catch((error) => {
        console.log(error)
      })
  }

  // Infinite Scroll https://blog.logrocket.com/3-ways-implement-infinite-scroll-react/
  const mutationInfinityScroll = useMutation(getData, {
    onSuccess: (data) => {
      setIsLoading(false)
      if (data.status === "error") {
        console.log("error")
      } else {
        setDataFilter([...dataFilter, ...data.data])
        localStorage.setItem("metadataFiles", JSON.stringify(data.metadata))
        //setMetadata(data.metadata)
      }
    }
  })

  const handleMutationInfinityScroll = () => {
    const metadata = JSON.parse(localStorage.getItem("metadataFiles"))
    console.log(metadata)
    if (metadata.nextPage === null) { setIsLoading(false); return; }
    mutationInfinityScroll.mutate({
      category: categorySelected !== null && categorySelected !== undefined ? categorySelected.value : null,
      page: metadata.nextPage
    })
  }

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isLoading) { return; }
    setIsLoading(true)
    handleMutationInfinityScroll()
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, [isLoading])

  return (<>
    <FMPageOpacityTransition>
      { foldersList !== undefined ? 
      <Box component="main" style={{ marginBottom: 100, }}>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Paper sx={{ padding: "20px 10px 20px 10px", backgroundColor: "#efefef" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Folders folders={foldersList.data} folderSelected={folderSelected} onChangeFolderSelected={handleFolderSelected} />
              </Grid>
              {folderSelected !== null ?
              <Grid item xs={12} md={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Categories
                      folderSelected={folderSelected}
                      categorySelected={categorySelected}
                      onChangeCategorySelected={handleCategorySelected} />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "end" }}>
                    {typeShow === "grid" ?
                      <Tooltip title="Lista"> <IconButton onClick={() => setTypeShow("list")}><SortIcon /></IconButton> </Tooltip>
                      :
                      <Tooltip title="Cuadrícula"> <IconButton onClick={() => setTypeShow("grid")}><AppsIcon /></IconButton> </Tooltip>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    {dataFilter.length > 0 ?
                      <Grid container spacing={2}>
                        {dataFilter.map(file => (
                          <Grid item xs={12} sm={typeShow === "grid" ? 6 : 12} key={file._id}>
                            <CardDocumentPolitician file={file} typeShow={typeShow} onDownload={handlerDownloadFile} />
                          </Grid>
                        ))}
                      </Grid>
                      : <NoFiles />}
                    <div style={{ paddingTop: 8, textAlign: "center" }}>
                      {isLoading && <CircularProgress />}
                    </div>
                  </Grid>
                </Grid>
              </Grid> 
              : null }
            </Grid>
          </Paper>
        </Container>
      </Box>
      : null }
    </FMPageOpacityTransition>
  </>)
}